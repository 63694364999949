import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TournamentIcon = props => (
  <SvgIcon {...props} viewBox="-60 -60 632 632">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M358.402,268.002c148.832-28.416,153.568-217.824,153.6-219.776c0.064-4.288-1.6-8.416-4.608-11.456    s-7.104-4.768-11.392-4.768h-112v-16c0-8.832-7.168-16-16-16h-224c-8.832,0-16,7.168-16,16v16h-112    c-4.288,0-8.384,1.728-11.392,4.768c-3.008,3.04-4.672,7.168-4.608,11.456c0.032,1.952,4.736,191.392,153.6,219.776    c13.92,18.688,32.544,33.568,54.4,42.464v56.544c0,32.928-19.776,62.016-50.56,74.144l-19.52,8    c-5.984,2.496-9.92,8.352-9.92,14.848v32c0,8.832,7.168,16,16,16h224c8.832,0,16-7.168,16-16v-32    c0-6.496-3.936-12.352-9.952-14.816l-19.712-8.064c-30.592-12.096-50.336-41.152-50.336-74.08v-56.544    C325.89,301.57,344.514,286.69,358.402,268.002z M384.002,192.002v-128h94.816c-4.096,38.432-22.336,133.92-101.024,165.376    C381.442,217.474,384.002,205.09,384.002,192.002z M33.058,64.002h94.944v128c0,13.12,2.56,25.536,6.24,37.472    C54.498,198.082,36.866,102.658,33.058,64.002z"
    />
  </SvgIcon>
);

export default TournamentIcon;
