import TeamIcon from '@material-ui/icons/Group';
import ManagerIcon from '../assets/icons/ManagerIcon';
import PlayerIcon from '@material-ui/icons/Person';
import RegionIcon from '@material-ui/icons/Public';
import TournamentIcon from '../assets/icons/TournamentIcon';
import MatchIcon from '../assets/icons/MatchIcon';
import StadiumIcon from '../assets/icons/StadiumIcon';

import TournamentSearch from '../modules/entities/tournaments/TournamentSearch';
import TeamSearch from '../modules/entities/teams/TeamSearch';
import ManagerSearch from '../modules/entities/managers/ManagerSearch';
import PlayerSearch from '../modules/entities/players/PlayerSearch';
import MatchSearch from '../modules/entities/matches/MatchSearch';
import RegionSearch from '../modules/entities/regions/RegionSearch';
import StadiumSearch from '../modules/entities/stadiums/StadiumSearch';
import TournamentCardContainer from '../modules/entities/tournaments/TournamentCardContainer';
import TeamCardContainer from '../modules/entities/teams/TeamCardContainer';
import PlayerCardContainer from '../modules/entities/players/PlayerCardContainer';
import MatchCardContainer from '../modules/entities/matches/MatchCardContainer';
import RegionCardContainer from '../modules/entities/regions/RegionCardContainer';
import SeasonCardContainer from '../modules/entities/seasons/SeasonCardContainer';
import StadiumCardContainer from '../modules/entities/stadiums/StadiumCardContainer';
import ManagerCardContainer from '../modules/entities/managers/ManagerCardContainer';

const entityRoutes = [
  {
    title: 'Tournaments',
    path: '/tournaments',
    icon: TournamentIcon,
    drawerComponent: TournamentSearch,
  },
  {
    path: '/tournaments/:tournamentId',
    drawerComponent: TournamentSearch,
    mainComponent: TournamentCardContainer,
  },
  {
    title: 'Teams',
    path: '/teams',
    icon: TeamIcon,
    drawerComponent: TeamSearch,
  },
  {
    path: '/teams/:teamId',
    drawerComponent: TeamSearch,
    mainComponent: TeamCardContainer,
  },
  {
    title: 'Managers',
    path: '/managers',
    icon: ManagerIcon,
    drawerComponent: ManagerSearch,
  },
  {
    path: '/managers/:managerId',
    drawerComponent: ManagerSearch,
    mainComponent: ManagerCardContainer,
  },
  {
    title: 'Players',
    path: '/players',
    icon: PlayerIcon,
    drawerComponent: PlayerSearch,
  },
  {
    path: '/players/:playerId',
    drawerComponent: PlayerSearch,
    mainComponent: PlayerCardContainer,
  },
  {
    title: 'Matches',
    path: '/matches',
    icon: MatchIcon,
    drawerComponent: MatchSearch,
  },
  {
    path: '/matches/:matchId',
    drawerComponent: MatchSearch,
    mainComponent: MatchCardContainer,
  },
  {
    title: 'Regions',
    path: '/regions',
    icon: RegionIcon,
    drawerComponent: RegionSearch,
  },
  {
    path: '/regions/:regionId',
    drawerComponent: RegionSearch,
    mainComponent: RegionCardContainer,
  },
  {
    path: '/seasons/:seasonId',
    mainComponent: SeasonCardContainer,
  },
  {
    title: 'Stadiums',
    path: '/stadiums',
    icon: StadiumIcon,
    drawerComponent: StadiumSearch,
  },
  {
    path: '/stadiums/:stadiumId',
    drawerComponent: StadiumSearch,
    mainComponent: StadiumCardContainer,
  },
];

export default entityRoutes;
