import React, { Component } from 'react';

import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Typography,
} from '@material-ui/core';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import MatchEventRow from './MatchEventRow';

const formatScore = score => (score ? `${score[0]} - ${score[1]}` : '');

const nameFromPeriodType = (periodType) => {
  switch (periodType) {
    case 'first_half':
      return 'First Half';
    case 'second_half':
      return 'Second Half';
    case 'penalty_shootout':
      return 'Penalty Shootout';
    default:
      return periodType;
  }
};

const titleFromPeriod = ({ type, score }) => `${nameFromPeriodType(type)} ${formatScore(score)}`;

class Period extends Component {
  state = { expanded: true };
  toggleExpand = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };
  render() {
    const { period, onEventEdited } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="inherit" align="center" style={{ fontWeight: 'bold' }}>
                {titleFromPeriod(period)}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={this.toggleExpand}>
              {this.state.expanded ? <ArrowDropDown /> : <ArrowDropUp />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={expanded}>
          {period.events.map(event => (
            <MatchEventRow key={event.id} event={event} onEventEdited={onEventEdited} />
          ))}
        </Collapse>
      </div>
    );
  }
}

export default Period;
