import React from 'react';
import { ListItemAvatar, Avatar } from '@material-ui/core';
import { getImageUrl, getFallbackImageUrl } from '../../../utils/getImageUrl';

const teamStyle = {
  style: {
    width: 32,
    height: 32,
    borderRadius: 0,
  },
};

const ResultListItemAvatar = ({ entityType = '', entityId, imgKey = '', ...rest }) => (
  <ListItemAvatar {...rest}>
    <Avatar
      src={imgKey ? getImageUrl(entityType, entityId, imgKey) : ""}
      {...(entityType === 'team' || entityType === 'region' ? teamStyle : {})}
      imgProps={{
        onError: (e) => {
          e.target.src = getFallbackImageUrl();
        },
      }}
    />
  </ListItemAvatar>
);

export default ResultListItemAvatar;
