const initialState = {
  variant: null,
  message: null,
  open: false,
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_ALERT':
      return {
        ...state,
        open: true,
        variant: action.payload.variant,
        message: action.payload.message,
      };

    case 'CLOSE_ALERT':
      return {
        ...state,
        open: false,
        variant: null,
        message: null,
      };

    default:
      return state;
  }
};

export default alert;
