import React, { useState } from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import useUploadStatus from '../../hooks/useUploadStatus';
import constants from '../../../../utils/constants';
import ExistingImage from './ExistingImage';
import ImageUpload from './ImageUpload';
import { getImageUrl } from '../../../../utils/getImageUrl';

const Message = ({ message, secondary, type }) => (
  <div style={{ textAlign: 'center' }}>
    <p
      style={{
        color: type === 'success' ? 'darkgreen' : 'darkred',
        fontWeight: '500',
      }}
    >
      {message}
    </p>
    <p style={{ fontWeight: 500 }}>{secondary}</p>
  </div>
);

const VisualSetupSection = ({
  entityType,
  entityId,
  sectionTitle,
  images = [],
  useCroppie,
  defaultExpand = false,
  uploadSize,
}) => {
  const [expanded, setExpanded] = useState(defaultExpand);
  const [uploadStatus, uploadImage] = useUploadStatus(entityType, entityId);

  const handleExpandClick = () => setExpanded(!expanded);

  // We need to scale the images down in case of thumbnails, hence this is used
  const formatUploadImages = imagePayload =>
    images.map((img) => {
      // const uploadKey = constants.images[entityType][img].key;
      let imageData;
      // Scale team/player thumbnail images down from full width
      if (img === 'badge_thumbnail' || img === 'badge_tiny' || img === 'photo_thumbnail') {
        const thubnailWidth = constants.images[entityType][img].uploadWidth;
        const fullWidth = constants.images.team.badge_full.uploadWidth;
        const multiplier = thubnailWidth / fullWidth;

        imageData = {
          ...imagePayload,
          ...(imagePayload.scale && { scale: imagePayload.scale * multiplier }),
          ...(imagePayload.top && { top: imagePayload.top * multiplier }),
          ...(imagePayload.left && { left: imagePayload.left * multiplier }),
        };
      } else {
        imageData = { ...imagePayload };
      }
      return {
        key: img,
        src: imageData,
      };
    });

  const uploadImageHandler = (imgPayload) => {
    const preparedImages = formatUploadImages(imgPayload);
    uploadImage(preparedImages);
  };

  return (
    <div>
      <ListItem onClick={handleExpandClick} style={{ cursor: 'pointer' }}>
        <Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>
          {sectionTitle}
        </Typography>
        <ListItemSecondaryAction>
          <IconButton onClick={handleExpandClick}>
            {expanded ? <ArrowDropDown /> : <ArrowDropUp />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {/* Show Existing images */}
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {images.map(image => (
            <ExistingImage
              key={image}
              src={getImageUrl(entityType, entityId, image)}
              width={constants.images[entityType][image].displayWidth}
              height={constants.images[entityType][image].displayHeight}
              title={constants.images[entityType][image].title}
              enableDownload
            />
          ))}
        </div>

        {/* Upload Image */}
        <ImageUpload
          enableCroppie={useCroppie}
          expectedImgSize={{ ...uploadSize }}
          onImageSave={imgPayload => uploadImageHandler(imgPayload)}
        />
        {/* Upload Status Message */}
        {/* TODO: Use a generic message component to show messages */}
        <React.Fragment>
          {uploadStatus === 'loading' && <LinearProgress />}
          {uploadStatus === 'success' && (
            <Message
              type="success"
              message="Image upload was successful, please wait for few minutes for it to reflect."
              secondary="Note: Remember to upload the image in Monorail Admin also."
            />
          )}
          {uploadStatus === 'failure' && (
            <Message
              type="failure"
              message="Oops, there was an error during image upload, please try again!"
            />
          )}
        </React.Fragment>
      </Collapse>
    </div>
  );
};

export default VisualSetupSection;
