import React from 'react';
import { withStyles, Paper } from '@material-ui/core';
import classNames from 'classnames';

const styles = {
  container: { paddingTop: 24, paddingLeft: 24 },
  card: {
    marginBottom: 24,
    padding: 24,
    marginRight: 24,
  },
  horizontalCards: {
    display: 'flex',
  },
  horizontalCard: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  buttonContainer: {
    paddingLeft: 24,
    margin: -24,
    marginTop: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  taskBtnContainer: {
    position: 'absolute',
    top: 180,
    right: 16,
  },
};

const Page = withStyles(styles)(({ classes, children }) => (
  <div className={classes.page}>{children}</div>
));

const Container = withStyles(styles)(({ classes, children }) => (
  <div className={classes.container}>{children}</div>
));

const ColumnCards = withStyles(styles)(({ classes, children }) => (
  <div className={classes.horizontalCards}>{children}</div>
));

const ColumnCard = withStyles(styles)(({ classes, children }) => (
  <Paper className={classNames(classes.card, classes.horizontalCard)}>{children}</Paper>
));

const HorizontalCards = withStyles(styles)(({ classes, children }) => (
  <div className={classes.horizontalCards}>{children}</div>
));

const HorizontalPaperCard = withStyles(styles)(({ classes, children }) => (
  <Paper className={classNames(classes.card, classes.horizontalCard)}>{children}</Paper>
));

const TaskTargetBtnContainer = withStyles(styles)(({ classes, children }) => (
  <div className={classes.buttonContainer}>{children}</div>
));

const TaskBtnContainer = withStyles(styles)(({ classes, children }) => (
  <div className={classes.taskBtnContainer}>{children}</div>
));

// Layout
export default {
  Page,
  Container,
  ColumnCards,
  ColumnCard,
  HorizontalCards,
  HorizontalPaperCard,
  TaskTargetBtnContainer,
  TaskBtnContainer,
};
