import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import {
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
  selectEntity,
} from '../../../redux/actions';
import constants from '../../../utils/constants';
import PlayerCard from './PlayerCard';

const PlayerCardContainer = ({ match, getEntity, ...rest }) => {
  // Match is a router prop, not the football match :P
  const { playerId } = match.params;
  useEffect(() => {
    getEntity(playerId, constants.entityType.player);
  }, [playerId]);

  return <PlayerCard {...rest} />;
};

const mapStateToProps = (state) => {
  const isLoadingOrError = state.playerOverwrites.loading || state.playerOverwrites.writing;
  const arePropertiesEdited =
    Object.values(state.edits.currentEdits).filter(value => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    player: getReducedEntityObject(state.entities.selectedPlayer),
    attributeTypes: getReducedEntityTypes(state.entities.selectedPlayer),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = dispatch => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (playerId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('player', playerId, currentEdits)),
  fetchEntityAndOverwrites: playerId => dispatch(fetchEntityAndOverwrites('player', playerId)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerCardContainer));
