import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from 'material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider as NewMuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';
import Backend from './services/backend';

import LoginPage from './modules/auth/LoginPage';
import AppContainer from './AppContainer';

const newTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#4a8eb9',
      main: '#006189',
      dark: '#00375c',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    body1: {
      fontSize: 14,
      fontWeight: 300,
    },
  },
});

class App extends Component {
  state = {
    user: {},
    loggedIn: false,
    isLoading: true,
    loginError: null,
    theme: {
      palette: {
        primary1Color: '#006189', // "Dala Blue"
        primary2Color: '#006189',
        accent1Color: '#ffffff',
        pickerHeaderColor: '#006189',
      },
    },
  };

  componentDidMount() {
    Backend.validateUserLogin().then(
      (user) => {
        this.setState({ user, loggedIn: true, isLoading: false });
      },
      () => {
        this.setState({ loggedIn: false, isLoading: false });
      },
    );
  }
  login = (username, password) => {
    Backend.login(username, password)
      .then(() => {
        localStorage.setItem('user', username);
        this.setState({ user: username, loggedIn: true, loginError: null });
      })
      .catch((error) => {
        this.setState({ loginError: error });
      });
  };

  logout = () => {
    Backend.logout().then(() => {
      localStorage.removeItem('user');
      this.setState({ user: {}, loggedIn: false });
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <NewMuiThemeProvider theme={newTheme}>
        <MuiThemeProvider muiTheme={getMuiTheme(this.state.theme)}>
          <div>
            <CssBaseline />
            {this.state.loggedIn ? (
              <Router>
                <AppContainer user={this.state.user} onLogout={this.logout} />
              </Router>
            ) : (
              <LoginPage onLogin={this.login} loginError={this.state.loginError} />
            )}
          </div>
        </MuiThemeProvider>
      </NewMuiThemeProvider>
    );
  }
}

export default App;
