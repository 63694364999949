import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAllTaskCount, resetEntityEdits } from '../../../redux/actions';
import { openPrimaryDrawer, closePrimaryDrawer } from '../../../redux/actions/drawer';
import constants from '../../../utils/constants';
import TaskContainer from '../TaskContainer';

const VerifyTaskPage = (props) => {
  useEffect(() => {
    // if the mode is mobile, keep the primary drawer closed by default
    if (props.appMode === constants.mode.mobile) {
      props.closePrimaryDrawer();
    }
  }, [props.appMode]);

  return <TaskContainer {...props} taskType="unverified" />;
};

export default connect(
  state => ({
    currentEdits: state.edits.currentEdits,
    editsDirty: state.edits.dirty,
    appMode: state.appDimensions.mode,
  }),
  dispatch => ({
    openPrimaryDrawer: () => dispatch(openPrimaryDrawer()),
    closePrimaryDrawer: () => dispatch(closePrimaryDrawer()),
    fetchAllTaskCount: () => dispatch(fetchAllTaskCount),
    resetEditState: () => dispatch(resetEntityEdits()),
  }),
)(VerifyTaskPage);
