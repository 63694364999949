import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Chip from '@material-ui/core/Chip';
import CardHeader from './CardHeader';
import ProviderPopover from '../../../components/ProviderPopover';
import Backend from '../../../services/backend';

const styles = () => ({
  titleText: { textTransform: 'capitalize' },
  titileWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-24px',
    cursor: 'pointer',
    width: 100,
  },
});

// Custom hook to fetch provider info
const useProvider = (entityType, entityId) => {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const fetchProviderDetail = async () => {
      try {
        const providersResponse = await Backend.fetchEntityProviders(entityType, entityId);
        setProviders(providersResponse);
      } catch (error) {
        setProviders([]);
      }
    };
    if (entityId) {
      fetchProviderDetail();
    }
  }, [entityId]);
  return [providers];
};

const TargetCardHeader = ({
  classes, showTargetDetails, onGoBack, targetId, entityType,
}) => {
  const [providers] = useProvider(entityType, targetId);

  const isProviderPresent = () => providers && !!providers.length;

  if (showTargetDetails) {
    return (
      <CardHeader background="pending">
        <div className={classes.titileWrapper} role="button" tabIndex="0" onClick={onGoBack}>
          <ChevronLeftIcon cursor="pointer" />
          <Typography color="inherit" variant="h5" className={classes.titleText}>
            Back
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="inherit" className={classes.titleText}>
            to Map options
          </Typography>
          {isProviderPresent() && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                variant="outlined"
                size="small"
                color="secondary"
                style={{
                  textTransform: 'capitalize',
                }}
                label={providers[0].provider_name || 'some'}
              />
              <ProviderPopover entityType={entityType} providers={providers} />
            </div>
          )}
        </div>
      </CardHeader>
    );
  }
  return (
    <CardHeader background="target">
      <Typography color="inherit" variant="h5" className={classes.titleText}>
        map options
      </Typography>
      <Typography color="inherit" className={classes.titleText}>
        From Forza football
      </Typography>
    </CardHeader>
  );
};

TargetCardHeader.defaultProps = {
  targetId: null,
};
TargetCardHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string.isRequired,
  targetId: PropTypes.number,
  showTargetDetails: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default withStyles(styles)(TargetCardHeader);
