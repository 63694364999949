import React from 'react';
import { ListItemText, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = {
  primary: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'visible',
    },
  },
  secondary: {},
};

const ResultListItemText = ({
  primary,
  secondary,
  primaryStyle,
  secondaryStyle,
  classes,
  ...rest
}) => (
  <ListItemText
    classes={{
      primary: classNames(classes.primary, primaryStyle),
      secondary: classNames(classes.secondary, secondaryStyle),
    }}
    primary={primary}
    secondary={secondary}
    {...rest}
  />
);

export default withStyles(styles)(ResultListItemText);
