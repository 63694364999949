import React, { Component } from 'react';

import {
  Typography,
  IconButton,
  ListItem,
  List,
  Popover,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core';

import InfoIcon from '@material-ui/icons/ErrorOutline';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import providerLinks from '../utils/providerLinks';

class ProviderPopover extends Component {
  state = { anchorEl: null };

  getMonorailEntityUrl = (entityType, entityId) => {
    const entId = entityId.substr(entityId.lastIndexOf(':') + 1);

    if (entityType === 'tournament') {
      return `https://api.footballaddicts.com/admin/unique_tournaments/${entId}`;
    }

    if (entityType === 'match') {
      return `https://api.footballaddicts.com/admin/matches/${entId}`;
    }

    return `https://api.footballaddicts.com/admin/${entityType}s/${entId}`;
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  // TODO: Remove this once android moves to Multiball
  showMonorailLink = (entityType, entityId) => {
    if (entityId && entityId.indexOf('sr:') !== -1) {
      return (
        <Button
          href={this.getMonorailEntityUrl(entityType, entityId)}
          target="_blank"
          style={{ marginRight: 10 }}
        >
          Old Admin
        </Button>
      );
    }
    return null;
  };

  render() {
    const { entityType, providers, ...props } = this.props;
    const { anchorEl } = this.state;
    const open = !!anchorEl;

    return (
      <div {...props}>
        <IconButton color="inherit" onClick={this.handleClick}>
          <InfoIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List disablePadding dense>
            <ListItem style={{ backgroundColor: '#00A7E1', color: '#fff' }}>
              <ListItemText disableTypography>
                <Typography color="inherit">INFORMATION</Typography>
              </ListItemText>
            </ListItem>
            {(!providers || !providers.length) && (
              <ListItem>
                <ListItemText>No data available</ListItemText>
              </ListItem>
            )}
            {providers &&
              entityType &&
              providers.map(({ provider_name, provider_entity_id }) => (
                <ListItem key={provider_entity_id}>
                  <ListItemText
                    style={{ paddingRight: 48 }}
                    primary={provider_entity_id}
                    secondary={provider_name}
                  />
                  {entityType !== 'region' && this.showMonorailLink(entityType, provider_entity_id)}
                  <ListItemSecondaryAction>
                    <IconButton
                      href={providerLinks.getLink(provider_name, entityType, provider_entity_id)}
                      target="_blank"
                      style={{ color: '#007FA3' }}
                    >
                      <EyeIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Popover>
      </div>
    );
  }
}

export default ProviderPopover;
