import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  List,
  Typography,
  Fab,
  ListSubheader,
  LinearProgress,
  Divider,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core';
import ReloadIcon from '@material-ui/icons/Refresh';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import TranslateIcon from '@material-ui/icons/Translate';
import IconButton from '@material-ui/core/IconButton';
import { setEntityEdits, setEditsDirty } from '../../../../redux/actions';
import { categoriseProperties, parseStringToValue, parseStatusArrayToString } from './editHelper';
import AttributeTextField from './AttributeTextField';
import AttributeColorField from './AttributeColorField';
import AttributeNestedField from './AttributeNestedField';
import AttributeArrayField from './AttributeArrayField';
import AttributeTwoElementNameField from './AttributeTwoElementNameField';
import constants from '../../../../utils/constants';

class EditEntity extends Component {
  componentDidMount() {
    this.clearEdits();
  }

  onChangeProperty = propertyName => ({ target, ...colorAttr }) => {
    if (!this.editingEnabled()) {
      return;
    }

    if (target) {
      this.setPropertyEdit(propertyName, target.value, false);
    }

    // for color change // temp, will be changed once this file is being optimized
    if (!target && colorAttr && Object.keys(colorAttr).length) {
      this.setPropertyEdit(propertyName, colorAttr.hex, false);
    }
  };

  onRestoreOverwrittenProperty = (property) => {
    if (!this.editingEnabled()) {
      return;
    }

    let proposedValue;
    if (property === 'status') {
      const statusValueArray = this.props.overwrites[property].proposed_value;
      proposedValue = parseStatusArrayToString(statusValueArray);
    } else {
      proposedValue = this.props.overwrites[property].proposed_value;
    }

    this.props.dispatch(setEntityEdits({
      ...this.props.currentEdits,
      [property]: { value: proposedValue, restore: true },
    }));
  };

  onUndoEditProperty = (property, valueToSetTo = null) => {
    if (!this.editingEnabled()) {
      return;
    }

    let value;
    if (valueToSetTo && this.props.entity[property] !== valueToSetTo) {
      value = { value: valueToSetTo, restore: false };
    } else {
      value = undefined;
    }

    this.props.dispatch(setEntityEdits({
      ...this.props.currentEdits,
      [property]: value,
    }));
  };

  setPropertyEdit = (propertyName, value, restore) => {
    const { type } = this.props.attributeTypes[propertyName];
    const parsedEditValue = parseStringToValue(type, value);

    // If a parsed value is the same as the parsed string (for example for numbers)
    // we just set the parsed value, and don't set edits to dirty
    // since for this case it's not disruptive to set the text field value to the parsed one,
    // which is not the case for example for tuples
    const setValue = `${parsedEditValue}` === value ? parsedEditValue : value;

    this.props.dispatch(setEntityEdits({
      ...this.props.currentEdits,
      [propertyName]: { value: setValue, restore },
    }));

    const dirty = parsedEditValue !== setValue;
    if (dirty !== this.props.editsDirty) {
      this.props.dispatch(setEditsDirty(dirty));
    }
  };

  getCurrentValue = (property) => {
    if (this.editingEnabled() && this.props.currentEdits[property] !== undefined) {
      if (this.props.currentEdits[property].value !== null) {
        return this.props.currentEdits[property].value;
      }
      return '';
    }
    if (this.props.entity[property] !== null) {
      if (property === 'status') {
        return parseStatusArrayToString(this.props.entity[property]);
      }
      return this.props.entity[property];
    }
    return '';
  };

  getTranslationLink = (entityType, entityId) => {
    const url = `${constants.crowdin.url}${constants.crowdin[entityType]}/en-sv/#q="${entityId}"`;
    return url || constants.crowdin.url;
  };

  clearEdits = () => this.props.dispatch(setEntityEdits({}));

  // Parses edit, if needed, and updates current edit with a value
  // that's valid to send to backend
  // We can't do this on every edit, since for example tuples have a different
  // representation in fields than what is a valid payload when sending overwrites
  parseOverwrittenProperty = (propertyName) => {
    if (!this.editingEnabled()) {
      return;
    }

    const { type } = this.props.attributeTypes[propertyName];
    const currentEdit = this.props.currentEdits[propertyName];

    if (currentEdit) {
      const originalEdit = this.props.entity[propertyName];

      let parsedEditValue;
      if (currentEdit.value !== null) {
        if (typeof currentEdit.value === 'string') {
          parsedEditValue = parseStringToValue(type, currentEdit.value);
        } else {
          parsedEditValue = currentEdit.value;
        }
      } else {
        parsedEditValue = null;
      }

      if (originalEdit === parsedEditValue) {
        this.onUndoEditProperty(propertyName);
      } else if (this.props.editsDirty) {
        // Only update edits with parsed value if needed
        this.props.dispatch(setEntityEdits({
          ...this.props.currentEdits,
          [propertyName]: { value: parsedEditValue, restore: false },
        }));
        this.props.dispatch(setEditsDirty(false));
      }
    }
  };

  clearProperty = (propertyName) => {
    if (!this.editingEnabled()) {
      return;
    }

    const existingOverwrite = this.props.overwrites[propertyName];

    const originalValue = existingOverwrite
      ? existingOverwrite.proposed_value
      : this.props.entity[propertyName];

    this.setPropertyEdit(propertyName, null, originalValue === null);
  };

  // TODO: Hack until EditEntity has been generalized so we can control this
  // using redux props
  editingEnabled = () => this.props.editableProperties.length > 0;

  reloadContent = () => {
    this.clearEdits();
    this.props.reloadContent();
  };

  isPropertyEdited = propName => this.editingEnabled() && !!this.props.currentEdits[propName];

  isPropertyOverwritten = propName => this.editingEnabled() && !!this.props.overwrites[propName];

  isPropertyRestored = propName =>
    this.editingEnabled() &&
    this.isPropertyOverwritten(propName) &&
    this.props.currentEdits[propName] &&
    this.props.currentEdits[propName].restore;

  isLoading = () => this.props.writing || this.props.loadingOverwrites;

  isError = () => this.props.fetchOverwriteError || this.props.writeError;

  isLoadingOrError = () => this.isLoading() || this.isError();

  isPropertyEditable = property => (this.props.editableProperties || []).indexOf(property) >= 0;

  render() {
    const {
      entity, entityType, attributeTypes, overwrites, highlightFields,
    } = this.props;

    const {
      simplePropNames,
      arrayPropNames,
      objectPropNames,
      colorPropNames,
    } = categoriseProperties(attributeTypes);

    return (
      <div style={{ padding: 24 }}>
        <List style={{ display: 'flex', flexFlow: 'column' }}>
          <ListSubheader style={{ textTransform: 'capitalize' }}>
            {entityType} Information
            <ListItemSecondaryAction>
              <Tooltip title="Translation">
                <IconButton
                  aria-label="translations"
                  color="inherit"
                  target="_blank"
                  href={this.getTranslationLink(entityType, entity.id)}
                >
                  <TranslateIcon color="inherit" />
                </IconButton>
              </Tooltip>
              {entityType === 'match' && (
                <Tooltip title="Videos">
                  <IconButton
                    aria-label="videos"
                    color="inherit"
                    target="_blank"
                    href={`${constants.spectator.url}/matches/${entity.id}`}
                  >
                    <VideoLibraryIcon color="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListSubheader>
          <div
            style={{
              flexBasis: '100%',
              display: 'flex',
              flexFlow: 'row wrap',
              paddingBottom: '20px',
            }}
          >
            <AttributeTextField
              entity={entity}
              propNames={simplePropNames}
              attributeTypes={attributeTypes}
              overwrites={overwrites}
              getCurrentValue={this.getCurrentValue}
              onChangeProperty={this.onChangeProperty}
              onUndoEditProperty={this.onUndoEditProperty}
              parseOverwrittenProperty={this.parseOverwrittenProperty}
              clearProperty={this.clearProperty}
              onRestoreOverwrittenProperty={this.onRestoreOverwrittenProperty}
              isLoadingOrError={this.isLoadingOrError}
              isPropertyEditable={this.isPropertyEditable}
              isPropertyEdited={this.isPropertyEdited}
              isPropertyOverwritten={this.isPropertyOverwritten}
              isPropertyRestored={this.isPropertyRestored}
              highlightFields={highlightFields}
            />
          </div>

          <AttributeColorField
            entity={entity}
            propNames={colorPropNames}
            attributeTypes={attributeTypes}
            overwrites={overwrites}
            getCurrentValue={this.getCurrentValue}
            onChangeProperty={this.onChangeProperty}
            onUndoEditProperty={this.onUndoEditProperty}
            parseOverwrittenProperty={this.parseOverwrittenProperty}
            clearProperty={this.clearProperty}
            onRestoreOverwrittenProperty={this.onRestoreOverwrittenProperty}
            isLoadingOrError={this.isLoadingOrError}
            isPropertyEditable={this.isPropertyEditable}
            isPropertyEdited={this.isPropertyEdited}
            isPropertyOverwritten={this.isPropertyOverwritten}
            isPropertyRestored={this.isPropertyRestored}
          />

          {arrayPropNames.length > 0 && <Divider style={{ marginRight: -24, marginLeft: -24 }} />}

          {entityType === 'manager' ? (
            <AttributeTwoElementNameField
              entity={entity}
              parentPropertyName="full_name"
              attributeTypes={attributeTypes}
              overwrites={overwrites}
              getCurrentValue={this.getCurrentValue}
              onChangeProperty={this.onChangeProperty}
              onUndoEditProperty={this.onUndoEditProperty}
              parseOverwrittenProperty={this.parseOverwrittenProperty}
              clearProperty={this.clearProperty}
              onRestoreOverwrittenProperty={this.onRestoreOverwrittenProperty}
              isLoadingOrError={this.isLoadingOrError}
              isPropertyEditable={this.isPropertyEditable}
              isPropertyEdited={this.isPropertyEdited}
              isPropertyOverwritten={this.isPropertyOverwritten}
              isPropertyRestored={this.isPropertyRestored}
              highlightFields={highlightFields}
            />
          ) : (
            <AttributeArrayField
              entity={entity}
              propNames={arrayPropNames}
              attributeTypes={attributeTypes}
              highlightFields={highlightFields}
            />
          )}

          <AttributeNestedField
            entity={entity}
            propNames={objectPropNames}
            attributeTypes={attributeTypes}
            highlightFields={highlightFields}
          />

          <Divider style={{ marginRight: -24, marginLeft: -24 }} />

          <div
            style={{
              marginTop: 24,
              flexBasis: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <LinearProgress hidden={!this.isLoading()} style={{ flex: 1, marginLeft: 24, marginRight: 24 }} />
            {this.props.writeError && (
              <Typography>Error when overwriting entity. Please reload and try again.</Typography>
            )}
            {this.props.fetchOverwriteError && (
              <Typography>Error when fetching overwrites. Please reload try again</Typography>
            )}
            {this.isError() && (
              <Fab
                style={{ marginLeft: 24, marginRight: 24, minWidth: 56 }}
                color="primary"
                aria-label="Save"
                onClick={this.reloadContent}
              >
                <ReloadIcon />
              </Fab>
            )}
          </div>
        </List>
      </div>
    );
  }
}

export default connect()(EditEntity);
