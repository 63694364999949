import React from 'react';
import { ListItem } from '@material-ui/core';
import ResultListItemText from '../components/ResultListItemText';
import ResultListItemAvatar from '../components/ResultListItemAvatar';

const TournamentEntityListItem = ({ entity, ...rest }) => (
  // TODO: make this outer element as a generic component
  <ListItem button {...rest} style={{ height: 60 }}>
    <ResultListItemAvatar entityId={entity.region.id} entityType="region" imgKey="flag_thumbnail" />
    <ResultListItemText primary={entity.name} secondary={entity.region ? entity.region.name : ''} />
  </ListItem>
);

export default TournamentEntityListItem;
