import React from 'react';
import { connect } from 'react-redux';
import RegionIcon from '@material-ui/icons/Public';
import { getReducedEntityObjectList } from '../../../redux/reducers';
import constants from '../../../utils/constants';
import RegionEntityListItem from './RegionEntityListItem';
import EntitySearch from '../components/EntitiySearch';

const RegionSearch = connect(state => ({
  entities: getReducedEntityObjectList(state.entities.regions),
}))(props => (
  <EntitySearch
    {...{
      ...props,
      title: 'Regions',
      entityType: constants.entityType.region,
      EntityListItem: RegionEntityListItem,
      searches: [
        {
          search: 'name',
          leftIcon: <RegionIcon />,
          hintText: 'Region',
        },
      ],
    }}
  />
));

export default RegionSearch;
