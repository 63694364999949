import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import {
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
  selectEntity,
} from '../../../redux/actions';
import constants from '../../../utils/constants';
import MatchCard from './MatchCard';

// This can be genaralized for all the entities,
// intentionally its been kept as this, as it invloves React Hooks
const MatchCardContainer = ({ match, getEntity, ...rest }) => {
  // Match is a router prop, not the football match :P
  const { matchId } = match.params;
  useEffect(() => {
    getEntity(matchId, constants.entityType.match);
  }, [matchId]);

  return <MatchCard {...rest} />;
};

const mapStateToProps = state => {
  const isLoadingOrError = state.matchOverwrites.loading || state.matchOverwrites.writing;
  const arePropertiesEdited =
    Object.values(state.edits.currentEdits).filter(value => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    mbMatch: getReducedEntityObject(state.entities.selectedMatch),
    attributeTypes: getReducedEntityTypes(state.entities.selectedMatch),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = dispatch => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (matchId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('match', matchId, currentEdits)),
  fetchEntityAndOverwrites: matchId => dispatch(fetchEntityAndOverwrites('match', matchId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MatchCardContainer),
);
