const constants = {
  drawerWidth: 350,
  windowResizeDebounceDelay: 250,
  mode: {
    laptop: 'laptop',
    tablet: 'tablet',
    mobile: 'mobile',
  },
  entityType: {
    tournament: 'tournament',
    team: 'team',
    player: 'player',
    match: 'match',
    region: 'region',
    season: 'season',
    stadium: 'stadium',
    manager: 'manager',
  },
  taskType: {
    pending: 'pending',
    unverified: 'unverified',
  },
  similarity: {
    high: 0.9,
    medium: 0.6,
    low: 0,
  },
  ffColors: {
    orange: '#FF5100',
    lightOrange: '#f5a623',
    grey: '#D0D0CE',
    candyPink: '#f5b6cd',
    lightBlue: '#a5c5ed',
    neonGreen: '#38D430',
    yellow: '#FFED00',
    dalablue: '#006189',
    black: '#000000',
    green: '#4a8b2c',
  },
  images: {
    image_base_url: 'https://images.multiball.forzafootball.net/',
    fallback_url: 'https://via.placeholder.com/150.png',
    team: {
      background_full: {
        title: 'Team Background Full',
        displayWidth: 600,
        displayHeight: 400,
        uploadWidth: 900,
        uploadHeight: 600,
        urlKey: 'backgrounds/team/full/',
      },
      badge_full: {
        title: 'Team Badge Full',
        displayWidth: 200,
        displayHeight: 200,
        uploadWidth: 400,
        uploadHeight: 400,
        urlKey: 'badges/team/full/',
      },
      badge_thumbnail: {
        title: 'Team Badge Thumbnail',
        displayWidth: 100,
        displayHeight: 100,
        uploadWidth: 100,
        uploadHeight: 100,
        urlKey: 'badges/team/thumbnail/',
      },
      badge_tiny: {
        title: 'Team Badge Tiny',
        displayWidth: 45,
        displayHeight: 45,
        uploadWidth: 45,
        uploadHeight: 45,
        urlKey: 'badges/team/tiny/',

      },
    },
    player: {
      photo_full: {
        title: 'Player Full Image',
        displayWidth: 200,
        displayHeight: 200,
        uploadWidth: 400,
        uploadHeight: 400,
        urlKey: 'photos/player/full/',
      },
      photo_thumbnail: {
        title: 'Player Thumbnail Image',
        displayWidth: 150,
        displayHeight: 150,
        uploadWidth: 150,
        uploadHeight: 150,
        urlKey: 'photos/player/thumbnail/',
      },
    },
    tournament: {
      background_full: {
        title: 'Tournament Background Full Image',
        displayWidth: 600,
        displayHeight: 400,
        uploadWidth: 1406,
        uploadHeight: 1008,
        urlKey: 'backgrounds/tournament/full/',
      },
    },
    region: {
      background_full: {
        title: 'Region Background Full Image',
        displayWidth: 600,
        displayHeight: 400,
        uploadWidth: 703,
        uploadHeight: 504,
        urlKey: 'backgrounds/region/full/',
      },
      flag_thumbnail: {
        title: 'Region Flag Thumbnail Image',
        displayWidth: 48,
        displayHeight: 48,
        uploadWidth: 48,
        uploadHeight: 48,
        urlKey: 'flags/region/thumbnail/',
      },
    },
    stadium: {
      background_full: {
        title: 'Stadium Background Full Image',
        displayWidth: 600,
        displayHeight: 400,
        uploadWidth: 1406,
        uploadHeight: 1008,
        urlKey: 'backgrounds/stadium/full/',
      },
    },
    manager: {
      background_full: {
        title: 'Manager Full Image',
        displayWidth: 200,
        displayHeight: 200,
        uploadWidth: 400,
        uploadHeight: 400,
        urlKey: 'photos/manager/full/',
      },
    },
  },
  crowdin: {
    url: 'https://crowdin.com/translate/forza-football-entities/',
    region: 125,
    team: 123,
    tournament: 127,
  },
  spectator: {
    url: 'https://spectator.forzafootball.net',
  },
};

export default constants;
