import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import {
  ListItem,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from '@material-ui/core';
import ResultListItemAvatar from '../modules/entities/components/ResultListItemAvatar';

const isRegion = entityType => entityType === 'region';
const isSeason = entityType => entityType === 'season';
const isTournament = entityType => entityType === 'tournament' || entityType === 'tournaments';
const isTeam = entityType =>
  entityType === 'team' ||
  entityType === 'teams' ||
  entityType === 'home_team' ||
  entityType === 'away_team';

const getRouteLink = (entity, entityId) => {
  if (isRegion(entity) || entity === 'tournament') {
    return `/${entity}s/${entityId}`;
  }
  if (isTeam(entity)) {
    return `/teams/${entityId}`;
  }
  return `/${entity}/${entityId}`;
};

const getImageEntityType = (entType) => {
  if (isSeason(entType) || isTournament(entType)) {
    // Since tournaments & seasons don't have image, we use that of region instead
    return 'region';
  } else if (isTeam(entType)) {
    return 'team';
  } else if (entType === 'players') {
    return 'player';
  }
  return entType;
};

const getImageKey = (entityType) => {
  if (isSeason(entityType) || isRegion(entityType) || isTournament(entityType)) {
    return 'flag_thumbnail';
  }
  if (isTeam(entityType)) {
    return 'badge_thumbnail';
  }
  return 'flag_thumbnail';
};

const PropertyListItem = ({
  id,
  primary,
  secondary,
  routeLinkUrl,
  entityType,
  highlightFields,
}) => (
  <ListItem
    button
    component={Link}
    to={routeLinkUrl}
    style={{
      backgroundColor:
        highlightFields && highlightFields.includes(entityType) ? 'rgb(255, 204, 204)' : 'inherit',
    }}
  >
    <ResultListItemAvatar
      entityId={id}
      entityType={getImageEntityType(entityType)}
      imgKey={getImageKey(entityType)}
    />
    <ListItemText primary={primary} secondary={secondary} style={{ textTransform: 'capitalize' }} />
  </ListItem>
);

const ExpandablePropertyItem = ({ property, objectList = [], highlightFields = [] }) => {
  const [expanded, setExpanded] = useState(false);
  const onToggleExpand = () => {
    setExpanded(!expanded);
  };

  if (!objectList.length) return null;

  if (objectList.length === 1) {
    return (
      <PropertyListItem
        key={objectList[0].id}
        id={isTournament(property) ? objectList[0].region.id : objectList[0].id}
        primary={`${property}`}
        secondary={`${objectList[0].name}(${objectList[0].id})`}
        routeLinkUrl={getRouteLink(property, objectList[0].id)}
        entityType={property.toLowerCase()}
        highlightFields={highlightFields}
      />
    );
  }

  return (
    <div>
      <ListItem
        button
        onClick={onToggleExpand}
        style={{
          backgroundColor:
            highlightFields && highlightFields.includes(property)
              ? 'rgb(255, 204, 204)'
              : 'inherit',
        }}
      >
        <ListItemText
          primary={property}
          primaryTypographyProps={{
            style: {
              textTransform: 'capitalize',
            },
          }}
          secondary={objectList.map(obj => obj.name).join(', ') || '-'}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={onToggleExpand}>
            {expanded ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={expanded}>
        {objectList.map(object => (
          <React.Fragment key={object.id}>
            <PropertyListItem
              id={isTournament(property) ? object.region.id : object.id}
              primary={`${object.name}(${object.id})`}
              routeLinkUrl={getRouteLink(property, object.id)}
              entityType={property.toLowerCase()}
              highlightFields={highlightFields}
            />
            <Divider />
          </React.Fragment>
        ))}
        {objectList.length === 0 && (
          <ListItem>
            <ListItemText secondary="No entries" />
          </ListItem>
        )}
      </Collapse>
    </div>
  );
};

export default ExpandablePropertyItem;
