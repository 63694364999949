import React from 'react';
import {
  ListItem,
  ListItemText,
  Avatar,
  ListItemIcon,
  Typography,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';

const eventStyles = {
  flexHome: {
    flexDirection: 'row',
  },
  flexAway: {
    flexDirection: 'row-reverse',
  },
  textContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    wordWrap: 'break-word',
  },
  substituteIn: {
    color: 'green',
  },
  bold: {
    fontWeight: 'bold',
  },
  substituteOut: {
    color: 'red',
  },
  icon: {
    margin: '0 16px',
  },
  iconRight: {
    marginLeft: 36,
  },
  iconLeft: {
    marginRight: 36,
  },
};

const EventListItem = ({
  side, type, primary, secondary, icon, classes, primaryBold,
}) => (
  <ListItem
    className={classNames({
      [classes.flexHome]: side === 'home',
      [classes.flexAway]: side === 'away',
    })}
  >
    <ListItemText
      className={classes.textContainer}
      primary={
        <Typography
          className={classNames(classes.text, {
            [classes.substituteIn]: type === 'substitution',
            [classes.bold]: primaryBold,
          })}
          variant="inherit"
          align={side === 'home' ? 'right' : 'left'}
        >
          {primary}
        </Typography>
      }
      secondary={
        <Typography
          className={classNames(classes.text, {
            [classes.substituteOut]: type === 'substitution',
          })}
          variant="inherit"
          align={side === 'home' ? 'right' : 'left'}
        >
          {secondary}
        </Typography>
      }
    />
    <ListItemIcon
      className={classNames(classes.icon, {
        [classes.iconLeft]: side === 'home',
        [classes.iconRight]: side === 'away',
      })}
    >
      <Avatar src={icon} />
    </ListItemIcon>
  </ListItem>
);

export default withStyles(eventStyles)(EventListItem);
