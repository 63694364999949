const initialState = {
  pendingSuggestions: [],
  unverifiedSuggestions: [],
  pendingSuggestionsLoading: false,
  unverifiedSuggestionsLoading: false,
  pendingSuggestionsError: false,
  unverifiedSuggestionsError: false,
};

const taskSuggestions = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FETCH_PENDING_SUGGESTIONS_PENDING':
      return {
        ...state,
        pendingSuggestions: [],
        pendingSuggestionsLoading: true,
        pendingSuggestionsError: false,
      };
    case 'FETCH_UNVERIFIED_SUGGESTIONS_PENDING':
      return {
        ...state,
        unverifiedSuggestions: [],
        unverifiedSuggestionsLoading: true,
        unverifiedSuggestionsError: false,
      };
    case 'FETCH_PENDING_SUGGESTIONS_FULFILLED':
      return {
        ...state,
        pendingSuggestions: action.payload,
        pendingSuggestionsLoading: false,
        pendingSuggestionsError: false,
      };
    case 'FETCH_UNVERIFIED_SUGGESTIONS_FULFILLED':
      return {
        ...state,
        unverifiedSuggestions: action.payload,
        unverifiedSuggestionsLoading: false,
        unverifiedSuggestionsError: false,
      };
    case 'FETCH_PENDING_SUGGESTIONS_REJECTED':
      return {
        ...state,
        pendingSuggestions: [],
        pendingSuggestionsLoading: false,
        pendingSuggestionsError: true,
      };
    case 'FETCH_UNVERIFIED_SUGGESTIONS_REJECTED':
      return {
        ...state,
        unverifiedSuggestions: [],
        unverifiedSuggestionsLoading: false,
        unverifiedSuggestionsError: true,
      };
    default:
      return state;
  }
};

export default taskSuggestions;
