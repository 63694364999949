import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardHeader from './CardHeader';
import ProviderPopover from '../../../components/ProviderPopover';

const styles = () => ({
  titleText: { textTransform: 'capitalize' },
});

const TaskCardHeader = ({
  classes, entityType, taskState, providerName, providerEntityId,
}) => {
  const getHeaderText = () => {
    switch (taskState) {
      case 'pending':
        return `incoming ${entityType}`;

      case 'unverified_accept':
        return `${entityType} created`;

      case 'unverified_reject':
        return `${entityType} ignored`;

      case 'unverified_map':
        return `${entityType} mapped`;

      default:
        return '';
    }
  };

  const headerText = taskState && getHeaderText();
  return (
    <CardHeader background={taskState}>
      <Typography color="inherit" variant="h5" className={classes.titleText}>
        {headerText}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography color="inherit" className={classes.titleText}>
          {providerName ? `From ${providerName}` : ''}
        </Typography>
        {providerName === 'sportradar' && (
          <ProviderPopover
            entityType={entityType}
            providers={[
              {
                provider_name: providerName,
                provider_entity_id: providerEntityId,
              },
            ]}
          />
        )}
      </div>
    </CardHeader>
  );
};

TaskCardHeader.defaultProps = {
  providerEntityId: '',
};

TaskCardHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string.isRequired,
  taskState: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  providerEntityId: PropTypes.string,
};

// As the task card header doesn't change unless task changes,
// This is a good use case for memoising the component.
const MemoisedTaskCardHeader = React.memo(withStyles(styles)(TaskCardHeader));
export default MemoisedTaskCardHeader;
