import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { DatePicker } from 'material-ui';
import CloseIcon from '@material-ui/icons/Close';
import dateFormat from 'dateformat';
import ResultListItemAvatar from '../modules/entities/components/ResultListItemAvatar';

const hasInputField = (evt) => {
  // Since Materil UI doesn't support input fields inside the Select,
  // in order to identify the input field, this is used
  const { currentTarget } = evt;
  if (currentTarget) {
    const childEls = Array.from(currentTarget.children);
    return !!childEls.find(el => el.className.includes('MuiFormControl-root'));
  }
  return false;
};

class SearchField extends Component {
  state = {
    value: this.props.value || '',
    loading: false,
    propLoading: false,
    isSelectOpen: false,
    filterInputVal: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.propLoading && prevState.loading && nextProps.loading) {
      return { ...prevState, loading: true, propLoading: true };
    }
    if (prevState.propLoading && prevState.loading && !nextProps.loading) {
      return { ...prevState, loading: false, propLoading: false };
    }

    return prevState;
  }

  handleChange = (event) => {
    // If the clicked element is text not text element,
    // set the value for search
    if (!hasInputField(event)) {
      const { value } = event.target;
      this.handleSearch(value);
      this.setState({
        value,
      });
    }
  };

  handleOpen = () => {
    this.setState({ isSelectOpen: true });
  };

  handleClose = (event) => {
    if (event.target.type !== 'text') {
      this.setState({ isSelectOpen: false });
    }
  };

  handleInputChange = (event) => {
    this.setState({ filterInputVal: event.target.value });
  };

  handleSearch = (value) => {
    const { handleSearch } = this.props;

    if (handleSearch) {
      this.setState({ loading: true });
      handleSearch(value);
    }
  };

  handleClearSearch = () => {
    if (this.props.type === 'date') {
      const now = new Date();
      this.handleNewDate(now);
      this.setState({ value: now });
    } else {
      this.handleSearch('');
      this.setState({ value: '' });
    }
  };

  handleNewDate = date => this.handleChange({ target: { value: dateFormat(date, 'yyyy-mm-dd') } });

  renderSelections = (selections) => {
    let listItems = [...selections];
    if (this.state.filterInputVal) {
      // eslint-disable-next-line no-unused-vars
      listItems = selections.filter(({ id, value }) =>
        value && value.name.toLowerCase().includes(this.state.filterInputVal.toLowerCase()));
    }

    const secondaryText = (value) => {
      const regionName = value && value.region ? value.region.name : '';
      const gender = value && value.sex ? value.sex : '';
      const ageGroup = value && value.age_group ? `U${value.age_group}` : '';
      return `${regionName} - ${gender} ${ageGroup}`;
    };

    return listItems.map(({ id, value }) => (
      <MenuItem key={id} value={id}>
        {this.state.isSelectOpen && value.region && (
          <ResultListItemAvatar
            entityType="region"
            entityId={value.region.id}
            imgKey="flag_thumbnail"
            style={{ height: 20, width: 20 }}
          />
        )}
        <ListItemText primary={value.name} secondary={secondaryText(value)} />
      </MenuItem>
    ));
  };

  // eslint-disable-next-line consistent-return
  renderSelect = () => {
    const currentSelection = this.state.value;
    const { selections, hintText } = this.props;
    const selectionValues = selections.map(({ id }) => id);
    if (
      currentSelection &&
      currentSelection !== '' &&
      selectionValues.indexOf(currentSelection) === -1
    ) {
      this.handleClearSearch();
    } else {
      return (
        <Select
          disableUnderline
          displayEmpty
          value={currentSelection}
          onChange={this.handleChange}
          open={this.state.isSelectOpen}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          fullWidth
        >
          {hintText && (
            <MenuItem disabled value="">
              {hintText}
            </MenuItem>
          )}
          <MenuItem button={false}>
            <TextField
              style={{ width: '100%' }}
              value={this.state.filterInputVal}
              placeholder={`Filter ${hintText}`}
              onChange={this.handleInputChange}
              onKeyDown={e => e.stopPropagation()}
            />
          </MenuItem>
          {this.renderSelections(selections)}
        </Select>
      );
    }
  };

  render() {
    const { leftIcon, hintText, type = 'default' } = this.props;
    if (type === 'toggle') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 12,
          }}
        >
          <Typography style={{ color: 'black' }} variant="caption">
            {hintText}
          </Typography>{' '}
          <Switch
            color="primary"
            checked={this.state.value}
            onChange={event => this.handleChange({ target: { value: event.target.checked } })}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton disabled={!this.state.value} color="primary">
          {leftIcon}
        </IconButton>

        {type === 'date' && (
          <DatePicker
            hintText={hintText}
            underlineShow={false}
            style={{ flex: 1 }}
            textFieldStyle={{ width: '100%' }}
            name="MatchDatePicker"
            value={this.state.value ? new Date(this.state.value) : null}
            container="inline"
            autoOk
            onChange={(evt, date) => {
              this.handleNewDate(date);
            }}
          />
        )}
        {type === 'select' && this.renderSelect()}
        {type === 'default' && (
          <TextField
            InputProps={{ disableUnderline: true }}
            fullWidth
            id="text-field-controlled"
            value={this.state.value}
            onChange={this.handleChange}
            placeholder={hintText}
          />
        )}

        {/* we show loading at the results pane for now  */}
        {/* <Fade in={this.state.loading}>
          <CircularProgress size={24} />
        </Fade> */}
        <IconButton color="primary" disabled={!this.state.value} onClick={this.handleClearSearch}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  }
}

export default SearchField;
