import React from 'react';
import { ListItem } from '@material-ui/core';
import ResultListItemText from '../components/ResultListItemText';
import ResultListItemAvatar from '../components/ResultListItemAvatar';

const Secondary = ({ region, sex }) => (
  <React.Fragment>
    {region}
    {' - '}
    {sex}
  </React.Fragment>
);

const TeamEntityListItem = ({ entity, ...rest }) => (
  <ListItem button {...rest} style={{ height: 60 }}>
    <ResultListItemAvatar entityId={entity.id} entityType="team" imgKey="badge_thumbnail" />
    <ResultListItemText
      primary={`${entity.name} ${entity.age_group ? `U${entity.age_group}` : ''}`}
      secondary={<Secondary region={entity.region.name || ''} sex={entity.sex || ''} />}
    />
  </ListItem>
);

export default TeamEntityListItem;
