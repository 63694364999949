import React from 'react';
import { ListItem, List, ListItemText } from '@material-ui/core';
import AttributeNestedField from './AttributeNestedField';

const isAssocArray = (attributeTypes, property) =>
  attributeTypes[property].type &&
  attributeTypes[property].type.array &&
  attributeTypes[property].type.array.assoc;

const ArrayOfStringsPropertyItem = ({ property, entries }) => (
  <List style={{ padding: '0' }}>
    <ListItem>
      <ListItemText primary={entries.join(' - ') || '-'} secondary={property} />
    </ListItem>
  </List>
);

const AttributeArrayField = ({ entity, propNames, attributeTypes, highlightFields = [] }) =>
  propNames
    .filter(propName => !!entity[propName])
    .map(propName =>
      (isAssocArray(attributeTypes, propName) ? (
        <AttributeNestedField
          entity={entity}
          propNames={[propName]}
          key={propName}
          highlightFields={highlightFields}
        />
      ) : (
        <ArrayOfStringsPropertyItem property={propName} entries={entity[propName]} key={propName} />
      )));

export default AttributeArrayField;
