import React from 'react';
import classNames from 'classnames';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import {
  Typography,
  Drawer,
  withStyles,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Paper,
  List,
  ListSubheader,
  Divider,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

import UserInfoCard from './UserInfoCard';
import sections from '../../routes/index';
import constants from '../../utils/constants';

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: constants.drawerWidth,
    border: 'none',
    height: '100vh',
  },
  drawerContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  drawerSection: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  hideVerticalScroll: {
    overflowY: 'hidden',
  },
  secondaryDrawerSection: {
    display: 'flex',
    marginLeft: constants.drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  secondaryDrawerSectionShift: {
    marginLeft: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  secondaryDrawerSpacer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 56,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
  },
  selectedRoute: {
    fontWeight: 'normal',
    color: theme.palette.primary.main,
  },
  indicator: {
    backgroundColor: '#d56262',
    fontWeight: 'normal',
    minWidth: 8,
    height: 16,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 4px',
  },
  grayIndicator: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  toolbar: theme.mixins.toolbar,
});

const AppDrawer = ({
  user,
  drawerOpen,
  secondarySectionOpen,
  pendingTaskCount,
  unverifiedTaskCount,
  verifiedPendingTaskCount,
  rejectedTaskCount,
  onLogout,
  openSecondarySection,
  closeSecondarySection,
  location,
  classes,
}) => (
  <Drawer
    open={drawerOpen}
    variant="persistent"
    PaperProps={{ elevation: 2 }}
    classes={{ paper: classes.drawerPaper }}
  >
    <div className={classes.toolbar} />
    <div className={classes.drawerContent}>
      <div
        className={classNames(classes.drawerSection, {
          [classes.hideVerticalScroll]: secondarySectionOpen,
        })}
      >
        <UserInfoCard user={user} onLogout={onLogout} />
        <List>
          {sections.map(section => (
            <div key={`app_section_${section.header}`}>
              <ListSubheader disableSticky>{section.header}</ListSubheader>
              {section.routes
                .filter(route => route.icon)
                .map((route) => {
                  const openSecSection = route.drawerComponent ? openSecondarySection : null;
                  return (
                    <MenuItem
                      onClick={openSecSection}
                      component={Link}
                      to={route.path}
                      key={route.path}
                    >
                      {route.icon && (
                        <ListItemIcon
                          className={classNames({
                            [classes.selectedRoute]: location.pathname === route.path,
                          })}
                        >
                          <route.icon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        classes={{
                          primary: classNames({
                            [classes.selectedRoute]: location.pathname === route.path,
                          }),
                        }}
                        primary={route.title || ''}
                      />

                      {route.title === 'Incoming' && (
                        <Typography
                          className={classes.indicator}
                          variant="caption"
                          color="secondary"
                        >
                          {pendingTaskCount}
                        </Typography>
                      )}
                      {route.title === 'Verification' && (
                        <Typography
                          className={classes.indicator}
                          variant="caption"
                          color="secondary"
                        >
                          {unverifiedTaskCount}
                        </Typography>
                      )}
                      {route.title === 'Verified pending' && (
                        <Typography
                          className={classNames(classes.indicator, classes.grayIndicator)}
                          variant="caption"
                          color="secondary"
                        >
                          {verifiedPendingTaskCount}
                        </Typography>
                      )}
                      {route.title === 'Rejected' && (
                        <Typography
                          className={classNames(classes.indicator, classes.grayIndicator)}
                          variant="caption"
                          color="secondary"
                        >
                          {rejectedTaskCount}
                        </Typography>
                      )}
                    </MenuItem>
                  );
                })}
              <Divider />
            </div>
          ))}
        </List>
      </div>
      <Backdrop
        open={secondarySectionOpen}
        className={classNames(classes.drawerSection)}
        style={{ zIndex: 0, pointerEvents: 'none' }}
      />
      <div
        className={classNames(
          classes.drawerSection,
          classes.secondaryDrawerSection,
          classes.hideVerticalScroll,
          {
            [classes.secondaryDrawerSectionShift]: secondarySectionOpen,
          },
        )}
      >
        <button className={classes.secondaryDrawerSpacer} onClick={closeSecondarySection} />
        <Paper
          elevation={2}
          style={{ flex: 1, overflowY: 'auto', borderRadius: 0 }}
        >
          <Switch>
            {sections.map(section =>
              section.routes
                .filter(route => route.drawerComponent)
                .map(route => (
                  <Route exact path={route.path}>
                    <route.drawerComponent />
                  </Route>
                )))}
          </Switch>
        </Paper>
      </div>
    </div>
  </Drawer>
);

export default withRouter(withStyles(styles, { withTheme: true })(AppDrawer));
