import React from 'react';
import { ListItem } from '@material-ui/core';
import ResultListItemText from '../components/ResultListItemText';
import ResultListItemAvatar from '../components/ResultListItemAvatar';
import { getSuggestionPrimaryText, getSuggestionSecondaryText } from '../../tasks/helper';

const ManagerEntityListItem = ({ entity, onClick }) => (
  <ListItem button onClick={onClick} style={{ height: 60 }}>
    <ResultListItemAvatar entityId={entity.id} entityType="manager" imgKey="" />
    <ResultListItemText
      primary={getSuggestionPrimaryText('manager', entity)}
      secondary={getSuggestionSecondaryText('manager', entity)}
    />
  </ListItem>
);

export default ManagerEntityListItem;
