import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  getSuggestionPrimaryText,
  getSuggestionSecondaryText,
  getThumbnailImgUri,
} from '../helper';
import { getReducedEntityObject } from '../../../redux/reducers';
import SimilarityScore from './SimilarityScore';
import ResultListItemAvatar from './ResultListItemAvatar';

const SearchResultsListItem = ({ entity, entityType, onResultSelect }) => {
  let entityDetails;
  if (entity.details) {
    entityDetails = getReducedEntityObject(entity.details);
  } else {
    entityDetails = getReducedEntityObject(entity);
  }

  const entityId = entityType !== 'tournament' ? entity.target_forza_id : entityDetails.region.id;
  const imgUri = getThumbnailImgUri(entityType, entityId);

  const primaryText = getSuggestionPrimaryText(entityType, entityDetails);
  const secondaryText = getSuggestionSecondaryText(entityType, entityDetails);

  return (
    <ListItem
      onClick={onResultSelect}
      style={{ cursor: 'pointer', position: 'relative', padding: 8 }}
      button
    >
      <ResultListItemAvatar uri={imgUri} />
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        secondaryTypographyProps={{ style: { textTransform: 'capitalize' } }}
      />
      {entity.similarity_score && <SimilarityScore score={entity.similarity_score} />}
      {/* TODO: Enable this to show badge of provider once the API is ready */}
      {/* <p
      style={{
        position: 'absolute',
        left: '40px',
        top: 25,
        background: 'cornflowerblue',
        borderRadius: 10,
        fontSize: '10px',
        padding: '0 5px',
        color: 'white',
        fontWeight: 'bold',
      }}
    >
      CB
    </p> */}
    </ListItem>
  );
};

SearchResultsListItem.propTypes = {
  entityType: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Object).isRequired,
  onResultSelect: PropTypes.func.isRequired,
};

const SearchResultsList = ({ entityType, results, onResultSelect }) => (
  <List style={{ height: '100%', maxHeight: 700, overflow: 'auto' }}>
    {results.map((result, index) => {
      let key;
      if (result.details) {
        // This is for suggestions DS
        key = result.target_forza_id ? result.target_forza_id : index;
      } else if (Array.isArray(result)) {
        // This is for entity search DS
        key = getReducedEntityObject(result).id;
      } else {
        key = index;
      }

      return (
        <SearchResultsListItem
          key={key}
          entity={result}
          entityType={entityType}
          onResultSelect={() => onResultSelect(result)}
        />
      );
    })}
  </List>
);

SearchResultsList.propTypes = {
  entityType: PropTypes.string.isRequired,
  results: PropTypes.instanceOf(Array).isRequired,
  onResultSelect: PropTypes.func.isRequired,
};

export default SearchResultsList;
