import React from 'react';
import { Paper, Fade } from '@material-ui/core';

const EntityCard = ({ children }) => (
  <Fade in style={{ display: 'flex', flex: 1 }}>
    <div
      style={{
        maxWidth: 800,
        overflowY: 'auto',
        overflowX: 'auto',
        flex: 1,
      }}
    >
      <Paper style={{ margin: 24, flex: 1 }}>{children}</Paper>
    </div>
  </Fade>
);

export default EntityCard;
