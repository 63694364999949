import { combineReducers } from 'redux';

import entities from './entities';
import entityOverwrites from './entityOverwrites';
import tasks from './tasks';
import selectedTask from './selectedTask';
import taskList from './taskList';
import taskSuggestions from './taskSuggestions';
import matchEvents from './matchEvents';
import tournamentProviders from './tournamentProviders';
import matchProviders from './matchProviders';
import availableProviders from './availableProviders';
import edits from './edits';
import alert from './alert';
import drawer from './drawer';
import appDimensions from './appDimensions';

export * from './helper';

export default combineReducers({
  entities,
  edits,
  tournamentOverwrites: entityOverwrites('tournament'),
  teamOverwrites: entityOverwrites('team'),
  managerOverwrites: entityOverwrites('manager'),
  playerOverwrites: entityOverwrites('player'),
  regionOverwrites: entityOverwrites('region'),
  seasonOverwrites: entityOverwrites('season'),
  matchOverwrites: entityOverwrites('match'),
  stadiumOverwrites: entityOverwrites('stadium'),
  tasks,
  taskSuggestions,
  rejectedTasks: taskList('rejected'),
  verifiedPendingTasks: taskList('verified_pending'),
  selectedVerifiedPendingTask: selectedTask('verified_pending'),
  selectedRejectedTask: selectedTask('rejected'),
  matchEvents,
  tournamentProviders,
  matchProviders,
  drawer,
  appDimensions,
  availableProviders: combineReducers(['tournament', 'team', 'player', 'region', 'season', 'match', 'stadium', 'manager']
    .map(entityType => ({ [entityType]: availableProviders(entityType) }))
    .reduce((a, b) => ({ ...a, ...b }))),
  alert,
});
