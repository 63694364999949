import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const TargetEntityDetails = ({ targetEntity, targetEntityTypes, renderEditEntity }) => (
  <Fragment>
    {targetEntity && (
      <div>
        <Typography>Properties</Typography>
        {renderEditEntity(targetEntity, targetEntityTypes, [])}
      </div>
    )}
  </Fragment>
);

TargetEntityDetails.propTypes = {
  targetEntity: PropTypes.instanceOf(Object).isRequired,
  targetEntityTypes: PropTypes.instanceOf(Object).isRequired,
  renderEditEntity: PropTypes.func.isRequired,
};

export default TargetEntityDetails;
