import React from 'react';
import { Paper, Typography } from '@material-ui/core';

const NoTasksPlaceholder = () => (
  <Paper style={{ padding: 20, margin: 20 }}>
    <Typography>You have no tasks to work on at this moment!</Typography>
  </Paper>
);

export default NoTasksPlaceholder;
