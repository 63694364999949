import React from 'react';
import { connect } from 'react-redux';
import RegionIcon from '@material-ui/icons/Public';
import StadiumIcon from '../../../assets/icons/StadiumIcon';
import { getReducedEntityObjectList } from '../../../redux/reducers';
import constants from '../../../utils/constants';
import StadiumEntityListItem from './StadiumEntityListItem';
import EntitySearch from '../components/EntitiySearch';

const StadiumSearch = connect(state => ({
  entities: getReducedEntityObjectList(state.entities.stadiums),
}))(props => (
  <EntitySearch
    {...{
      ...props,
      title: 'Stadiums',
      entityType: constants.entityType.stadium,
      EntityListItem: StadiumEntityListItem,
      searches: [
        {
          search: 'name',
          leftIcon: <StadiumIcon />,
          hintText: 'Stadium',
        },
        {
          search: 'region',
          leftIcon: <RegionIcon />,
          hintText: 'Region',
        },
      ],
    }}
  />
));

export default StadiumSearch;
