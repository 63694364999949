import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PostponedIcon = props => (
  <SvgIcon {...props} viewBox="0,0,38,38">
    <path d="M28.6,37.2L27.5,36.1l3.8,-3.7L27.5,28.6l1.1,-1.1l3.8,3.8l3.7,-3.8l1.1,1.1l-3.8,3.8l3.8,3.7l-1.1,1.1L32.4,33.4l-3.8,3.8ZM25.1,26.1L18.3,19.4l0.3,-0.3l-0.3,0l0,-16l1.6,0l0,15.7l6.2,6.3l-1,1Zm-6,12.1C8.6,38.2,0,29.6,0,19.1C0,8.6,8.6,0,19.1,0C29.6,0,38.2,8.6,38.2,19.1c0,2.4,-0.5,4.7,-1.3,6.9l-1.6,0c0.9,-2.1,1.4,-4.5,1.4,-6.9C36.7,9.4,28.8,1.5,19.1,1.5C9.4,1.5,1.5,9.4,1.5,19.1c0,9.7,7.9,17.6,17.6,17.6c2.4,0,4.8,-0.5,6.9,-1.4l0,1.6c-2.2,0.8,-4.5,1.3,-6.9,1.3ZM28.6,37.2L27.5,36.1l3.8,-3.7L27.5,28.6l1.1,-1.1l3.8,3.8l3.7,-3.8l1.1,1.1l-3.8,3.8l3.8,3.7l-1.1,1.1L32.4,33.4l-3.8,3.8ZM25.1,26.1L18.3,19.4l0.3,-0.3l-0.3,0l0,-16l1.6,0l0,15.7l6.2,6.3l-1,1Zm-6,12.1C8.6,38.2,0,29.6,0,19.1C0,8.6,8.6,0,19.1,0C29.6,0,38.2,8.6,38.2,19.1c0,2.4,-0.5,4.7,-1.3,6.9l-1.6,0c0.9,-2.1,1.4,-4.5,1.4,-6.9C36.7,9.4,28.8,1.5,19.1,1.5C9.4,1.5,1.5,9.4,1.5,19.1c0,9.7,7.9,17.6,17.6,17.6c2.4,0,4.8,-0.5,6.9,-1.4l0,1.6c-2.2,0.8,-4.5,1.3,-6.9,1.3Z" />
  </SvgIcon>
);

export default PostponedIcon;
