import {
  applyMiddleware,
  createStore,
  compose,
} from 'redux';
// TODO: Use this only for development. Add environments - DEV/PROD
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducers from './reducers';

// This will be removed in production after initial verification
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const middleware = applyMiddleware(promise(), thunk);

const store = createStore(reducers, composeEnhancers(middleware));


export default store;
