import React, { Component } from 'react';
import { Paper, TextField, Button, Snackbar, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

class LoginPage extends Component {
  state = {
    username: '',
    password: '',
    loginError: this.props.loginError,
  };

  static getDerivedStateFromProps(nextProps) {
    return { loginError: nextProps.loginError };
  }

  onLogin = () => {
    const onLogin = this.props.onLogin || (() => {});

    onLogin(this.state.username, this.state.password);
  };

  updatePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  updateUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  resolveLoginError = () => {
    this.setState({ loginError: null });
  };

  render() {
    const { theme } = this.props;
    const primaryColor = theme.palette.primary.main;

    return (
      <div>
        <div
          style={{
            position: 'absolute',
            zIndex: -1,
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
        >
          <div style={{ height: '40%', background: primaryColor }} />
        </div>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Paper
            style={{
              padding: 32,
              minHeight: 400,
              display: 'flex',
              flexFlow: 'column nowrap',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h4">Multiball Admin</Typography>
            <br />
            <TextField helperText="Username" onChange={this.updateUsername} />
            <br />
            <TextField helperText="Password" type="password" onChange={this.updatePassword} />
            <br />
            <Button color="primary" tabIndex={0} variant="contained" onClick={this.onLogin}>
              Login
            </Button>
            <br />
          </Paper>
        </div>
        <Snackbar
          open={!!this.state.loginError}
          message="Login failed. Try again."
          onClose={this.resolveLoginError}
        />
      </div>
    );
  }
}

export default withTheme(LoginPage);
