import constants from './constants';

// Few images are saved in jpg format
export const getImageFormat = (entType, key) => {
  if (
    (entType === 'player' && (key === 'photo_thumbnail' || key === 'photo_full')) ||
    (entType === 'team' && key === 'background_full')
  ) {
    return 'jpg';
  }
  return 'png';
};

export const getImageUrl = (entityType, entityId, imgKey) => {
  const { urlKey } = constants.images[entityType][imgKey];
  const imgFormat = getImageFormat(entityType, imgKey);
  return `${constants.images.image_base_url}${urlKey}${entityId}.${imgFormat}`;
};

export const getFallbackImageUrl = () => constants.images.fallback_url;
