const initialState = {
  id: null,
  availableProviders: [],
  selectedProvider: null,
  fetchLoading: false,
  fetchError: false,
  selectLoading: false,
  selectError: false,
};

const matchProviders = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FETCH_MATCH_PROVIDER_PENDING':
      return {
        ...state,
        fetchLoading: true,
        fetchError: false,
      };
    case 'FETCH_MATCH_PROVIDER_FULFILLED':
      return {
        ...state,
        selectedProvider: action.payload,
        fetchLoading: false,
        fetchError: false,
      };
    case 'FETCH_MATCH_PROVIDER_REJECTED':
      return {
        ...state,
        fetchLoading: false,
        fetchError: true,
      };
    case 'FETCH_MATCH_PROVIDER_NAMES_PENDING':
      return {
        ...state,
        fetchLoading: true,
        fetchError: false,
        selectLoading: false,
        selectError: false,
      };
    case 'FETCH_MATCH_PROVIDER_NAMES_FULFILLED':
      return {
        ...state,
        availableProviders: action.payload,
        fetchLoading: false,
        fetchError: false,
      };
    case 'FETCH_MATCH_PROVIDER_NAMES_REJECTED':
      return {
        ...state,
        fetchLoading: false,
        fetchError: true,
      };
    case 'SELECT_MATCH_PROVIDER_PENDING':
      return {
        ...state,
        selectLoading: true,
        selectError: false,
      };
    case 'SELECT_MATCH_PROVIDER_FULFILLED':
      return {
        ...state,
        selectLoading: false,
        selectError: false,
      };
    case 'SELECT_MATCH_PROVIDER_REJECTED':
      return {
        ...state,
        selectLoading: false,
        selectError: true,
      };
    default:
      return state;
  }
};

export default matchProviders;
