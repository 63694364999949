import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import SkipIcon from '@material-ui/icons/SkipNext';
import EditIcon from '@material-ui/icons/Create';
import VerifyIcon from '@material-ui/icons/Check';
import UndoEditIcon from '@material-ui/icons/Replay';

const styles = {
  verifyButtonsContainer: {
    position: 'absolute',
    bottom: 24,
    right: 24,
    display: 'flex',
    zIndex: 1,
  },
  fab: {
    marginLeft: 12,
    color: '#fff',
  },
  verify: {
    backgroundColor: '#03A9F4',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#03A9F4',
    },
  },
  edit: {
    backgroundColor: '#f5a623',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#f5a623',
    },
  },
  skip: {
    backgroundColor: '#fff',
    marginLeft: 12,
  },
};

const VerifyButtons = ({
  classes, taskEditState, onTaskAction, onTaskEdit,
}) => (
  <div className={classes.verifyButtonsContainer}>
    <div>
      {(taskEditState === '' || taskEditState === 'undo') && (
        <div>
          <Tooltip title="Verify">
            <Fab
              aria-label="Verify"
              size="small"
              className={classNames(classes.fab, classes.verify)}
              onClick={onTaskAction('verify')}
            >
              <VerifyIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Edit">
            <Fab
              aria-label="Edit"
              size="small"
              className={classNames(classes.fab, classes.edit)}
              onClick={() => onTaskEdit('edit')}
            >
              <EditIcon />
            </Fab>
          </Tooltip>
        </div>
      )}
      {taskEditState === 'edit' && (
        <Tooltip title="Undo Edit">
          <Fab
            aria-label="Undo Edit"
            size="small"
            className={classNames(classes.fab, classes.verify)}
            onClick={() => onTaskEdit('undo')}
          >
            <UndoEditIcon />
          </Fab>
        </Tooltip>
      )}
    </div>
    <Tooltip title="Skip">
      <Fab
        aria-label="Skip"
        size="small"
        className={(classes.fab, classes.skip)}
        onClick={onTaskAction('skip')}
      >
        <SkipIcon />
      </Fab>
    </Tooltip>
  </div>
);

VerifyButtons.defaultProps = {
  taskEditState: null,
};
VerifyButtons.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  taskEditState: PropTypes.string,
  onTaskAction: PropTypes.func.isRequired,
  onTaskEdit: PropTypes.func.isRequired,
};

export default withStyles(styles)(VerifyButtons);
