const selectedTask = taskType => (state = null, action) => {
  const taskTypeUpperCase = String(taskType).toUpperCase();

  switch (action.type) {
    case `SELECT_${taskTypeUpperCase}_TASK_FULFILLED`:
      return action.payload;
    default:
      return state;
  }
};

export default selectedTask;
