import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import {
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
  selectEntity,
} from '../../../redux/actions';
import constants from '../../../utils/constants';
import RegionCard from './RegionCard';

const RegionCardContainer = ({ match, getEntity, ...rest }) => {
  // Match is a router prop, not the football match :P
  const { regionId } = match.params;
  useEffect(() => {
    getEntity(regionId, constants.entityType.region);
  }, [regionId]);

  return <RegionCard {...rest} />;
};

const mapStateToProps = (state) => {
  const isLoadingOrError = state.regionOverwrites.loading || state.regionOverwrites.writing;
  const arePropertiesEdited =
    Object.values(state.edits.currentEdits).filter(value => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    region: getReducedEntityObject(state.entities.selectedRegion),
    attributeTypes: getReducedEntityTypes(state.entities.selectedRegion),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = dispatch => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (regionId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('region', regionId, currentEdits)),
  fetchEntityAndOverwrites: regionId => dispatch(fetchEntityAndOverwrites('region', regionId)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegionCardContainer));
