import entityRoutes from './entityRoutes';
import taskRoutes from './taskRoutes';
import AccountSettings from '../modules/settings/AccountSettings/AccountSettings';

export const globalRoutes = [
  {
    title: 'Account Settings',
    path: '/account-settings',
    component: AccountSettings,
  },
];

const sections = [
  {
    header: 'Entities',
    routes: entityRoutes,
  },
  {
    header: 'Tasks',
    routes: taskRoutes,
  },
];

export default sections;
