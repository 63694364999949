import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Radio,
  Divider,
  ListSubheader,
  LinearProgress,
  Typography,
  Fab,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ReloadIcon from '@material-ui/icons/Refresh';
import {
  fetchSelectedProviderAndProviderNames,
  selectAndFetchEntityProviderNames,
} from '../../../redux/actions';

class TournamentProviders extends Component {
  state = {
    checkedProvider: null,
  };

  componentDidMount() {
    this.reloadContent();
  }

  getCheckedProvider = () => this.state.checkedProvider || this.props.selectedProvider;

  checkProvider = (checkedProvider) => {
    this.setState({ checkedProvider });
  };

  isSelectedProviderChecked = () => this.getCheckedProvider() === this.props.selectedProvider;

  selectTournamentProvider = () => {
    this.props.selectTournamentProvider(this.props.tournamentId, this.getCheckedProvider());
  };

  reloadContent = () => {
    this.props.fetchTournamentProviders(this.props.tournamentId);
  };

  isLoading = () => this.props.fetchLoading || this.props.selectLoading;

  isError = () => this.props.fetchError || this.props.selectError;

  render() {
    const { availableProviders } = this.props;
    return (
      <div style={{ padding: 24 }}>
        <List>
          <ListSubheader style={{ textTransform: 'capitalize' }}>Available Providers</ListSubheader>
          <Divider />
          {!this.isLoading() &&
            availableProviders.map(provider => (
              <div key={provider}>
                <ListItem>
                  <ListItemText primary={provider} />
                  <ListItemSecondaryAction>
                    <Radio
                      color="primary"
                      value={provider}
                      checked={this.getCheckedProvider() === provider}
                      onChange={() => this.checkProvider(provider)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </div>
            ))}
          <Divider />
        </List>
        <div
          style={{
            marginTop: 24,
            flexBasis: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <LinearProgress
            hidden={!this.isLoading()}
            style={{ flex: 1, marginLeft: 24, marginRight: 24 }}
          />
          {this.props.fetchError && (
            <Typography>
              Error when trying to fetch providers. Please reload and try again.
            </Typography>
          )}
          {this.props.selectError && (
            <Typography>
              Error when trying to select provider. Please reload and try again.
            </Typography>
          )}
          {this.isError() && (
            <Fab
              style={{ marginLeft: 24, marginRight: 24, minWidth: 56 }}
              color="primary"
              aria-label="Reload"
              onClick={this.reloadContent}
            >
              <ReloadIcon />
            </Fab>
          )}
          <Fab
            style={{ minWidth: 56 }}
            color="primary"
            aria-label="Save"
            disabled={this.isError() || this.isSelectedProviderChecked()}
            onClick={this.selectTournamentProvider}
          >
            <SaveIcon />
          </Fab>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    selectedProvider: state.tournamentProviders.selected_provider,
    availableProviders: state.tournamentProviders.available_providers,
    fetchLoading: state.tournamentProviders.fetchLoading,
    fetchError: state.tournamentProviders.fetchError,
    selectLoading: state.tournamentProviders.selectLoading,
    selectError: state.tournamentProviders.selectError,
  }),
  dispatch => ({
    fetchTournamentProviders: tournamentId =>
      dispatch(fetchSelectedProviderAndProviderNames('tournament', tournamentId)),
    selectTournamentProvider: (tournamentId, provider) =>
      dispatch(selectAndFetchEntityProviderNames('tournament', tournamentId, provider)),
  }),
)(TournamentProviders);
