import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const SimilarityScore = ({ score }) => (
  <ListItemSecondaryAction>
    <ListItemText
      primary={`${Math.round(score * 100)}%`}
      primaryTypographyProps={{
        style: {
          fontWeight: 400,
        },
      }}
    />
  </ListItemSecondaryAction>
);

SimilarityScore.propTypes = {
  score: PropTypes.number.isRequired,
};
export default SimilarityScore;
