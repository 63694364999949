import { useState, useEffect } from 'react';
import Backend from '../../../services/backend';

const useSuggestions = (taskId, entityType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const removeOldSuggestionsFromLS = () => {
    Object.keys(localStorage).forEach((element) => {
      if (element.match(/task_*/)) {
        localStorage.removeItem(element);
      }
    });
  };

  const isSameTaskAsCached = (currentTskId) => {
    const oldTask = Object.keys(localStorage).find(el => el.match(/task_*/));
    return oldTask === `task_${currentTskId}`;
  };

  const cacheSuggestions = (suggestionsResponse) => {
    if (suggestionsResponse && suggestionsResponse.length > 0) {
      localStorage.setItem(`task_${taskId}`, JSON.stringify(suggestionsResponse));
    }
  };

  const thresholdFromEntityType = (entityType) => {
    switch (entityType) {
      case 'manager':
      case 'player':
        return 0.7;
      case 'team':
        return 0.65;
      case 'season':
        return 0.0;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      setIsLoading(true);
      try {
        const threshold = thresholdFromEntityType(entityType);
        const suggestionsResponse = await Backend.fetchTargetSuggestions(taskId, threshold);
        setSuggestions(suggestionsResponse);
        cacheSuggestions(suggestionsResponse);
        setIsError(false);
      } catch (er) {
        setIsError(true);
        setSuggestions([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (!taskId) return;

    if (isSameTaskAsCached(taskId)) {
      let suggs = [];
      if (localStorage.getItem(`task_${taskId}`)) {
        suggs = JSON.parse(localStorage.getItem(`task_${taskId}`));
      }
      setSuggestions(suggs);
    } else {
      // Remove old cache from LS
      removeOldSuggestionsFromLS();
      fetchSuggestions();
    }
  }, [taskId]);

  return [isLoading, isError, suggestions];
};

export default useSuggestions;
