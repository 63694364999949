import React from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditStadium from './EditStadium';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardTab from '../components/EntityCardTab';
import EntityCardFooter from '../components/EntityCardFooter';
import ProviderPopover from '../../../components/ProviderPopover';
import Mapping from '../components/Mapping';

const StadiumCard = ({
  stadium,
  attributeTypes,
  currentEdits,
  buttonEnable,
  providers,
  readOnly,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const overwriteEntity = () => {
    overwriteAndReloadEntity(stadium.id, currentEdits);
  };

  const reloadContent = () => {
    fetchEntityAndOverwrites(stadium.id);
  };

  return (
    stadium && (
      <EntityCard>
        <EntityCardHeader
          primary={stadium.name}
          imgUrl=""
        >
          {readOnly && <ProviderPopover entityType="stadium" providers={providers} />}
        </EntityCardHeader>
        <EntityCardContent
          tabs={
            readOnly ? ['information'] : ['information', 'mappings']
          }
        >
          <EntityCardTab tab="information">
            <EditStadium
              entity={stadium}
              entityType="stadium"
              key={stadium.id}
              editableProperties={readOnly ? [] : Object.keys(stadium).filter(key => key !== 'id')}
              attributeTypes={attributeTypes}
              reloadContent={reloadContent}
            />

            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          <EntityCardTab tab="mappings">
            <Mapping entityType="stadium" entityId={stadium.id} />
          </EntityCardTab>
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default StadiumCard;
