import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { getReducedEntityObject } from '../../../redux/reducers';
import { getSuggestionPrimaryText, getSuggestionSecondaryText } from '../helper';
import SimilarityScore from './SimilarityScore';
import ResultListItemAvatar from './ResultListItemAvatar';

const TargetMappedEntity = ({ target, entityType, onItemSelect }) => {
  const entityDetails = getReducedEntityObject(target.targetEntity);
  return (
    <div>
      <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
        Mapped {entityType}
      </Typography>
      <List>
        <ListItem
          key={target.id}
          onClick={() => onItemSelect(target)}
          style={{ cursor: 'pointer', position: 'relative' }}
          button
        >
          <ResultListItemAvatar entityType={entityType} entityId={target.id} />
          <ListItemText
            primary={getSuggestionPrimaryText(entityType, entityDetails)}
            secondary={getSuggestionSecondaryText(entityType, entityDetails)}
          />
          {target.similarity_score && <SimilarityScore score={target.similarity_score} />}
        </ListItem>
      </List>
    </div>
  );
};
TargetMappedEntity.propTypes = {
  target: PropTypes.instanceOf(Object).isRequired,
  entityType: PropTypes.string.isRequired,
  onItemSelect: PropTypes.func.isRequired,
};
export default TargetMappedEntity;
