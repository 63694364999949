import React, { Component } from 'react';

import {
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  withStyles,
  Typography,
  Collapse,
  TextField,
  Switch,
} from '@material-ui/core';

import { connect } from 'react-redux';
import classNames from 'classnames';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { setMatchEventDeleted } from '../../../../redux/actions';

import penaltyAwardedIcon from '../../../../assets/icons/match_events/awarded-penalty.svg';
import missedGoalCrossbarIcon from '../../../../assets/icons/match_events/bar-hit.svg';
import goalIcon from '../../../../assets/icons/match_events/goal.svg';
import injuryIcon from '../../../../assets/icons/match_events/injury.svg';
import ownGoalIcon from '../../../../assets/icons/match_events/own-goal.svg';
import missedPenaltyIcon from '../../../../assets/icons/match_events/penalty-miss.svg';
import penaltyTakerIcon from '../../../../assets/icons/match_events/penalty-taker.svg';
import missedGoalPostIcon from '../../../../assets/icons/match_events/post-hit.svg';
import redCardIcon from '../../../../assets/icons/match_events/red-card.svg';
import substitutionIcon from '../../../../assets/icons/match_events/substitution.svg';
import yellowCardIcon from '../../../../assets/icons/match_events/yellow-card.svg';

import WhistleIcon from '../../../../assets/icons/WhistleIcon';
import EventListItem from './EventListItem';
import RecursiveObjectRenderer from '../../components/RecursiveObjectRenderer';

const nameFromPlayer = (player) => {
  if (player) {
    return player.nickname || `${player.first_name} ${player.last_name}`;
  }
  return '';
};

const iconFromGoalDetail = (goalDetail) => {
  switch (goalDetail) {
    case 'own':
      return ownGoalIcon;
    case 'penalty':
      return penaltyTakerIcon;
    default:
      return goalIcon;
  }
};

const iconFromPenaltyShootoutSuccess = (scored) => {
  if (scored) {
    return penaltyTakerIcon;
  }
  return missedPenaltyIcon;
};

const formatScore = ([homeScore, awayScore]) => `${homeScore} - ${awayScore}`;

const eventInfo = (event) => {
  const info = {
    type: event.type,
    side: event.team_side,
    minute: `${event.match_minute}'`,
    primary: nameFromPlayer(event.player),
  };

  switch (event.type) {
    case 'stoppage_time':
      return {
        ...info,
        side: 'both',
        primary: `+${event.amount}`,
        primaryBold: true,
      };
    case 'match_start':
      return {
        minute: <WhistleIcon style={{ fontSize: 18 }} />,
      };
    case 'match_end':
      return {
        minute: <WhistleIcon style={{ fontSize: 18 }} />,
      };
    case 'goal':
      return {
        ...info,
        icon: iconFromGoalDetail(event.detail),
        secondary: event.assisting_player
          ? `Assist: ${nameFromPlayer(event.assisting_player)}`
          : '',
        primary:
          event.team_side === 'home'
            ? `${nameFromPlayer(event.player)} ${formatScore(event.score)}`
            : `${formatScore(event.score)} ${nameFromPlayer(event.player)}`,
      };
    case 'missed_goal':
      switch (event.detail) {
        case 'post':
          return {
            ...info,
            icon: missedGoalPostIcon,
            secondary: 'Shot off the post',
          };
        case 'crossbar':
          return {
            ...info,
            icon: missedGoalCrossbarIcon,
            secondary: 'Shot off the bar',
          };
        default:
          break;
      }
      break;
    case 'card':
      switch (event.detail) {
        case 'yellow':
          return {
            ...info,
            icon: yellowCardIcon,
            secondary: 'Yellow card',
          };
        case 'red':
          return {
            ...info,
            icon: redCardIcon,
            secondary: 'Red card',
          };
        default:
          break;
      }
      break;
    case 'substitution':
      return {
        ...info,
        icon: substitutionIcon,
        primary: nameFromPlayer(event.player_in),
        secondary: nameFromPlayer(event.player_out),
      };
    case 'added_time':
      return {
        ...info,
        minute: `+${event.added_minutes}`,
      };
    case 'injury':
      return {
        ...info,
        icon: injuryIcon,
        secondary: 'Injury break',
      };
    case 'missed_penalty':
      return {
        ...info,
        icon: missedPenaltyIcon,
        primary: nameFromPlayer(event.player),
        secondary: 'Missed penalty',
      };
    case 'awarded_penalty':
      return {
        ...info,
        icon: penaltyAwardedIcon,
        primary: 'Awarded penalty',
      };
    case 'penalty_shootout_shot':
      return {
        ...info,
        minute: '-',
        icon: iconFromPenaltyShootoutSuccess(event.scored),
        primary: formatScore(event.score),
        primaryBold: true,
      };
    default:
      break;
  }
  return info;
};

const rowStyles = theme => ({
  lineContainer: {
    position: 'absolute',
    left: 0,
    height: '100%',
    width: '100%',
    paddingLeft: 'inherit',
    paddingRight: 'inherit',
  },
  line: {
    width: '50%',
    height: '100%',
    borderRight: '1px solid lightgray',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  firstLine: {
    top: '50%',
    height: '50%',
  },
  deletedEvent: {
    backgroundColor: '#ffcccc',
  },
  lastLine: {
    height: '50%',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  eventContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  event: {
    flex: '1 0 50%',
    minWidth: '50%',
    maxWidth: '50%',
  },
  minuteAvatarContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  minuteAvatar: {
    border: '4px solid #fff',
    width: 48,
    height: 48,
    backgroundColor: '#7d7d7d',
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  highlightedAvatar: {
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  extraMinuteAvatar: {
    backgroundColor: 'orange',
  },
});

const renderListItem = (renderSide, { side: eventSide, ...rest }) => {
  if (renderSide === eventSide || eventSide === 'both') {
    const opts = Object.assign(rest, { side: renderSide });
    return <EventListItem {...opts} />;
  }
  return null;
};

const translateMatchEventType = (type) => {
  switch (type) {
    case 'penalty_shootout_shot':
      return 'penalty_shootout';
    default:
      return type;
  }
};

const renderVisibilitySwitch = (event, dispatch, onEventEdited) => {
  const isStartOrEnd = ['match_start', 'match_end'].indexOf(event.type) !== -1;
  if (!isStartOrEnd) {
    return (
      <ListItem style={{ flex: '0 1 50%' }}>
        <Typography style={{ color: 'black', paddingLeft: 56 }} variant="caption">
          Visible:
        </Typography>
        <Switch
          color="primary"
          checked={!event.deleted}
          onClick={() =>
            // eslint-disable-next-line max-len
            dispatch(setMatchEventDeleted(translateMatchEventType(event.type), event.id, !event.deleted)).then(onEventEdited)
          }
        />
      </ListItem>
    );
  }

  return null;
};

class MatchEventRow extends Component {
  state = { expanded: false };

  toggleExpand = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const {
      event, first, last, classes, dispatch, onEventEdited,
    } = this.props;

    const info = eventInfo(event);
    const { minute, type } = info;
    const { expanded } = this.state;
    return (
      <div>
        <ListItem button onClick={this.toggleExpand} style={{ minHeight: 64 }}>
          <div
            className={classNames(classes.lineContainer, {
              [classes.firstLine]: first,
              [classes.deletedEvent]: event.deleted,
            })}
          >
            <div className={classNames(classes.line, { [classes.lastLine]: last || expanded })} />
          </div>
          <div className={classes.eventContainer}>
            <div className={classes.event}>{renderListItem('home', info)}</div>
            <div className={classes.minuteAvatarContainer}>
              <Avatar
                className={classNames(classes.minuteAvatar, {
                  [classes.highlightedAvatar]: expanded,
                  [classes.extraMinuteAvatar]: !expanded && type === 'added_time',
                })}
              >
                <Typography color="inherit">{minute}</Typography>
              </Avatar>
            </div>
            <div className={classes.event}>{renderListItem('away', info)}</div>
          </div>
          <ListItemSecondaryAction>
            <IconButton onClick={this.toggleExpand}>
              {this.state.expanded ? <ArrowDropDown /> : <ArrowDropUp />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={expanded}>
          {renderVisibilitySwitch(event, dispatch, onEventEdited)}

          <RecursiveObjectRenderer
            style={{ display: 'flex', flex: '1 1 100%', flexFlow: 'row wrap' }}
            object={event}
            RenderItem={({ value, keyvalue }) => (
              <ListItem style={{ flex: '0 1 50%' }}>
                <TextField
                  style={{ paddingLeft: 56 }}
                  value={String(value)}
                  helperText={keyvalue}
                />
              </ListItem>
            )}
          />
        </Collapse>
      </div>
    );
  }
}

export default connect()(withStyles(rowStyles, { withTheme: true })(MatchEventRow));
