import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const ConfirmationDialog = ({
  primaryText, secondaryText, open, onClose,
}) => (
  <Dialog
    open={open}
    onClose={() => onClose('cancel')}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{primaryText}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {secondaryText}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose('cancel')} color="primary">
        Cancel
      </Button>
      <Button onClick={() => onClose('ok')} color="primary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
