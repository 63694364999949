import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RedCardIcon = props => (
  <SvgIcon
    {...props}
    style={{
      height: 15,
      width: 12,
      color: '#ee0000',
      flex: '0 0 auto',
      ...props.style,
    }}
  >
    <path d="M17 3 H7 c -1.1 0 -2 .9 -2 2 v 14 c 0 1.1 .9 2 2 2 h10 c 1.1 0 2 -.9 2 -2 V5 c0-1.1-.9-2-2-2z" />
  </SvgIcon>
);

export default RedCardIcon;
