/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Croppie from '../../../../components/lib/Croppie/Croppie';
import ExistingImage from './ExistingImage';

const styles = {
  uploadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: 10,
  },
  input: {
    display: 'none',
  },
  errorMessage: {
    textAlign: 'center',
    color: 'darkred',
  },
};

const UploadInstructions = ({ width, height }) => (
  <div style={{ textAlign: 'center' }}>
    <Typography paragraph>
      Accepted formats: jpg, png, jpeg
      <br />
      min size: {width}*{height}
    </Typography>
  </div>
);

const ImageUpload = ({
  classes, expectedImgSize, enableCroppie, onImageSave,
}) => {
  const [previewImgUrl, setPreviewImgUrl] = useState(null);
  const [img, setImg] = useState(null);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showImageSizeError, setShowImageSizeError] = useState(false);
  const croppieRef = useRef(null);
  // Generate unique id for fileinput
  const [inputId] = useState(() =>
    Math.random()
      .toString(16)
      .slice(-13));

  const handleFileChange = (imgFile) => {
    const url = window.URL.createObjectURL(imgFile);
    // eslint-disable-next-line no-shadow
    const tempImg = new Image();
    tempImg.onload = () => {
      const { width, height } = expectedImgSize;
      if (tempImg.naturalHeight < height || tempImg.naturalWidth < width) {
        setPreviewImgUrl(null);
        setImg(null);
        setShowImageSizeError(true);
        setShowUploadButton(true);
        setShowCancelButton(false);
      } else {
        setPreviewImgUrl(url);
        setImg(imgFile);
        setShowImageSizeError(false);
        setShowUploadButton(false);
        setShowCancelButton(true);
      }
      window.URL.revokeObjectURL(tempImg.src);
    };
    tempImg.src = url;
  };

  const getResult = (options) => {
    if (enableCroppie) {
      return croppieRef.current.resultImage(options);
    }
    return {
      points: [0, 0],
      zoom: 1,
    };
  };

  const imageSaveHandler = () => {
    const res = getResult();
    const [x, y] = res.points;
    const imagePayload = {
      image_data: img,
      scale: res.zoom || 1,
      left: x ? Number(x) : 0,
      top: y ? Number(y) : 0,
    };
    setPreviewImgUrl(null);
    setShowCancelButton(false);
    setShowUploadButton(true);
    onImageSave(imagePayload);
  };

  const cancelHandler = () => {
    setImg(null);
    setPreviewImgUrl(null);
    setShowCancelButton(false);
    setShowUploadButton(true);
  };

  return (
    <div>
      {previewImgUrl && (
        <div className={classes.saveContainer}>
          {enableCroppie ? (
            <Croppie
              url={previewImgUrl}
              ref={croppieRef}
              viewport={{ ...expectedImgSize, type: 'circle' }}
              boundary={expectedImgSize}
            />
          ) : (
            <ExistingImage src={previewImgUrl} />
          )}
        </div>
      )}
      <div className={classes.uploadContainer}>
        {showUploadButton && (
          <form>
            <input
              accept=".png, .jpg, .jpeg"
              className={classes.input}
              id={inputId}
              type="file"
              onChange={evt => handleFileChange(evt.target.files[0])}
            />
            <label htmlFor={inputId}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
              >
                SELECT FILE
              </Button>
            </label>
          </form>
        )}
        {previewImgUrl && (
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={imageSaveHandler}
          >
            Save
          </Button>
        )}
        {showCancelButton && (
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={cancelHandler}
          >
            CANCEL
          </Button>
        )}
      </div>
      {showImageSizeError && (
        <p className={classes.errorMessage}>
          Uploaded image size is less than the min accepted size, please try again with a larger
          image
        </p>
      )}
      {/* upload Instructions */}
      <UploadInstructions {...expectedImgSize} />
    </div>
  );
};
export default withStyles(styles)(ImageUpload);
