import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import {
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
  selectEntity,
} from '../../../redux/actions';
import constants from '../../../utils/constants';
import TeamCard from './TeamCard';

const TeamCardContainer = ({ match, getEntity, ...rest }) => {
  // Match is a router prop, not the football match :P
  const { teamId } = match.params;
  useEffect(() => {
    getEntity(teamId, constants.entityType.team);
  }, [teamId]);

  return <TeamCard {...rest} />;
};

const mapStateToProps = (state) => {
  const isLoadingOrError = state.teamOverwrites.loading || state.teamOverwrites.writing;
  const arePropertiesEdited =
    Object.values(state.edits.currentEdits).filter(value => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    team: getReducedEntityObject(state.entities.selectedTeam),
    attributeTypes: getReducedEntityTypes(state.entities.selectedTeam),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = dispatch => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (teamId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('team', teamId, currentEdits)),
  fetchEntityAndOverwrites: teamId => dispatch(fetchEntityAndOverwrites('team', teamId)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamCardContainer));
