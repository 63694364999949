import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const styles = () => ({
  card: { marginBottom: 24, marginRight: 24, padding: 24 },
  cardHeader: {
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: 200,
    height: 200,
    margin: -24,
    marginBottom: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    color: '#fff',
    justifyContent: 'flex-end',
  },
  cardHeaderContent: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' },
  cardTitle: { textTransform: 'capitalize' },
  pending: { backgroundColor: '#2196f3' },
  edit: { backgroundColor: '#639456' },
  unverified_map: { backgroundColor: '#2196f3' },
  unverified_accept: { backgroundColor: '#7ED321' },
  unverified_reject: { backgroundColor: '#EC407A' },
  target: { backgroundColor: '#646464' },
});

const CardHeader = ({ classes, children, background }) => (
  <Paper className={classNames(classes.card, classes.cardHeader, classes[background])}>
    {children}
  </Paper>
);

CardHeader.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  background: PropTypes.string.isRequired,
};
export default withStyles(styles)(CardHeader);
