import React, { useState, useEffect } from 'react';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core';

const styles = {
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  img: {
    maxHeight: 600,
    maxWidth: 600,
    border: 'solid 1px #eae7e7',
  },
  imgTitle: {
    fontSize: 10,
  },
  downloadIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  downloadIcon: {
    position: 'absolute',
    right: 0,
    top: 2,
    left: '110%',
  },
};

const ExistingImage = ({
  src, title, enableDownload, classes, ...rest
}) => {
  const [isError, setIsError] = useState(true);

  useEffect(() => {
    setIsError(false);
  }, [src]);

  const errorHandler = (e) => {
    e.target.error = null;
    setIsError(true);
  };

  return (
    <div className={classes.imgContainer}>
      <img src={src} alt="" onError={errorHandler} style={styles.img} {...rest} />
      <div className={classes.downloadIconContainer}>
        <p className={classes.imgTitle}>{title}</p>
        {enableDownload && !isError && (
          <a href={src} className={classes.downloadIcon} download>
            <CloudDownload color="primary" />
          </a>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ExistingImage);
