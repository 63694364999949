import axios from 'axios';
import msgpack from 'msgpack-lite';

export const API = {
  admin: { url: 'https://api.admin.forzafootball.net/api/', msgpack: false },
  private_striker: { url: 'https://api.forzafootball.net/private/', msgpack: true },
};

export const axiosInstance = axios.create({
  headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
  withCredentials: true,
});

const strikerHeaders = new Headers({
  "Accept": "application/msgpack",
  "Accept-Language": "en",
  "X-Client-ID": "forza-football",
  "X-Forza-Service": "multiball-admin",
  "X-Client-Platform": "client"
});

const unpackMsgpackTimestamp = uint8bytes => new DataView(uint8bytes.buffer).getInt32(0, false);

const setupMsgpackCodec = () => {
  const codec = msgpack.createCodec();
  codec.addExtUnpacker(0xff, unpackMsgpackTimestamp);
  return codec;
};

const msgpackCodec = setupMsgpackCodec();

export default class Base {
  static get(config, endpoint) {
    if (config.msgpack) {
      const url = config.url + endpoint;
      return fetch(url, {
        headers: strikerHeaders
      })
        .then((response) => {
          if (!response.ok || response.status === 204) {
            return Promise.reject({ status: response.status });
          }
          return response;
        })
        .then(response => response.arrayBuffer())
        .then(buffer => msgpack.decode(new Uint8Array(buffer), { codec: msgpackCodec }));
    }

    // JSON when not msgpack
    return axiosInstance.get(config.url + endpoint).then(response => response.data);
  }

  static async put(endpoint, payload, config) {
    const response = await axiosInstance
      .put(API.admin.url + endpoint, payload, config);
    return response.data;
  }

  static async delete(endpoint, payload) {
    const response = await axiosInstance.delete(API.admin.url + endpoint, payload);
    return response.data;
  }

  static async post(endpoint, payload) {
    const response = await axiosInstance.post(API.admin.url + endpoint, payload);
    return response.data;
  }
}
