import {
  OPEN_PRIMARY_DRAWER,
  CLOSE_PRIMARY_DRAWER,
  TOGGLE_PRIMARY_DRAWER,
  OPEN_SECONDARY_DRAWER,
  CLOSE_SECONDARY_DRAWER,
  TOGGLE_SECONDARY_DRAWER,
} from '../actions/actionTypes';

const initialState = {
  primaryDrawerOpen: true,
  secondaryDrawerOpen: false,
};

const drawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PRIMARY_DRAWER:
      return {
        ...state,
        primaryDrawerOpen: true,
      };
    case CLOSE_PRIMARY_DRAWER:
      return {
        ...state,
        primaryDrawerOpen: false,
      };
    case TOGGLE_PRIMARY_DRAWER:
      return {
        ...state,
        primaryDrawerOpen: !state.primaryDrawerOpen,
      };
    case OPEN_SECONDARY_DRAWER:
      return {
        ...state,
        secondaryDrawerOpen: true,
      };

    case CLOSE_SECONDARY_DRAWER:
      return {
        ...state,
        secondaryDrawerOpen: false,
      };
    case TOGGLE_SECONDARY_DRAWER:
      return {
        ...state,
        secondaryDrawerOpen: !state.secondaryDrawerOpen,
      };

    default:
      return state;
  }
};

export default drawer;
