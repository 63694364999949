import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import { overwriteAndReloadEntity, fetchEntityAndOverwrites, selectEntity } from '../../../redux/actions';
import { closePrimaryDrawer } from '../../../redux/actions/drawer';
import constants from '../../../utils/constants';
import ManagerCard from './ManagerCard';

const ManagerCardContainer = ({ match, getEntity, manager, ...rest }) => {
  const { managerId } = match.params;
  useEffect(() => {
    getEntity(managerId, constants.entityType.manager);
  }, [managerId]);

  // Don't show the drawer for manager cards
  useEffect(() => {
    closePrimaryDrawer();
  });

  if (manager) {
    return <ManagerCard manager={manager} {...rest} />;
  }
  return null;
};

const mapStateToProps = (state) => {
  const isLoadingOrError = state.managerOverwrites.loading || state.managerOverwrites.writing;
  const arePropertiesEdited = Object.values(state.edits.currentEdits).filter((value) => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    manager: getReducedEntityObject(state.entities.selectedManager),
    attributeTypes: getReducedEntityTypes(state.entities.selectedManager),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (managerId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('manager', managerId, currentEdits)),
  fetchEntityAndOverwrites: (managerId) => dispatch(fetchEntityAndOverwrites('manager', managerId)),
  closePrimaryDrawer: () => dispatch(closePrimaryDrawer()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManagerCardContainer)
);
