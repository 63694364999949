const initialState = {
  selectedTournament: null,
  selectedTeam: null,
  selectedPlayer: null,
  selectedManager: null,
  selectedMatch: null,
  selectedRegion: null,
  selectedSeason: null,
  tournaments: [],
  teams: [],
  managers: [],
  players: [],
  matches: [],
  matchFilterSelections: {
    tournaments: [],
    teams: [],
  },
  pinnedMatches: {},
  regions: [],
  seasons: [],
  stadiums: [],
  loading: false,
};

const entities = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'SEARCH_MATCH_PENDING':
    case 'SEARCH_TOURNAMENT_PENDING':
    case 'SEARCH_TEAM_PENDING':
    case 'SEARCH_MANAGER_PENDING':
    case 'SEARCH_PLAYER_PENDING':
    case 'SEARCH_REGION_PENDING':
    case 'SEARCH_SEASON_PENDING':
    case 'SEARCH_STADIUM_PENDING':
      return {
        ...state,
        loading: true,
        tournaments: [],
        teams: [],
        managers: [],
        players: [],
        matches: [],
        regions: [],
        seasons: [],
        stadiums: [],
      };
    case 'SEARCH_MATCH_REJECTED':
    case 'SEARCH_TOURNAMENT_REJECTED':
    case 'SEARCH_TEAM_REJECTED':
    case 'SEARCH_MANAGER_REJECTED':
    case 'SEARCH_PLAYER_REJECTED':
    case 'SEARCH_REGION_REJECTED':
    case 'SEARCH_SEASON_REJECTED':
    case 'SEARCH_STADIUM_REJECTED':
      return {
        ...state,
        loading: false,
      };
    case 'SEARCH_TOURNAMENT_FULFILLED':
      return {
        ...state,
        tournaments: action.payload,
        loading: false,
      };
    case 'SEARCH_TEAM_FULFILLED':
      return {
        ...state,
        teams: action.payload,
        loading: false,
      };
    case 'SEARCH_PLAYER_FULFILLED':
      return {
        ...state,
        players: action.payload,
        loading: false,
      };
    case 'SEARCH_MATCH_FULFILLED':
      return {
        ...state,
        matches: action.payload.matches,
        matchFilterSelections: action.payload.filterSelections,
        // TODO: Either fix pinned matches again, or remove
        pinnedMatches: [],
        loading: false,
      };
    case 'SEARCH_REGION_FULFILLED':
      return {
        ...state,
        regions: action.payload,
        loading: false,
      };
    case 'SEARCH_SEASON_FULFILLED':
      return {
        ...state,
        seasons: action.payload,
        loading: false,
      };
    case 'SEARCH_STADIUM_FULFILLED':
      return {
        ...state,
        stadiums: action.payload,
        loading: false,
      };
    case 'SEARCH_MANAGER_FULFILLED':
      return {
        ...state,
        managers: action.payload,
        loading: false,
      };
    case 'TOGGLE_PIN_MATCH_FULFILLED':
      return {
        ...state,
        matches: state.matches.map((match) => {
          if (match.id === action.payload.id) {
            return {
              ...match,
              pinned: action.payload.pinned,
            };
          }
          return match;
        }),
      };
    case 'SELECT_TOURNAMENT_PENDING':
    case 'SELECT_TEAM_PENDING':
    case 'SELECT_MANAGER_PENDING':
    case 'SELECT_PLAYER_PENDING':
    case 'SELECT_MATCH_PENDING':
    case 'SELECT_REGION_PENDING':
    case 'SELECT_SEASON_PENDING':
    case 'SELECT_STADIUM_PENDING':
      return {
        ...state,
        selectedTournament: null,
        selectedTeam: null,
        selectedPlayer: null,
        selectedMatch: null,
        selectedRegion: null,
        selectedSeason: null,
        selectedStadium: null,
        selectedManager: null,
      };
    case 'SELECT_TOURNAMENT_FULFILLED':
      return {
        ...state,
        selectedTournament: action.payload,
      };
    case 'SELECT_TEAM_FULFILLED':
      return {
        ...state,
        selectedTeam: action.payload,
      };
    case 'SELECT_MANAGER_FULFILLED':
      return {
        ...state,
        selectedManager: action.payload,
      };
    case 'SELECT_PLAYER_FULFILLED':
      return {
        ...state,
        selectedPlayer: action.payload,
      };
    case 'SELECT_MATCH_FULFILLED':
      return {
        ...state,
        selectedMatch: action.payload,
      };
    case 'SELECT_REGION_FULFILLED':
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case 'SELECT_SEASON_FULFILLED':
      return {
        ...state,
        selectedSeason: action.payload,
      };
    case 'SELECT_STADIUM_FULFILLED':
      return {
        ...state,
        selectedStadium: action.payload,
      };
    default:
      return state;
  }
};

export default entities;
