import React from 'react';
import { connect } from 'react-redux';
import DateIcon from '@material-ui/icons/DateRange';
import TeamIcon from '@material-ui/icons/Group';
import TournamentIcon from '../../../assets/icons/TournamentIcon';
import constants from '../../../utils/constants';
import EntitySearch from '../components/EntitiySearch';

const MatchSearch = connect(state => ({
  entities: state.entities.matches,
  matchFilterSelections: state.entities.matchFilterSelections,
}))(props => (
  <EntitySearch
    {...{
      ...props,
      title: 'Matches',
      entityType: constants.entityType.match,
      searches: [
        {
          search: 'date',
          leftIcon: <DateIcon />,
          hintText: 'Date',
          type: 'date',
          value: new Date().toISOString().slice(0, 10),
        },
        {
          search: 'tournament',
          leftIcon: <TournamentIcon />,
          hintText: 'Tournament',
          type: 'select',
          selections: props.matchFilterSelections.tournaments,
        },
        {
          search: 'team',
          leftIcon: <TeamIcon />,
          hintText: 'Team',
          type: 'select',
          selections: props.matchFilterSelections.teams,
        },

        // TODO: Re-enable when pinning is reimplemented
        // {
        //   search: 'pinned',
        //   type: 'toggle',
        //   hintText: 'Show pinned matches',
        //   value: false,
        // },
      ],
    }}
  />
));

export default MatchSearch;
