import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ConfirmationDialog from './ConfirmationDialog';
import providerLinks from '../../../../utils/providerLinks';
import Backend from '../../../../services/backend';
import TournamentProviders from '../../tournaments/TournamentProviders';
import MatchProviders from '../../matches/MatchProviders';

class Mapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      selectedMapping: null,
      isDialogOpen: false,
      confirmationPrimaryText: '',
      confirmationSecondaryText: '',
    };
  }

  componentDidMount() {
    this.getProviders();
  }

  componentDidUpdate(prevProps) {
    if (this.props.entityId !== prevProps.entityId) {
      this.getProviders();
    }
  }

  getProviders = async () => {
    try {
      this.setState({ isLoading: true });
      const providerList = await Backend.fetchEntityProviders(
        this.props.entityType,
        this.props.entityId,
      );
      this.setState({ providers: providerList, isLoading: false });
      return providerList;
    } catch (e) {
      this.setState({ providers: [], isLoading: false });
      return [];
    }
  };

  showProviderLink = providerId =>
    providerId && (providerId.indexOf('sr:') !== -1 || providerId.indexOf('sr_manual') !== -1);

  deleteMapping = async () => {
    try {
      this.setState({ isLoading: true });
      await Backend.deleteMapping(
        this.props.entityType,
        this.props.entityId,
        this.state.selectedMapping.provider_name,
        this.state.selectedMapping.provider_entity_id,
      );
      this.setState({ isLoading: false });

      // Refresh the list of results
      this.getProviders();
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  openDialogHandler = (selectedItem) => {
    const confirmPrimaryText = 'Are you sure you want to delete mapping?';
    const confirmSecondText = 'Please note that this results in deletion of the mapping';

    this.setState({
      isDialogOpen: true,
      selectedMapping: selectedItem,
      confirmationPrimaryText: confirmPrimaryText,
      confirmationSecondaryText: confirmSecondText,
    });
  };

  dialogCloseHandler = (action) => {
    //  Delete Mapping
    if (action === 'ok') {
      this.deleteMapping();
    }
    this.setState({
      isDialogOpen: false,
      confirmationPrimaryText: '',
      confirmationSecondaryText: '',
    });
  };

  render() {
    const { providers, isLoading } = this.state;

    return (
      <div>
        {this.props.entityType === 'tournament' && (
          <TournamentProviders key={this.props.entityId} tournamentId={this.props.entityId} />
        )}
        {this.props.entityType === 'match' && (
          <MatchProviders key={this.props.entityId} matchId={this.props.entityId} />
        )}
        {/* Active Mappings */}
        <div style={{ padding: 24 }}>
          <List>
            <ListSubheader style={{ textTransform: 'capitalize' }}>Active Mappings</ListSubheader>
            {/* array of providers is nothing but list of mappings */}
            {!this.isLoading &&
              providers.map(mapping => (
                <div key={mapping.provider_entity_id}>
                  <ListItem>
                    <ListItemText
                      primary={mapping.provider_name}
                      secondary={mapping.provider_entity_id}
                    />
                    <ListItemSecondaryAction style={{ display: 'flex', alignItems: 'center' }}>
                      {/* Open source in another tab */}
                      {this.showProviderLink(mapping.provider_entity_id) && (
                        <Tooltip title="View Source">
                          <IconButton
                            href={providerLinks.getLink(
                              mapping.provider_name,
                              this.props.entityType,
                              mapping.provider_entity_id,
                            )}
                            target="_blank"
                          >
                            <EyeIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {/* Delete mapping */}
                      <Tooltip title="Delete Mapping">
                        <div>
                          <IconButton
                            disabled={providers && providers.length === 1}
                            onClick={() => this.openDialogHandler(mapping)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            <Divider />
          </List>
          <div
            style={{
              marginTop: 24,
              flexBasis: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <LinearProgress
              hidden={!isLoading}
              style={{ flex: 1, marginLeft: 24, marginRight: 24 }}
            />
          </div>
        </div>
        <Divider />
        {/* TODO: Enable this when the backend is ready. Actions- Map/Delete */}
        {/* <div style={{ padding: 24 }}>
          <List>
            <ListSubheader style={{ textTransform: 'capitalize' }}>
              Actions
            </ListSubheader>
            <ListItem
              button
              onClick={() => this.openDialogHandler(null, 'mappingDialog')}
            >
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText
                primary="Map into another ID"
                secondary="Map & delete this item"
              />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.openDialogHandler(null, 'confirmationDialog')
              }
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText
                primary="Delete Team"
                secondary="This will map and delete"
              />
            </ListItem>
          </List>
        </div>
        <MappingDialog
          selectedEntityId={this.state.selectedEntityId}
          handleInputChange={this.handleInputChange}
          open={this.state.mappingDialog}
          onClose={action => this.dialogCloseHandler('mappingDialog', action)}
        /> */}
        <ConfirmationDialog
          primaryText={this.state.confirmationPrimaryText}
          secondaryText={this.state.confirmationSecondaryText}
          open={this.state.isDialogOpen}
          onClose={action => this.dialogCloseHandler(action)}
        />
      </div>
    );
  }
}

Mapping.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
};

export default Mapping;
