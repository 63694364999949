import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CancelledIcon = props => (
  <SvgIcon {...props} viewBox="0,0,38,38">
    <path d="M19.1,36.7c4.6,0,8.7,-1.8,11.9,-4.7L6.1,7.2C3.3,10.4,1.5,14.5,1.5,19.1c0,9.7,7.9,17.6,17.6,17.6ZM32,31c2.9,-3.2,4.7,-7.3,4.7,-11.9C36.7,9.4,28.8,1.5,19.1,1.5C14.5,1.5,10.4,3.3,7.2,6.1L32,31ZM19.1,38.2C8.6,38.2,0,29.6,0,19.1C0,8.6,8.6,0,19.1,0C29.6,0,38.2,8.6,38.2,19.1c0,10.5,-8.6,19.1,-19.1,19.1Z" />
  </SvgIcon>
);

export default CancelledIcon;
