import Backend from '../../services/backend';

export const fetchTaskCount = taskType => ({
  type: `FETCH_${String(taskType).toUpperCase()}_TASK_COUNT`,
  payload: Backend.fetchTaskCount(taskType),
});

export const fetchAllTaskCount = (dispatch) => {
  dispatch(fetchTaskCount('pending'));
  dispatch(fetchTaskCount('unverified'));
  dispatch(fetchTaskCount('verified_pending'));
  dispatch(fetchTaskCount('rejected'));
};

export const selectTask = (taskId, taskType) => (dispatch) => {
  dispatch({
    type: `SELECT_${String(taskType).toUpperCase()}_TASK`,
    payload: Backend.fetchTask(taskId),
  });
};

export const fetchPendingTask = () => ({
  type: 'FETCH_PENDING_TASK',
  payload: Backend.fetchNextTask('pending'),
});

export const fetchPendingSuggestions = taskId => ({
  type: 'FETCH_PENDING_SUGGESTIONS',
  payload: Backend.fetchTargetSuggestions(taskId),
});

export const confirmPendingTask = (taskId, action, review, targetId, overwrites) => ({
  type: 'CONFRIM_PENDING_TASK',
  payload: Backend.confirmTaskAction(taskId, action, review, targetId, overwrites),
});

export const confirmPendingTaskAndFetchNext = (
  taskId,
  action,
  review,
  targetId,
  overwrites,
) => dispatch =>
  dispatch(confirmPendingTask(taskId, action, review, targetId, overwrites))
    .then(() => dispatch(fetchPendingTask()))
    .then(() => dispatch(fetchAllTaskCount));

export const fetchUnverifiedTask = () => ({
  type: 'FETCH_UNVERIFIED_TASK',
  payload: Backend.fetchNextTask('unverified'),
});

export const fetchUnverifiedSuggestions = taskId => ({
  type: 'FETCH_UNVERIFIED_SUGGESTIONS',
  payload: Backend.fetchTargetSuggestions(taskId),
});

export const confirmUnverifiedTask = (taskId, action, review, targetId, overwrites) => ({
  type: 'CONFRIM_UNVERIFIED_TASK',
  payload: Backend.confirmTaskAction(taskId, action, review, targetId, overwrites),
});

export const confirmUnverifiedTaskAndFetchNext = (
  taskId,
  action,
  review,
  targetId,
  overwrites,
) => dispatch =>
  dispatch(confirmUnverifiedTask(taskId, action, review, targetId, overwrites))
    .then(() => dispatch(fetchUnverifiedTask()))
    .then(() => dispatch(fetchAllTaskCount));

export const fetchTasks = (taskType, page) => ({
  type: `FETCH_${String(taskType).toUpperCase()}_TASKS`,
  payload: Backend.fetchTasks(taskType, page),
});
