const initialState = {
  tasks: [],
  loading: false,
  error: false,
  selectedTask: null,
};

const taskList = taskType => (
  state = initialState,
  action,
) => {
  const taskTypeUpperCase = String(taskType).toUpperCase();
  switch (action.type) {
    case `FETCH_${taskTypeUpperCase}_TASKS_PENDING`:
      return {
        ...state,
        tasks: [],
        loading: true,
        error: false,
      };
    case `FETCH_${taskTypeUpperCase}_TASKS_FULFILLED`:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
        error: false,
      };
    case `FETCH_${taskTypeUpperCase}_TASKS_REJECTED`:
      return {
        ...state,
        tasks: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default taskList;
