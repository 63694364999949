import MapIcon from '@material-ui/icons/Link';
import VerifyIcon from '@material-ui/icons/Check';
import VerifiedIcon from '@material-ui/icons/DoneAll';
import RejectedIcon from '@material-ui/icons/NotInterested';

// Tasks // in the order they appear
import IncomingTaskPage from '../modules/tasks/incoming/IncomingTaskPage';
import VerifyTaskPage from '../modules/tasks/verification/VerifyTaskPage';
import VerifiedTaskSearch from '../modules/tasks/verifiedPending/VerifiedTaskSearch';
import RejectedTaskSearch from '../modules/tasks/rejected/RejectedTaskSearch';
import VerifiedTasks from '../modules/tasks/verifiedPending/VerifiedTasks';
import RejectedTasks from '../modules/tasks/rejected/RejectedTasks';

const taskRoutes = [
  {
    title: 'Incoming',
    path: '/incoming',
    icon: MapIcon,
    mainComponent: IncomingTaskPage,
  },
  {
    title: 'Verification',
    path: '/verification',
    icon: VerifyIcon,
    mainComponent: VerifyTaskPage,
  },
  {
    title: 'Verified pending',
    path: '/verifiedpending',
    icon: VerifiedIcon,
    mainComponent: VerifiedTasks,
    drawerComponent: VerifiedTaskSearch,
  },
  {
    title: 'Rejected',
    path: '/rejected',
    icon: RejectedIcon,
    mainComponent: RejectedTasks,
    drawerComponent: RejectedTaskSearch,
  },
];

export default taskRoutes;
