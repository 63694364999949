import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  container: { padding: 24 },
  tournamentsListContainer: {
    maxHeight: 400,
    overflow: 'scroll',
  },
}));

const Tournaments = ({ tournaments }) => {
  const classes = useStyles();

  if (tournaments.length === 0) {
    return (
      <Typography component="h4" style={{ padding: 24 }}>
        No tournaments found in this region
      </Typography>
    );
  }
  return (
    <div className={classes.container}>
      <Typography component="h4">Tournaments</Typography>
      <div className={classes.tournamentsListContainer}>
        <List>
          {tournaments.map(tournament => (
            <ListItem
              button
              key={tournament.id}
              component={Link}
              to={`/tournaments/${tournament.id}`}
              target="_blank"
            >
              <ListItemText primary={tournament.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

Tournaments.defaultProps = {
  tournaments: [],
};

Tournaments.propTypes = {
  tournaments: PropTypes.instanceOf(Array),
};
export default React.memo(Tournaments);
