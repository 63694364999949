import axios from 'axios';

const searchRequestCreator = () => {
  let cancelToken;

  return async (query) => {
    // Check if we made a request
    if (cancelToken) {
      // Cancel the previous request before making a new request
      cancelToken.cancel();
    }
    // Create a new CancelToken
    cancelToken = axios.CancelToken.source();
    const res = await axios(query, {
      cancelToken: cancelToken.token,
      headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      withCredentials: true,
    });
    const result = res.data;
    return result;
  };
};

export default searchRequestCreator();
