import React from 'react';
import { Divider } from '@material-ui/core';
import ExpandablePropertyItem from '../../../../components/ExpandablePropertyItem';

const AttributeNestedField = ({ entity, propNames, highlightFields }) =>
  propNames
    .filter(propName => !!entity[propName])
    .map(propName => (
      <div key={propName}>
        <Divider style={{ marginRight: -24, marginLeft: -24 }} />
        <ExpandablePropertyItem
          objectList={Array.isArray(entity[propName]) ? entity[propName] : [entity[propName]]}
          property={propName}
          highlightFields={highlightFields}
        />
      </div>
    ));

export default AttributeNestedField;
