import React, { Component } from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  positionStatic: {
    transform: 'none',
  },
};

class EntityCardContent extends Component {
  state = {
    selectedTab: this.props.tabs && this.props.tabs[0],
  };

  selectTab = (event, selectedTab) => {
    this.setState({ selectedTab });
  };

  render() {
    const { tabs, classes } = this.props;
    return [
      <AppBar key="entity_tabs_appbar" position="static" className={classes.positionStatic}>
        <Tabs
          variant="fullWidth"
          scrollButtons="on"
          value={this.state.selectedTab}
          onChange={this.selectTab}
        >
          {tabs.map(tab => (
            <Tab value={tab} label={tab} key={`tab_key_${tab}`} />
          ))}
        </Tabs>
      </AppBar>,
      (Array.isArray(this.props.children) ? this.props.children : [this.props.children])
        .filter(child => child && child.props.tab === this.state.selectedTab),
    ];
  }
}

export default withStyles(styles)(EntityCardContent);
