import React from 'react';
import { Paper, Typography, LinearProgress, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = () => ({
  card: { marginBottom: 24, marginRight: 24, padding: 24 },
  horizontalCard: { flex: 1, display: 'flex', flexDirection: 'column' },
  cardHeader: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    height: 200,
    margin: -24,
    marginBottom: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    color: '#fff',
  },
  cardHeaderTask: {
    backgroundColor: '#2196f3',
  },
});

const LoadingCard = ({ classes, loadingText }) => (
  <Paper className={classNames(classes.card, classes.horizontalCard)} style={{ minHeight: 500 }}>
    <Paper className={classNames(classes.card, classes.cardHeader, classes.cardHeaderTask)} />
    <LinearProgress />
    <Typography>{loadingText}</Typography>
  </Paper>
);

export default withStyles(styles)(LoadingCard);
