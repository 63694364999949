import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';

const TargetSearchField = ({ value, handleChange, onClear }) => (
  <TextField
    id="standard-basic"
    label="Search"
    fullWidth
    value={value}
    onChange={handleChange}
    InputProps={{
      endAdornment: (
        <IconButton disabled={!value} color="primary" onClick={onClear}>
          <ClearIcon />
        </IconButton>
      ),
    }}
  />
);

TargetSearchField.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default TargetSearchField;
