const initialState = {
  id: null,
  available_providers: [],
  selected_provider: null,
  fetchLoading: false,
  fetchError: false,
  selectLoading: false,
  selectError: false,
};

const tournamentProviders = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FETCH_TOURNAMENT_PROVIDER_PENDING':
      return {
        ...state,
        fetchLoading: true,
        fetchError: false,
      };
    case 'FETCH_TOURNAMENT_PROVIDER_FULFILLED':
      return {
        ...state,
        selected_provider: action.payload,
        fetchLoading: false,
        fetchError: false,
      };
    case 'FETCH_TOURNAMENT_PROVIDER_REJECTED':
      return {
        ...state,
        fetchLoading: false,
        fetchError: true,
      };
    case 'FETCH_TOURNAMENT_PROVIDER_NAMES_PENDING':
      return {
        ...state,
        fetchLoading: true,
        fetchError: false,
        selectLoading: false,
        selectError: false,
      };
    case 'FETCH_TOURNAMENT_PROVIDER_NAMES_FULFILLED':
      return {
        ...state,
        available_providers: action.payload,
        fetchLoading: false,
        fetchError: false,
      };
    case 'FETCH_TOURNAMENT_PROVIDER_NAMES_REJECTED':
      return {
        ...state,
        fetchLoading: false,
        fetchError: true,
      };
    case 'SELECT_TOURNAMENT_PROVIDER_PENDING':
      return {
        ...state,
        selectLoading: true,
        selectError: false,
      };
    case 'SELECT_TOURNAMENT_PROVIDER_FULFILLED':
      return {
        ...state,
        selectLoading: false,
        selectError: false,
      };
    case 'SELECT_TOURNAMENT_PROVIDER_REJECTED':
      return {
        ...state,
        selectLoading: false,
        selectError: true,
      };
    default:
      return state;
  }
};

export default tournamentProviders;
