import React from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditSeason from './EditSeason';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardTab from '../components/EntityCardTab';
import EntityCardFooter from '../components/EntityCardFooter';
import ProviderPopover from '../../../components/ProviderPopover';
import Mapping from '../components/Mapping';

const SeasonCard = ({
  season,
  attributeTypes,
  currentEdits,
  buttonEnable,
  providers,
  readOnly,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const overwriteEntity = () => {
    overwriteAndReloadEntity(season.id, currentEdits);
  };

  const reloadContent = () => {
    fetchEntityAndOverwrites(season.id);
  };

  return (
    season && (
      <EntityCard>
        <EntityCardHeader
          primary={season.name}
          imgUrl=""
        >
          {readOnly && <ProviderPopover entityType="season" providers={providers} />}
        </EntityCardHeader>
        <EntityCardContent
          tabs={
            readOnly ? ['information'] : ['information', 'mappings']
          }
        >
          <EntityCardTab tab="information">
            <EditSeason
              entity={season}
              entityType="season"
              key={season.id}
              editableProperties={readOnly ? [] : Object.keys(season).filter(key => key !== 'id')}
              attributeTypes={attributeTypes}
              reloadContent={reloadContent}
            />

            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          <EntityCardTab tab="mappings">
            <Mapping entityType="season" entityId={season.id} />
          </EntityCardTab>
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default SeasonCard;
