import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getReducedEntityObject, getReducedEntityTypes } from '../../redux/reducers';
import EditTournament from '../entities/tournaments/EditTournament';
import EditMatch from '../entities/matches/EditMatch';
import EditTeam from '../entities/teams/EditTeam';
import EditManager from '../entities/managers/EditManager';
import EditPlayer from '../entities/players/EditPlayer';
import EditRegion from '../entities/regions/EditRegion';
import EditSeason from '../entities/seasons/EditSeason';
import EditStadium from '../entities/stadiums/EditStadium';
import Layout from './components/TaskPageLayout';
import NoTasksPlaceHolder from './components/NoTasksPlaceholder';
import LoadingCard from './components/LoadingCard';
import TaskCardHeader from './components/TaskCardHeader';
import TaskButtons from './components/TaskButtons';
import TaskEntityDetails from './components/TaskEntityDetails';
import TaskReviewComments from './components/TaskReviewComments';
import TaskMapAction from './components/TaskMapAction';
import TargetCardHeader from './components/TargetCardHeader';
import TargetEntityDetails from './components/TargetEntityDetails';
import TargetSearch from './components/TargetSearch';
import VerifyButtons from './components/VerifyButtons';
import TargetMappedEntity from './components/TargetMappedEntity';

import { getObjectDiff } from './helper';
import Backend from '../../services/backend';

const TaskContainer = ({
  taskType,
  currentEdits,
  editsDirty,
  fetchAllTaskCount,
  resetEditState,
}) => {
  const [task, setTask] = useState(null);
  const [taskLoading, setTaskLoading] = useState(true);
  const [taskError, setTaskError] = useState(false);

  const [reviewComment, setReviewComment] = useState('');
  const [showReviewError, setShowReviewError] = useState(false);

  const [target, setTarget] = useState(null);
  const [showTargetDetails, setShowTargetDetails] = useState(false);
  const [taskEditState, setTaskEditState] = useState('');
  const [taskState, setTaskState] = useState(null);
  const [currentMappedTarget, setCurrentMappedTarget] = useState(null);

  const isTaskPresent = () => !!task && !!Object.keys(task).length;
  const isTaskInVerification = () =>
    task.state === 'unverified_accept' ||
    task.state === 'unverified_reject' ||
    task.state === 'unverified_map';

  const isTaskInEditOrPending = () => taskType === 'pending' || taskEditState === 'edit';

  const fetchTaskData = async () => {
    setTaskError(false);
    setTaskLoading(true);
    try {
      const taskResponse = await Backend.fetchNextTask(taskType);
      setTask(taskResponse);
      setTaskState(taskResponse.state);
    } catch (error) {
      setTaskError(true);
      setTask(null);
      setTaskState(null);
    } finally {
      setTaskLoading(false);
    }
  };

  useEffect(() => {
    fetchTaskData();
  }, [taskType]);

  useEffect(() => {
    // only to show the target mapping details in verification status
    if (task && isTaskInVerification() && task.target_entity_details) {
      setTarget({
        id: task.target_forza_id,
        similarity_score: task.target_similarity_score,
        targetEntity: task.target_entity_details,
      });

      setCurrentMappedTarget({
        id: task.target_forza_id,
        similarity_score: task.target_similarity_score,
        targetEntity: task.target_entity_details,
      });

      setShowTargetDetails(true);
    }
  }, [task]);

  const getEditedTaskProperties = () => {
    const edits = currentEdits;
    const newOverwrites = Object.entries(edits)
      .filter(([, value]) => !!value && !value.restore)
      .map(([key, entry]) => ({ [key]: entry.value }))
      .reduce((accum, entry) => ({ ...accum, ...entry }), {});

    const keptOverwrites = Object.keys(task.overwrites || {})
      .filter(key => edits[key] === undefined || !edits[key].restore)
      .reduce((res, key) => Object.assign(res, { [key]: task.overwrites[key] }), {});

    return Object.assign({}, keptOverwrites, newOverwrites);
  };

  const resetTaskState = () => {
    setTarget(null);
    setReviewComment(null);
    setTaskState(null);
  };

  const confirmTaskAndFetchNext = async (_taskId, _action, _review, _targetId, _overwrites) => {
    try {
      await Backend.confirmTaskAction(_taskId, _action, _review, _targetId, _overwrites);
      resetTaskState();
      fetchTaskData();
      // Refresh task counts on the drawer
      fetchAllTaskCount();
    } catch (error) {
      setTaskError(true);
    }
  };

  const resetTaskEditState = () => {
    setTaskEditState('');
    resetEditState();
  };

  const onGoBack = () => {
    setShowTargetDetails(false);
    if (taskEditState === 'edit') {
      resetTaskEditState();
    }
  };

  const onReviewChange = (rev) => {
    setReviewComment(rev);
  };
  const onMappedEntitySelect = (ent) => {
    setTarget(ent);
    setShowTargetDetails(true);
    if (taskEditState === 'edit') {
      resetTaskEditState();
    }
  };

  const onTargetEntityClick = (newTarget) => {
    // If the user in verification stage and if he had used edit option to edit,
    // and then decides to select other result to see difference,
    // previous edit state needs to be reset
    if (taskEditState === 'edit') {
      resetTaskEditState();
    }
    // if this is the suggestion result, datastructure is different
    if (newTarget.details) {
      setTarget({
        id: newTarget.target_forza_id,
        similarity_score: newTarget.similarity_score,
        targetEntity: newTarget.details,
      });
    } else {
      setTarget({
        id: getReducedEntityObject(newTarget).id || null,
        similarity_score: null,
        targetEntity: newTarget,
      });
    }
    setShowTargetDetails(true);
  };

  const onTaskEditChange = (_editState) => {
    // Clear currentEdits from redux store
    if (_editState === 'undo') {
      resetEditState();
    }
    setTaskEditState(_editState);
  };

  const onTaskAction = action => () => {
    if (action === 'reject' && (!reviewComment && !task.comment)) {
      setShowReviewError(true);
    } else {
      setShowReviewError(false);

      confirmTaskAndFetchNext(
        task.task_id,
        action,
        reviewComment || task.comment,
        target ? target.id : '',
        getEditedTaskProperties(),
      );
    }
    setShowTargetDetails(false);
  };

  const getEntityAndTypes = (entity) => {
    const entityDetails = getReducedEntityObject(entity);
    const entityTypes = getReducedEntityTypes(entity);
    return { entityDetails, entityTypes };
  };

  const getTaskEntityDetails = () => {
    if (isTaskPresent()) {
      // eslint-disable-next-line max-len
      const { entityDetails, entityTypes: taskEntityTypes } = getEntityAndTypes(task.entity_details);
      const taskEntity = Object.assign({}, entityDetails, task.overwrites);
      return { taskEntity, taskEntityTypes };
    }
    return { taskEntity: null, taskEntityTypes: null };
  };

  const getTargetEntityDetails = () => {
    if (target) {
      const targetEntityTypes = getReducedEntityTypes(target.targetEntity);
      const targetEntity = getReducedEntityObject(target.targetEntity);
      return { targetEntity, targetEntityTypes };
    }
    return { targetEntity: null, targetEntityTypes: null };
  };

  const { taskEntity, taskEntityTypes } = getTaskEntityDetails();
  const { targetEntity, targetEntityTypes } = getTargetEntityDetails();

  let attrDiffArr = [];
  if (showTargetDetails && taskEntity && targetEntity) {
    attrDiffArr = getObjectDiff(taskEntity, targetEntity);
  }

  const renderEditEntity = (entity, types, editableProps) => {
    const EntityComponent = {
      tournament: EditTournament,
      team: EditTeam,
      manager: EditManager,
      player: EditPlayer,
      match: EditMatch,
      region: EditRegion,
      season: EditSeason,
      stadium: EditStadium,
    }[task.entity_type];

    return (
      <EntityComponent
        entity={entity}
        entityType={task.entity_type}
        key={entity.id}
        editableProperties={editableProps}
        attributeTypes={types}
        reloadContent={() => {}}
        highlightFields={attrDiffArr}
      />
    );
  };

  if (!taskLoading && !taskError && !isTaskPresent()) {
    return <NoTasksPlaceHolder />;
  }

  return (
    <Layout.Container>
      <Layout.ColumnCards>
        {taskLoading && <LoadingCard loadingText="Loading task..." />}
        {!taskLoading && isTaskPresent() && (
          <Layout.ColumnCard>
            <TaskCardHeader
              taskState={task.state}
              entityType={task.entity_type}
              providerName={task.provider_name}
              providerEntityId={task.provider_entity_id}
            />
            {isTaskInEditOrPending() && (
              <TaskButtons editsDirty={editsDirty} onTaskAction={onTaskAction} />
            )}
            <TaskEntityDetails
              isEditable={taskType === 'pending' || taskEditState === 'edit'}
              taskEntity={taskEntity}
              taskEntityTypes={taskEntityTypes}
              renderEditEntity={renderEditEntity}
            />
          </Layout.ColumnCard>
        )}
        {showTargetDetails && (
          <TaskMapAction
            onTaskAction={onTaskAction}
            isEditable={taskType === 'pending' || taskEditState === 'edit'}
          />
        )}

        {taskLoading && <LoadingCard loadingText="Loading Suggestions..." />}
        {!taskLoading && isTaskPresent() && (
          <Layout.ColumnCard>
            <TargetCardHeader
              showTargetDetails={showTargetDetails}
              onGoBack={onGoBack}
              targetId={target && target.id}
              entityType={task.entity_type}
            />
            {!showTargetDetails && task.state === 'unverified_map' && currentMappedTarget && (
              <TargetMappedEntity
                target={currentMappedTarget}
                entityType={task.entity_type}
                onItemSelect={onMappedEntitySelect}
              />
            )}
            {/* Show Search field by deafault or on click of 'Go Back' */}
            {!showTargetDetails && (
              <TargetSearch
                resultsTitle={task.state === 'unverified_map' ? 'Other Guesses' : 'Best Guesses'}
                entityType={task.entity_type}
                taskId={task.task_id}
                onTargetSelect={onTargetEntityClick}
              />
            )}
            {/* Show target entity details */}
            {showTargetDetails && (
              <TargetEntityDetails
                targetEntity={targetEntity}
                targetEntityTypes={targetEntityTypes}
                renderEditEntity={renderEditEntity}
              />
            )}
          </Layout.ColumnCard>
        )}
      </Layout.ColumnCards>
      {/* Verify buttons shown only in the verification stage */}
      {taskType === 'unverified' && (
        <VerifyButtons
          taskEditState={taskEditState}
          onTaskAction={onTaskAction}
          onTaskEdit={onTaskEditChange}
        />
      )}
      {/* Review Comments box */}
      {!taskLoading && isTaskPresent() && (
        <TaskReviewComments
          review={reviewComment || task.comment}
          auditorName={task.auditor_name || ''}
          showReviewError={showReviewError}
          updateReviewNote={onReviewChange}
          autoMapped={false}
          isReviewEditable={taskState === 'pending' || taskEditState === 'edit'}
        />
      )}
    </Layout.Container>
  );
};

TaskContainer.propTypes = {
  taskType: PropTypes.string.isRequired,
  editsDirty: PropTypes.bool.isRequired,
  currentEdits: PropTypes.instanceOf(Object).isRequired,
  fetchAllTaskCount: PropTypes.func.isRequired,
  resetEditState: PropTypes.func.isRequired,
};
export default TaskContainer;
