import React from 'react';
import constants from '../../../utils/constants';
import VisualSetupSection from '../components/VisualSetupSection';

const PlayerVisualSetup = ({ player }) => (
  <React.Fragment>
    <VisualSetupSection
      entityType="player"
      entityId={player.id}
      sectionTitle="Player Image"
      images={['photo_thumbnail', 'photo_full']}
      uploadSize={{
        width: constants.images.player.photo_full.uploadWidth,
        height: constants.images.player.photo_full.uploadHeight,
      }}
      defaultExpand
      useCroppie
    />
  </React.Fragment>
);

export default PlayerVisualSetup;
