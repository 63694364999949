import { connect } from 'react-redux';
import { fetchTasks, selectTask } from '../../../redux/actions';
import TaskFilter from '../components/TaskFilter';

export default connect(
  state => ({
    title: 'Verified pending tasks',
    tasks: state.verifiedPendingTasks.tasks,
    loading: state.verifiedPendingTasks.loading,
  }),
  dispatch => ({
    searchTasks: () => dispatch(fetchTasks('verified_pending', 1)),
    selectTask: taskId => dispatch(selectTask(taskId, 'verified_pending')),
  }),
)(TaskFilter);
