import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import TargetSearchField from './TargetSearchField';
import LoadingErrorAlert from './LoadingErrorAlert';
import SearchResultsList from './SearchResultsList';
import useSuggestions from '../hooks/useSuggestions';
import useTaskEntitiesSearch from '../hooks/useTaskEntitiesSearch';

const TargetSearch = ({
  entityType, taskId, resultsTitle, onTargetSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSuggestionsLoading, isSuggestionsError, suggestions] = useSuggestions(taskId, entityType);

  // Hooks need to be in order
  const [isSearchLoading, isSearchError, searchResults] = useTaskEntitiesSearch(
    entityType,
    searchTerm,
  );

  // states for two collapsible sections - suggestions & entity search
  const [searchOpen, setSearchOpen] = useState(false);
  const [suggestionsOpen, setSuggestionsOpen] = useState(true);

  return (
    <React.Fragment>
      {/* Suggestions */}
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="inherit" variant="h6">
            {resultsTitle}
          </Typography>
          <IconButton
            color="inherit"
            style={{ height: 36, width: 36, marginRight: 6 }}
            onClick={() => setSuggestionsOpen(!suggestionsOpen)}
          >
            {suggestionsOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        </div>
        <p
          style={{
            fontSize: 10,
            color: '#2196f3',
            fontWeight: '600',
            margin: 0,
          }}
        >
          {suggestions && suggestions.length} Results
        </p>
        <Collapse in={suggestionsOpen} timeout="auto" unmountOnExit>
          {isSuggestionsLoading && <LinearProgress style={{ marginTop: 16 }} />}
          {isSuggestionsError && (
            <LoadingErrorAlert message="Oops, error while getting best guesses!, please try again after some time." />
          )}
          <SearchResultsList
            entityType={entityType}
            results={suggestions}
            onResultSelect={onTargetSelect}
          />
        </Collapse>
      </div>

      <Divider style={{ marginTop: 20 }} />

      {/* Entity Search */}
      <div style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="inherit" variant="h6">
            {'Entity Search'}
          </Typography>
          <IconButton
            color="inherit"
            style={{ height: 36, width: 36, marginRight: 6 }}
            onClick={() => setSearchOpen(!searchOpen)}
          >
            {searchOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        </div>
        {searchOpen && (
          <p
            style={{
              fontSize: 10,
              color: '#2196f3',
              fontWeight: '600',
              margin: 0,
            }}
          >
            {searchResults && searchResults.length} Results
          </p>
        )}

        <Collapse in={searchOpen} timeout="auto" unmountOnExit>
          <TargetSearchField
            value={searchTerm}
            handleChange={e => setSearchTerm(e.target.value)}
            onClear={() => setSearchTerm('')}
          />
          {isSearchLoading && <LinearProgress style={{ marginTop: 16 }} />}
          {isSearchError && (
            <LoadingErrorAlert message="Oops, error while searching!, please try again." />
          )}

          {searchResults && searchResults.length > 0 && (
            <SearchResultsList
              entityType={entityType}
              results={searchResults}
              onResultSelect={onTargetSelect}
            />
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
};

TargetSearch.propTypes = {
  entityType: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  resultsTitle: PropTypes.string.isRequired,
  onTargetSelect: PropTypes.func.isRequired,
};

export default TargetSearch;
