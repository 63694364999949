import React from 'react';
import { AppBar, Typography, Toolbar, withStyles, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
  appBar: { zIndex: theme.zIndex.drawer + 1, backgroundColor: '#2196f3' },
});

const AppHeader = ({ classes, toggleDrawer }) => (
  <AppBar position="absolute" className={classes.appBar}>
    <Toolbar>
      <IconButton color="inherit" onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" color="inherit">
        Multiball Admin
      </Typography>
    </Toolbar>
  </AppBar>
);

export default withStyles(styles, { withTheme: true })(AppHeader);
