const initialState = {
  currentEdits: {},
  dirty: false,
};

const edits = (state = initialState, action) => {
  if (action.type.indexOf('OVERWRITE_') !== -1) {
    return {
      ...state,
      currentEdits: {},
    };
  }
  switch (action.type) {
    case 'SET_EDITS_DIRTY':
      return {
        ...state,
        dirty: action.payload,
      };
    case 'SET_ENTITY_EDITS': {
      const dirty = action.payload === {} ? false : state.dirty;
      return {
        ...state,
        dirty,
        currentEdits: action.payload,
      };
    }
    case 'RESET_ENTITY_EDITS':
      return {
        ...state,
        dirty: false,
        currentEdits: {},
      };
    default:
      return state;
  }
};

export default edits;
