import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  Typography,
  Paper,
  ListSubheader,
  Divider,
  TextField,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RefreshIcon from '@material-ui/icons/Refresh';
import EntitySearchSpinner from '../../../components/EntitySearchSpinner';
import { getReducedEntityObject } from '../../../redux/reducers';

const getEntityPrimaryText = (entity) => {
  const {
    name, nickname, first_name, last_name,
  } = entity;
  return name || nickname || (first_name && last_name && `${first_name} ${last_name}`) || '';
};

const getTaskName = entityDetails => getEntityPrimaryText(getReducedEntityObject(entityDetails));

const getFilteredTasks = (tasks, filterTerm) => {
  const flattenedTasks = tasks.map(({ task_id, entity_type, entity_details }) => ({
    id: task_id,
    type: entity_type,
    name: getTaskName(entity_details),
  }));

  // Filter either by name or entity type like tournament/player/team
  const filteredTasks = flattenedTasks.filter(tsk =>
    tsk.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
      tsk.type.toLowerCase().includes(filterTerm.toLowerCase()));

  return filteredTasks;
};

const TaskFilter = ({
  title, tasks, hintText, searchTasks, selectTask, loading,
}) => {
  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    searchTasks();
  }, []);

  const handleInputChange = (event) => {
    setFilterInput(event.target.value);
  };

  const handleClearSearch = () => {
    setFilterInput('');
  };
  const onSelectTask = taskId => () => {
    selectTask(taskId);
  };

  const filteredTasks = getFilteredTasks(tasks, filterInput);

  return (
    <div>
      <div
        style={{
          minHeight: 96,
          padding: '33px 15px 0',
          backgroundColor: 'rgb(0, 97, 137)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography color="secondary" variant="h6">
          {title}
        </Typography>

        <IconButton color="secondary" onClick={searchTasks}>
          <RefreshIcon />
        </IconButton>
      </div>

      <Paper style={{ borderRadius: 0 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton color="primary" disabled>
            <AssignmentIcon />
          </IconButton>
          <TextField
            fullWidth
            value={filterInput}
            onChange={handleInputChange}
            placeholder={hintText}
            InputProps={{ disableUnderline: true }}
          />
          <IconButton color="primary" disabled={!filterInput} onClick={handleClearSearch}>
            <CloseIcon />
          </IconButton>
        </div>
      </Paper>

      {loading && <EntitySearchSpinner />}

      <div style={{ overflowY: 'auto', overflowX: 'hidden', flex: 1 }}>
        {!loading && tasks.length === 0 && <ListSubheader>No Tasks found</ListSubheader>}
      </div>

      {filteredTasks.map(({ id, type, name }) => (
        <div key={id}>
          <ListItem button onClick={onSelectTask(id)}>
            <ListItemText
              primary={name}
              secondary={type}
              secondaryTypographyProps={{ style: { textTransform: 'capitalize' } }}
            />
          </ListItem>
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default TaskFilter;
