import { useState } from 'react';
import axios from 'axios';
import Backend from '../../../services/backend';
import { getUploadImageFormData } from '../helper';

const useUploadStatus = (entityType, entityId) => {
  const [uploadStatus, setUploadStatus] = useState(null);

  const uploadImage = async (images) => {
    setUploadStatus('loading');
    try {
      const imagePromises = images.map((img) => {
        const formData = getUploadImageFormData(img.src);
        return Backend.uploadImage(entityType, entityId, img.key, formData);
      });
      await axios.all(imagePromises);
      setUploadStatus('success');
    } catch (err) {
      setUploadStatus('failure');
      throw err;
    }
  };

  return [uploadStatus, uploadImage];
};

export default useUploadStatus;
