import { connect } from 'react-redux';
import TaskFilter from '../components/TaskFilter';
import { fetchTasks, selectTask } from '../../../redux/actions';

export default connect(
  state => ({
    title: 'Rejected tasks',
    tasks: state.rejectedTasks.tasks,
    loading: state.rejectedTasks.loading,
  }),
  dispatch => ({
    searchTasks: () => dispatch(fetchTasks('rejected', 1)),
    selectTask: taskId => dispatch(selectTask(taskId, 'rejected')),
  }),
)(TaskFilter);
