import React from 'react';
import {
  ListItem,
  InputAdornment,
  IconButton,
  Tooltip,
  MenuItem,
  TextField,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import UndoIcon from '@material-ui/icons/Replay';
import RestoredIcon from '@material-ui/icons/Restore';
import RestoreValuePopover from './RestoreValuePopover';
import { validatePositiveNumber } from '../../../../utils/validations';

const isNumber = attributeType =>
  attributeType.type.value && attributeType.type.value === 'integer';
const isEnum = attributeType => !!attributeType.type.enum || !!attributeType.type.type_enum;
const isBoolean = attributeType =>
  attributeType.type.value && attributeType.type.value === 'boolean';
const isNumberValid = inputVal => validatePositiveNumber(inputVal);

const EditAttributeTextField = ({ attributeType, ...props }) => {
  const isSelect = isEnum(attributeType) || isBoolean(attributeType);
  let options = [];
  let type = 'text';
  let isValid = true;

  if (isNumber(attributeType)) {
    type = 'number';
    isValid = props.value ? isNumberValid(props.value.toString()) : true;
  }

  if (isSelect) {
    if (isEnum(attributeType)) {
      // This is for attributes like 'status'
      if (attributeType.type.type_enum) {
        options = attributeType.type.type_enum.values
          .map(val => val.filter(el => el))
          .map((el) => {
            if (el.length > 1) {
              return el.join('-');
            }
            return el.join();
          });
      } else {
        options = attributeType.type.enum;
      }
    }

    if (isBoolean(attributeType)) {
      options = [true, false];
    }
  }

  const textFieldProps = {
    ...props,
    select: isSelect,
    ...(type === 'number' && !isValid ? { error: true } : {}),
    ...(type === 'number' || !!isSelect ? { multiline: false } : {}),
  };

  return (
    <TextField {...textFieldProps}>
      {options.map(option => (
        <MenuItem value={option} key={option}>
          {String(option)}
        </MenuItem>
      ))}
    </TextField>
  );
};

const AttributeTextField = ({
  entity,
  propNames,
  attributeTypes,
  overwrites,
  getCurrentValue,
  onChangeProperty,
  onUndoEditProperty,
  parseOverwrittenProperty,
  clearProperty,
  onRestoreOverwrittenProperty,
  isLoadingOrError,
  isPropertyEditable,
  isPropertyEdited,
  isPropertyOverwritten,
  isPropertyRestored,
  highlightFields = [],
}) =>
  propNames.map(propName => (
    <ListItem
      style={{
        display: 'flex',
        flex: '1 0 49%',
        minWidth: 200,
        marginRight: 2,
        marginBottom: 2,
        backgroundColor: highlightFields.includes(propName) ? 'rgb(255, 204, 204)' : 'white',
      }}
      key={propName}
    >
      <EditAttributeTextField
        attributeType={attributeTypes[propName] ? attributeTypes[propName] : { type: 'string' }}
        multiline
        fullWidth
        disabled={isLoadingOrError() || !isPropertyEditable(propName)}
        helperText={propName}
        value={getCurrentValue(propName)}
        onChange={onChangeProperty(propName)}
        onBlur={() => {
          parseOverwrittenProperty(propName);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              {!isLoadingOrError() && isPropertyEdited(propName) && (
                <IconButton color="inherit" onClick={() => onUndoEditProperty(propName)}>
                  <UndoIcon />
                </IconButton>
              )}
              {!isLoadingOrError() &&
                getCurrentValue(propName) &&
                attributeTypes[propName] &&
                attributeTypes[propName].nullable &&
                entity[propName] && (
                  <IconButton
                    disabled={!isPropertyEditable(propName)}
                    color="inherit"
                    onClick={() => {
                      clearProperty(propName);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
            </InputAdornment>
          ),
          style: { alignItems: 'center' },
        }}
      />
      <div style={{ marginTop: -20 }}>
        {!isLoadingOrError() &&
          isPropertyOverwritten(propName) &&
          !isPropertyRestored(propName) && (
            <RestoreValuePopover
              property={propName}
              value={overwrites[propName].proposed_value}
              onRestoreOverwritten={() => onRestoreOverwrittenProperty(propName)}
            />
          )}
        {!isLoadingOrError() && isPropertyRestored(propName) && (
          <Tooltip title="Will be restored">
            <div>
              <IconButton disabled>
                <RestoredIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    </ListItem>
  ));

export default AttributeTextField;
