import React from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditPlayer from './EditPlayer';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardTab from '../components/EntityCardTab';
import EntityCardFooter from '../components/EntityCardFooter';
import ProviderPopover from '../../../components/ProviderPopover';
import PlayerVisualSetup from './PlayerVisualSetup';
import { getImageUrl } from '../../../utils/getImageUrl';
import Mapping from '../components/Mapping/Mapping';

const PlayerCard = ({
  player,
  attributeTypes,
  currentEdits,
  buttonEnable,
  providers,
  readOnly,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const overwriteEntity = () => {
    overwriteAndReloadEntity(player.id, currentEdits);
  };

  const reloadContent = () => {
    fetchEntityAndOverwrites(player.id);
  };

  return (
    player && (
      <EntityCard>
        <EntityCardHeader
          primary={player.nickname || `${player.first_name} ${player.last_name}`}
          imgUrl={getImageUrl('player', player.id, 'photo_full')}
          entityType="player"
        >
          {readOnly && <ProviderPopover entityType="player" providers={providers} />}
        </EntityCardHeader>
        <EntityCardContent
          tabs={readOnly ? ['information'] : ['information', 'visual setup', 'mappings']}
        >
          <EntityCardTab tab="information">
            <EditPlayer
              entity={player}
              entityType="player"
              key={player.id}
              editableProperties={readOnly ? [] : Object.keys(player).filter(key => key !== 'id')}
              attributeTypes={attributeTypes}
              reloadContent={reloadContent}
            />
            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          {!readOnly && (
            <EntityCardTab tab="visual setup">
              <PlayerVisualSetup player={player} />
            </EntityCardTab>
          )}
          {!readOnly && (
            <EntityCardTab tab="mappings">
              <Mapping entityType="player" entityId={player.id} />
            </EntityCardTab>
          )}
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default PlayerCard;
