import React from 'react';
import VisualSetupSection from '../components/VisualSetupSection';
import constants from '../../../utils/constants';

const TeamVisualSetup = ({ team }) => (
  <React.Fragment>
    <VisualSetupSection
      entityType="team"
      entityId={team.id}
      sectionTitle="Team Badge Thumbnail"
      images={['badge_tiny', 'badge_thumbnail', 'badge_full']}
      defaultExpand
      useCroppie
      uploadSize={{
        width: constants.images.team.badge_full.uploadWidth,
        height: constants.images.team.badge_full.uploadHeight,
      }}
    />
    <VisualSetupSection
      entityType="team"
      entityId={team.id}
      sectionTitle="Team Background Full"
      images={['background_full']}
      defaultExpand={false}
      useCroppie={false}
      uploadSize={{
        width: constants.images.team.background_full.uploadWidth,
        height: constants.images.team.background_full.uploadHeight,
      }}
    />
  </React.Fragment>
);
export default TeamVisualSetup;
