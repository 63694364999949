import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(() => ({
  container: { padding: 24 },
  seasonListContainer: {
    maxHeight: 400,
    overflow: 'scroll',
  },
}));

const Seasons = ({ seasons }) => {
  const classes = useStyles();

  if (seasons.length === 0) {
    return (
      <Typography component="h4" style={{ padding: 24 }}>
        No seasons found for this tournament
      </Typography>
    );
  }

  function getSecondaryText(season) {
    let text = '';
    if (season.starts_on) {
      text += `${season.starts_on}`;
    }
    if (season.ends_on) {
      text += ` till ${season.ends_on}`;
    }
    return text;
  }

  return (
    <div className={classes.container}>
      <Typography component="h4">Seasons</Typography>
      <div className={classes.seasonListContainer}>
        <List>
          {seasons
            .sort((s1, s2) => new Date(s2.ends_on) - new Date(s1.ends_on))
            .map((season) => (
              <ListItem button key={season.id} component={Link} to={`/seasons/${season.id}`}>
                <ListItemText primary={season.name} secondary={getSecondaryText(season)} />
                {season.current && <Chip label="Current" color="primary" />}
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
};

Seasons.defaultProps = {
  seasons: [],
};

Seasons.propTypes = {
  seasons: PropTypes.instanceOf(Array),
};

export default Seasons;
