import React from 'react';
import PropTypes from 'prop-types';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const ResultListItemAvatar = ({ uri, fallbackUri = 'https://via.placeholder.com/150.png' }) => (
  <ListItemAvatar>
    <Avatar
      src={uri}
      imgProps={{
        onError: (e) => {
          e.target.src = fallbackUri;
        },
      }}
    />
  </ListItemAvatar>
);

ResultListItemAvatar.defaultProps = {
  uri: '',
  fallbackUri: 'https://via.placeholder.com/150.png',
};

ResultListItemAvatar.propTypes = {
  uri: PropTypes.string,
  fallbackUri: PropTypes.string,
};

export default ResultListItemAvatar;
