import React from 'react';
import { ChromePicker } from 'react-color';
import {
  withStyles,
  IconButton,
  ListItem,
  InputAdornment,
  Tooltip,
  FormHelperText,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import UndoIcon from '@material-ui/icons/Replay';
import RestoredIcon from '@material-ui/icons/Restore';
import RestoreValuePopover from './RestoreValuePopover';

const styles = () => ({
  colorPickerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
    left: '80px',
    top: '10px',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
});

class AttributeColorField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };
  }
  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  toggleDatePicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  renderPicker = (classes, propName, colorVal, onChangeProperty) => (
    <React.Fragment>
      <div className={classes.colorPickerWrapper}>
        <div
          className={classes.swatch}
          onClick={this.props.isPropertyEditable(propName) ? this.toggleDatePicker : () => {}}
          role="button"
          tabIndex="0"
        >
          <div className={classes.color} style={{ backgroundColor: colorVal }} />
          {this.props.children}
        </div>
      </div>
      {this.state.displayColorPicker ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={this.handleClose} role="button" tabIndex="0" />
          <ChromePicker
            color={colorVal}
            onChangeComplete={onChangeProperty(propName)}
            disableAlpha
          />
        </div>
      ) : null}
    </React.Fragment>
  );

  render() {
    const {
      entity,
      propNames,
      attributeTypes,
      overwrites,
      getCurrentValue,
      onChangeProperty,
      onUndoEditProperty,
      clearProperty,
      onRestoreOverwrittenProperty,
      isLoadingOrError,
      isPropertyEdited,
      isPropertyOverwritten,
      isPropertyRestored,
      classes,
    } = this.props;

    return propNames.map(propName => (
      <ListItem
        style={{
          display: 'flex',
          flex: '1 0 50%',
          minWidth: 200,
          paddingBottom: '24px',
        }}
        key={propName}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.renderPicker(classes, propName, getCurrentValue(propName), onChangeProperty)}
            <div style={{ marginLeft: '1rem' }}>
              {getCurrentValue(propName) && getCurrentValue(propName)}
              {!getCurrentValue(propName) &&
                isPropertyOverwritten(propName) &&
                'No color has been set'}
            </div>
            <InputAdornment>
              {!isLoadingOrError() && isPropertyEdited(propName) && (
                <IconButton color="primary" onClick={() => onUndoEditProperty(propName)}>
                  <UndoIcon />
                </IconButton>
              )}
              {!isLoadingOrError() &&
                getCurrentValue(propName) &&
                attributeTypes[propName] &&
                attributeTypes[propName].nullable &&
                entity[propName] && (
                  <IconButton
                    color="primary"
                    onClick={() => {
                      clearProperty(propName);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
            </InputAdornment>
            <div
              style={{
                width: 36,
                height: 36,
                display: 'flex',
                maxHeight: '2em',
                alignItems: 'center',
              }}
            >
              {!isLoadingOrError() &&
                isPropertyOverwritten(propName) &&
                !isPropertyRestored(propName) && (
                  <RestoreValuePopover
                    property={propName}
                    value={overwrites[propName].proposed_value}
                    onRestoreOverwritten={() => onRestoreOverwrittenProperty(propName)}
                  />
                )}
              {!isLoadingOrError() && isPropertyRestored(propName) && (
                <Tooltip title="Will be restored">
                  <div>
                    <IconButton disabled>
                      <RestoredIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <FormHelperText id={propName}>{propName}</FormHelperText>
        </div>
      </ListItem>
    ));
  }
}

export default withStyles(styles)(AttributeColorField);
