const initialState = {
  periods: [],
  loading: false,
  error: false,
};

const matchEvents = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_MATCH_EVENTS_PENDING':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_MATCH_EVENTS_FULFILLED':
      return {
        ...state,
        periods: action.payload.periods,
        loading: false,
        error: false,
      };
    case 'FETCH_MATCH_EVENTS_REJECTED':
      if (action.payload.status && action.payload.status === 404) {
        return {
          ...state,
          periods: [],
          loading: false,
          error: false,
        };
      }
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default matchEvents;
