import { getReducedEntityObject } from './helper';

const initialState = {
  overwrites: {},
  loading: false,
  error: false,
  writing: false,
  writeError: false,
};

const entityOverwrites = entityType => (
  state = initialState,
  action,
) => {
  const entityTypeUpperCase = String(entityType).toUpperCase();
  switch (action.type) {
    case `FETCH_${entityTypeUpperCase}_OVERWRITES_PENDING`:
      return {
        ...state,
        overwrites: {},
        loading: true,
        error: false,
        writeError: false,
      };
    case `FETCH_${entityTypeUpperCase}_OVERWRITES_FULFILLED`:
      return {
        ...state,
        overwrites: action.payload,
        loading: false,
        error: false,
        writing: false,
      };
    case `FETCH_${entityTypeUpperCase}_OVERWRITES_REJECTED`:
      return {
        ...state,
        overwrites: {},
        loading: false,
        writing: false,
        error: true,
      };
    case `OVERWRITE_${entityTypeUpperCase}_PENDING`:
      return {
        ...state,
        writing: true,
        writeError: false,
      };
    case `OVERWRITE_${entityTypeUpperCase}_FULFILLED`:
      return {
        ...state,
        writeError: false,
      };
    case `OVERWRITE_${entityTypeUpperCase}_REJECTED`:
      return {
        ...state,
        writing: false,
        writeError: true,
      };
    case 'FETCH_PENDING_TASK_FULFILLED':
      return {
        ...state,
        overwrites: {},
      };
    case 'FETCH_UNVERIFIED_TASK_FULFILLED': {
      const task = action.payload;
      if (task.entity_type === entityType) {
        // Default to empty object, since only accepted tasks have overwrites
        const overwrites = task.overwrites || {};

        return {
          ...state,
          overwrites: Object.keys(overwrites)
            .map((key) => {
              const value = getReducedEntityObject(task.entity_details)[key];
              return { [key]: { proposed_value: value, previous_value: value } };
            })
            .reduce((a, b) => ({ ...a, ...b }), {}),
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default entityOverwrites;
