import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

import { fetchAllTaskCount } from './redux/actions';
import {
  closePrimaryDrawer,
  togglePrimaryDrawer,
  openSecondaryDrawer,
  closeSecondaryDrawer,
} from './redux/actions/drawer';
import setAppWindowMode from './redux/actions/appDimensions';
import sections, { globalRoutes } from './routes';

import AppAlert from './components/AppAlert';
import AppHeader from './components/AppHeader';
import AppDrawer from './modules/drawer/AppDrawer';
import constants from './utils/constants';
import getAppDimensions from './utils/getAppDimensions';

const mapStateToProps = state => ({
  pendingTaskCount: state.tasks.pendingTaskCount,
  unverifiedTaskCount: state.tasks.unverifiedTaskCount,
  verifiedPendingTaskCount: state.tasks.verifiedPendingTaskCount,
  rejectedTaskCount: state.tasks.rejectedTaskCount,
  drawer: state.drawer,
  appWindowMode: state.appDimensions.mode,
});

const mapDispatchToProps = dispatch => ({
  fetchAllTaskCount: () => dispatch(fetchAllTaskCount),
  togglePriDrawer: () => dispatch(togglePrimaryDrawer()),
  closePriDrawer: () => dispatch(closePrimaryDrawer()),
  openSecDrawer: () => dispatch(openSecondaryDrawer()),
  closeSecDrawer: () => dispatch(closeSecondaryDrawer()),
  setAppWindowMode: mode => dispatch(setAppWindowMode(mode)),
});

const styles = theme => ({
  content: {
    marginLeft: -constants.drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    maxHeight: '100vh',
    overflowX: 'auto',
    overflowY: 'auto',
    flex: 1,
  },
  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sectionBackdrop: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  toolbar: theme.mixins.toolbar,
});

let timeoutId = null;

class AppContainer extends Component {
  componentDidMount() {
    this.props.fetchAllTaskCount();

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      const appMode = getAppDimensions();
      this.props.setAppWindowMode(appMode);
    }, constants.windowResizeDebounceDelay);
  };

  render() {
    const {
      classes,
      user,
      appWindowMode,
      pendingTaskCount,
      unverifiedTaskCount,
      verifiedPendingTaskCount,
      rejectedTaskCount,
      drawer,
      onLogout,
      togglePriDrawer,
      closePriDrawer,
      openSecDrawer,
      closeSecDrawer,
    } = this.props;
    const { primaryDrawerOpen, secondaryDrawerOpen } = drawer;
    return (
      <div style={{ display: 'flex', overflow: 'hidden' }}>
        <AppAlert />

        <AppHeader toggleDrawer={togglePriDrawer} />

        <AppDrawer
          user={user}
          mode={appWindowMode}
          drawerOpen={primaryDrawerOpen}
          secondarySectionOpen={secondaryDrawerOpen}
          pendingTaskCount={pendingTaskCount}
          unverifiedTaskCount={unverifiedTaskCount}
          verifiedPendingTaskCount={verifiedPendingTaskCount}
          rejectedTaskCount={rejectedTaskCount}
          onLogout={onLogout}
          openSecondarySection={openSecDrawer}
          closeSecondarySection={closeSecDrawer}
          closeDrawer={closePriDrawer}
        />

        <div className={classNames(classes.content, { [classes.contentShift]: primaryDrawerOpen })}>
          <div className={classes.toolbar} />
          <Switch>
            {/* Routes that are global and not necessarily shown in the drawer */}
            {globalRoutes.map(route => (
              <Route exact path={route.path} key={route.path}>
                <route.component onLogout={onLogout} />
              </Route>
            ))}
            {/* Routes that are shown/nagivated from the side drawer */}
            {sections.map(section =>
              section.routes
                .filter(route => !!route.mainComponent)
                .map(route => (
                  <Route exact path={route.path} key={route.path}>
                    <route.mainComponent onLogout={onLogout} />
                  </Route>
                )))}
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(AppContainer)));
