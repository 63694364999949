import getAppDimensions from '../../utils/getAppDimensions';
import { SET_APP_WINDOW_MODE } from '../actions/actionTypes';

const initialState = {
  mode: getAppDimensions(),
};

const appDimensions = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_WINDOW_MODE:
      return {
        ...state,
        mode: action.payload,
      };

    default:
      return state;
  }
};

export default appDimensions;
