const initialState = {
  providers: [],
};

const availableProviders = entityType => (state = initialState, action) => {
  switch (action.type) {
    case `FETCH_${String(entityType).toUpperCase()}_PROVIDERS_FULFILLED`:
      return {
        ...state,
        providers: action.payload,
      };
    case `FETCH_${String(entityType).toUpperCase()}_PROVIDERS_REJECTED`:
      return {
        ...state,
        providers: [],
      };
    default:
      return state;
  }
};

export default availableProviders;
