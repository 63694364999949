import React from 'react';
import {
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
  Tooltip
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import UndoIcon from '@material-ui/icons/Replay';
import RestoredIcon from '@material-ui/icons/Restore';
import RestoreValuePopover from './RestoreValuePopover';

const AttributeTwoElementNameField = ({
  entity,
  parentPropertyName,
  attributeTypes,
  overwrites,
  getCurrentValue,
  onChangeProperty,
  onUndoEditProperty,
  parseOverwrittenProperty,
  clearProperty,
  onRestoreOverwrittenProperty,
  isLoadingOrError,
  isPropertyEditable,
  isPropertyEdited,
  isPropertyOverwritten,
  isPropertyRestored,
  highlightFields = [],
}) => {
  const disabled = isLoadingOrError() || !isPropertyEditable(parentPropertyName);

  const getFieldValue = (fieldName) => {
    const [fullNameFirst, fullNameLast] = getCurrentValue(parentPropertyName);

    if (fieldName === 'full_name_first') {
      return fullNameFirst;
    }
    return fullNameLast;
  };

  const onChange = (fieldName, event) => {
    if (fieldName === 'full_name_first') {
      const fullNameFirst = event.target.value;
      const fullNameLast = document.getElementById('full_name_last').value;
      const fullName = [fullNameFirst, fullNameLast];

      onChangeProperty(parentPropertyName)({ target: { value: fullName } });
    } else {
      const fullNameLast = event.target.value;
      const fullNameFirst = document.getElementById('full_name_first').value;
      const fullName = [fullNameFirst, fullNameLast];

      onChangeProperty(parentPropertyName)({ target: { value: fullName } });
    }
  };

  const onBlur = (fieldName) => {
    return parseOverwrittenProperty(parentPropertyName);
  };

  const isFieldValueEdited = (fieldName) => {
    const isFullNameEdited = isPropertyEdited(parentPropertyName);

    let isFieldEdited;
    if (isFullNameEdited) {
      const [fullNameFirst, fullNameLast] = getCurrentValue(parentPropertyName);
      const [originalFirstName, originalLastName] = entity[parentPropertyName];

      if (fieldName === 'full_name_first') {
        isFieldEdited = originalFirstName !== fullNameFirst;
      } else {
        isFieldEdited = originalLastName !== fullNameLast;
      }
    }
    return isFieldEdited;
  };

  const onUndoEditPropertyFn = (fieldName) => {
    let [fullNameFirst, fullNameLast] = getCurrentValue(parentPropertyName);
    const [originalFirstName, originalLastName] = entity[parentPropertyName];

    if (fieldName === 'full_name_first') {
      fullNameFirst = originalFirstName;
    } else {
      fullNameLast = originalLastName;
    }
    onUndoEditProperty(parentPropertyName, [fullNameFirst, fullNameLast]);
  };

  const localPropNames = ['full_name_first', 'full_name_last'];

  return localPropNames.map(propName => (
    <ListItem
      style={{
        display: 'flex',
        flex: '1 0 49%',
        minWidth: 200,
        marginRight: 2,
        marginBottom: 2,
        backgroundColor: highlightFields && highlightFields.includes(parentPropertyName) ? 'rgb(255, 204, 204)' : 'white',
      }}
      key={propName}
    >
      <TextField
        multiline
        fullWidth
        id={propName}
        disabled={disabled}
        helperText={propName}
        value={getFieldValue(propName)}
        onChange={event => onChange(propName, event)}
        onBlur={() => onBlur(propName)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              {!isLoadingOrError() && isFieldValueEdited(propName) && (
                <IconButton color="inherit" onClick={() => onUndoEditPropertyFn(propName)}>
                  <UndoIcon />
                </IconButton>
              )}
              {!isLoadingOrError() &&
                getFieldValue(propName) &&
                attributeTypes[parentPropertyName] &&
                attributeTypes[parentPropertyName].nullable &&
                entity[parentPropertyName] && (
                  <IconButton
                    disabled={!isPropertyEditable(parentPropertyName)}
                    color="inherit"
                    onClick={() => {
                      clearProperty(propName);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
            </InputAdornment>
          ),
          style: { alignItems: 'center' },
        }}
      />
      <div style={{ marginTop: -20 }}>
        {!isLoadingOrError() &&
          isPropertyOverwritten(parentPropertyName) &&
          !isPropertyRestored(parentPropertyName) && (
            <RestoreValuePopover
              property={parentPropertyName}
              value={overwrites[parentPropertyName].proposed_value}
              onRestoreOverwritten={() => onRestoreOverwrittenProperty(parentPropertyName)}
            />
          )}
        {!isLoadingOrError() && isPropertyRestored(parentPropertyName) && (
          <Tooltip title="Will be restored">
            <div>
              <IconButton disabled>
                <RestoredIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    </ListItem>
  ));
};

export default AttributeTwoElementNameField;
