import React from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MatchEvents from './MatchEvents/MatchEvents';
import EditMatch from './EditMatch';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardTab from '../components/EntityCardTab';
import EntityProviderPopopver from '../components/EntityProviderPopopver';
import EntityCardFooter from '../components/EntityCardFooter';
import Mapping from '../components/Mapping';

const MatchCard = ({
  mbMatch: match,
  attributeTypes,
  buttonEnable,
  currentEdits,
  readOnly,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const overwriteEntity = () => {
    // TODO: Remove this as soon as the api is fixed on BE
    if (currentEdits.status && Object.keys(currentEdits.status).length > 0) {
      // eslint-disable-next-line no-param-reassign
      currentEdits.status.value = currentEdits.status.value.split('-');
      if (currentEdits.status.value.length === 1) {
        currentEdits.status.value.push(null);
      }
    }
    overwriteAndReloadEntity(match.id, currentEdits);
  };

  return (
    match && (
      <EntityCard>
        <EntityCardHeader
          primary={
            <div>
              {match.home_team.name}
              <br />
              {match.away_team.name}
            </div>
          }
        >
          {!readOnly && <EntityProviderPopopver key={match.id} entityType="match" entityId={match.id} />}
        </EntityCardHeader>
        <EntityCardContent tabs={['information', 'events', 'mappings']}>
          <EntityCardTab tab="information">
            <EditMatch
              entity={match}
              entityType="match"
              key={match.id}
              editableProperties={readOnly ? [] : Object.keys(match).filter((key) => key !== 'id')}
              attributeTypes={attributeTypes}
              reloadContent={() => {
                fetchEntityAndOverwrites(match.id);
              }}
            />
            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          <EntityCardTab tab="events">
            <MatchEvents match={match} />
          </EntityCardTab>
          <EntityCardTab tab="mappings">
            <Mapping entityType="match" entityId={match.id} />
          </EntityCardTab>
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default MatchCard;
