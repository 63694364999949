import React from 'react';
import { Typography, withStyles, TextField, Paper } from '@material-ui/core';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import TournamentCard from '../../entities/tournaments/TournamentCard';
import ManagerCard from '../../entities/managers/ManagerCard';
import PlayerCard from '../../entities/players/PlayerCard';
import TeamCard from '../../entities/teams/TeamCard';
import RegionCard from '../../entities/regions/RegionCard';
import SeasonCard from '../../entities/seasons/SeasonCard';
import StadiumCard from '../../entities/stadiums/StadiumCard';

const styles = {
  reviewCommentWrapper: {
    display: 'flex',
    flex: 1,
    maxWidth: 800,
    opacity: 1,
    overflow: 'auto',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  reviewComment: {
    flex: 1,
    marginBottom: 24,
    marginRight: 24,
    marginLeft: 24,
    paddingBottom: 10,
  },
  reviewInput: {
    minHeight: 64,
    padding: '0 12px',
    borderRadius: 3,
    marginTop: '8px',
  },
};

const entities = {
  tournament: TournamentCard,
  team: TeamCard,
  manager: ManagerCard,
  player: PlayerCard,
  region: RegionCard,
  season: SeasonCard,
  stadium: StadiumCard,
};

const renderEntity = (task) => {
  const taskEntity = task && getReducedEntityObject(task.entity_details);
  const taskAttributeTypes = task && getReducedEntityTypes(task.entity_details);

  if (task.entity_type) {
    const EntityComponent = entities[task.entity_type];
    const entityProp = { [task.entity_type]: taskEntity };
    return (
      <EntityComponent
        readOnly
        {...entityProp}
        attributeTypes={taskAttributeTypes}
        buttonEnable={false}
        providers={[
          {
            provider_name: task.provider_name,
            provider_entity_id: task.provider_entity_id,
          },
        ]}
        currentEdits={{}}
        overwriteAndReloadEntity={() => {}}
        fetchEntityAndOverwrites={() => {}}
      />
    );
  }
  return null;
};

const TaskEntityCard = ({ task, classes }) => {
  if (task) {
    return (
      <React.Fragment>
        {renderEntity(task)}
        <div className={classes.reviewCommentWrapper}>
          <Paper className={classes.reviewComment}>
            <Typography style={{ margin: '10px 0 0 12px' }}>
              {`Auditor: ${task.auditor_name ? task.auditor_name : ''}`}
            </Typography>
            <TextField
              variant="outlined"
              disabled
              fullWidth
              multiline
              className={classes.reviewInput}
              value={task.comment ? task.comment : ''}
              helperText="Comments"
              FormHelperTextProps={{ style: { marginLeft: 0 } }}
            />
          </Paper>
        </div>
      </React.Fragment>
    );
  }
  return null;
};

export default withStyles(styles)(TaskEntityCard);
