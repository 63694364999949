import React from 'react';
import { connect } from 'react-redux';
import RegionIcon from '@material-ui/icons/Public';
import ManagerIcon from '../../../assets/icons/ManagerIcon';
import { getReducedEntityObjectList } from '../../../redux/reducers';
import constants from '../../../utils/constants';
import ManagerEntityListItem from './ManagerEntityListItem';
import EntitySearch from '../components/EntitiySearch';

const ManagerSearch = connect(state => ({
  entities: getReducedEntityObjectList(state.entities.managers),
}))(props => (
  <EntitySearch
    {...{
      ...props,
      title: 'Managers',
      entityType: constants.entityType.manager,
      EntityListItem: ManagerEntityListItem,
      searches: [
        {
          search: 'name',
          leftIcon: <ManagerIcon />,
          hintText: 'Manager',
        },
        {
          search: 'region',
          leftIcon: <RegionIcon />,
          hintText: 'Region',
        },
      ],
    }}
  />
));

export default ManagerSearch;
