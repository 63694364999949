import {
  OPEN_PRIMARY_DRAWER,
  CLOSE_PRIMARY_DRAWER,
  TOGGLE_PRIMARY_DRAWER,
  OPEN_SECONDARY_DRAWER,
  CLOSE_SECONDARY_DRAWER,
  TOGGLE_SECONDARY_DRAWER,
} from './actionTypes';

export const openPrimaryDrawer = () => ({ type: OPEN_PRIMARY_DRAWER });
export const closePrimaryDrawer = () => ({ type: CLOSE_PRIMARY_DRAWER });
export const togglePrimaryDrawer = () => ({ type: TOGGLE_PRIMARY_DRAWER });
export const openSecondaryDrawer = () => ({ type: OPEN_SECONDARY_DRAWER });
export const closeSecondaryDrawer = () => ({ type: CLOSE_SECONDARY_DRAWER });
export const toggleSecondaryDrawer = () => ({ type: TOGGLE_SECONDARY_DRAWER });
