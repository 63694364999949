// This file is supposed to act as helper module for entities.
// functions used by entities in common should live here to avoid duplication of code

// eslint-disable-next-line import/prefer-default-export
export const getUploadImageFormData = (inputObj) => {
  const formData = new FormData();
  formData.append('image_data', inputObj.image_data);

  if (inputObj.scale) {
    formData.append('scale', inputObj.scale);
  }
  if (inputObj.top && Math.abs(inputObj.top) !== 0) {
    formData.append('top', Math.round(inputObj.top));
  }
  if (inputObj.left && Math.abs(inputObj.left) !== 0) {
    formData.append('left', Math.round(inputObj.left));
  }
  return formData;
};
