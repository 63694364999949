import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const TaskEntityDetails = ({
  isEditable, taskEntity, taskEntityTypes, renderEditEntity,
}) => (
  <React.Fragment>
    <Typography>Properties</Typography>
    {(() => {
      const editableProperties = isEditable ? Object.keys(taskEntity) : [];
      return renderEditEntity(taskEntity, taskEntityTypes, editableProperties);
    })()}
    <div style={{ flex: 1 }} />
  </React.Fragment>
);

TaskEntityDetails.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  taskEntity: PropTypes.instanceOf(Object).isRequired,
  taskEntityTypes: PropTypes.instanceOf(Object).isRequired,
  renderEditEntity: PropTypes.func.isRequired,
};

export default TaskEntityDetails;
