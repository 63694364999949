import React, { useState, useEffect } from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Backend from '../../../services/backend';
import useGenericStatus from '../../../dataHooks/useGenericStatus';
import EditRegion from './EditRegion';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardTab from '../components/EntityCardTab';
import EntityCardFooter from '../components/EntityCardFooter';
import ProviderPopover from '../../../components/ProviderPopover';
import RegionVisualSetup from './RegionVisualSetup';
import { getImageUrl } from '../../../utils/getImageUrl';
import Mapping from '../components/Mapping';
import Tournaments from './Tournaments';
import { getReducedEntityObjectList } from '../../../redux/reducers/helper';

const RegionCard = ({
  region,
  attributeTypes,
  currentEdits,
  buttonEnable,
  providers,
  readOnly,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const [tournaments, setTournaments] = useState([]);
  const [loading, error, setLoading, setError] = useGenericStatus(false);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        setLoading(true);

        const tournamentsResponse = await Backend.fetchRegionTournaments(region.id);
        setTournaments(getReducedEntityObjectList(tournamentsResponse));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (region && region.id) {
      fetchTournaments();
    }
  }, [region && region.id]);

  const overwriteEntity = () => {
    overwriteAndReloadEntity(region.id, currentEdits);
  };

  const reloadContent = () => {
    fetchEntityAndOverwrites(region.id);
  };

  return (
    region && (
      <EntityCard>
        <EntityCardHeader
          primary={region.name}
          imgUrl={getImageUrl('region', region.id, 'background_full')}
        >
          {readOnly && <ProviderPopover entityType="region" providers={providers} />}
        </EntityCardHeader>
        <EntityCardContent
          tabs={
            readOnly ? ['information'] : ['information', 'tournaments', 'visual setup', 'mappings']
          }
        >
          <EntityCardTab tab="information">
            <EditRegion
              entity={region}
              entityType="region"
              key={region.id}
              editableProperties={readOnly ? [] : Object.keys(region).filter(key => key !== 'id')}
              attributeTypes={attributeTypes}
              reloadContent={reloadContent}
            />

            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          <EntityCardTab tab="tournaments">
            {!loading && !error && <Tournaments tournaments={tournaments} />}
          </EntityCardTab>
          <EntityCardTab tab="visual setup">
            <RegionVisualSetup region={region} />
          </EntityCardTab>
          <EntityCardTab tab="mappings">
            <Mapping entityType="region" entityId={region.id} />
          </EntityCardTab>
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default RegionCard;
