import React from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditTeam from './EditTeam';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardFooter from '../components/EntityCardFooter';
import EntityCardTab from '../components/EntityCardTab';
import ProviderPopover from '../../../components/ProviderPopover';
import TeamVisualSetup from './TeamVisualSetup';
import { getImageUrl } from '../../../utils/getImageUrl';
import Mapping from '../components/Mapping';

const TeamCard = ({
  team,
  attributeTypes,
  currentEdits,
  buttonEnable,
  providers,
  readOnly,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const overwriteEntity = () => {
    overwriteAndReloadEntity(team.id, currentEdits);
  };

  const reloadContent = () => {
    fetchEntityAndOverwrites(team.id);
  };

  return (
    team && (
      <EntityCard>
        <EntityCardHeader
          primary={
            <span>
              {team.name} {!!team.age_group && `U${team.age_group}`}
            </span>
          }
          imgUrl={getImageUrl('team', team.id, 'background_full')}
        >
          {readOnly && <ProviderPopover entityType="team" providers={providers} />}
        </EntityCardHeader>
        <EntityCardContent
          tabs={readOnly ? ['information'] : ['information', 'visual setup', 'mappings']}
        >
          <EntityCardTab tab="information">
            <EditTeam
              entity={team}
              entityType="team"
              key={team.id}
              editableProperties={readOnly ? [] : Object.keys(team).filter(key => key !== 'id')}
              attributeTypes={attributeTypes}
              reloadContent={reloadContent}
            />
            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          {!readOnly && (
            <EntityCardTab tab="visual setup">
              <TeamVisualSetup team={team} />
            </EntityCardTab>
          )}
          {!readOnly && (
            <EntityCardTab tab="mappings">
              <Mapping entityType="team" entityId={team.id} />
            </EntityCardTab>
          )}
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default TeamCard;
