import React from 'react';
import { ListItem, Typography, ListItemText, withStyles } from '@material-ui/core';
import RedCardIcon from '../../../assets/icons/matchlist/RedCardIcon';
import MatchEntityListItemAvatar from './MatchEntityListItemAvatar';
import ResultListItemText from '../components/ResultListItemText';

const styles = {
  primaryText: {
    display: 'flex',
    alignItems: 'center',
  },
  teamName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      overflow: 'visible',
    },
  },
};

const highlightPrimary = (priScore, secScore) =>
  priScore !== null && secScore !== null && priScore > secScore;

const isScoreNull = (priScore, secScore) => priScore === null || secScore === null;

const MatchScoreName = ({ classes, children }) => (
  <ResultListItemText
    primary={<React.Fragment>{children}</React.Fragment>}
    primaryStyle={classes.primaryText}
  />
);

const RedCards = ({ match, team }) => {
  let redCardCount = 0;
  if (team === 'home_team') {
    [redCardCount] = match.red_card_count;
  } else {
    [, redCardCount] = match.red_card_count;
  }
  return (
    <Typography component="span" style={{ display: 'flex', marginLeft: 3 }}>
      {Array(redCardCount)
        .fill()
        .map((val, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <RedCardIcon key={index} />
        ))}
    </Typography>
  );
};

const renderScore = (classes, primaryScore, secondaryScore) => {
  const shouldHightLight = highlightPrimary(primaryScore, secondaryScore);
  const scoreNull = isScoreNull(primaryScore, secondaryScore);
  return (
    <Typography
      component="span"
      style={{
        fontWeight: shouldHightLight ? 'normal' : 'lighter',
        marginRight: scoreNull ? '0px' : '0.4rem',
      }}
    >
      {primaryScore}
    </Typography>
  );
};

const renderTeamName = (match, team, primaryScore, secondaryScore, classes) => {
  const shouldHightLight = highlightPrimary(primaryScore, secondaryScore);
  const shouldStrikeThrough = team.includes(match.eliminated_side);

  return (
    <React.Fragment>
      <Typography
        component="span"
        className={classes.teamName}
        style={{
          fontWeight: shouldHightLight ? 'normal' : 'lighter',
          textDecoration: shouldStrikeThrough ? 'line-through' : 'none',
        }}
      >
        {match[team].name} {match[team].age_group ? `U${match[team].age_group}` : ''}{' '}
        {match[team].sex === 'female' ? '(W)' : ''}
      </Typography>
      <RedCards match={match} team={team} />
    </React.Fragment>
  );
};

const MatchEntityListItem = ({ match, onItemClick, classes }) => {
  const [homeScore, awayScore] =
    match.score && match.score.current ? match.score.current : [null, null];

  return (
    <ListItem
      button
      onClick={() => onItemClick(match.id, 'match')}
      style={{
        backgroundColor: match.deleted ? '#ffcccc' : '#ffffff',
        height: 60,
      }}
    >
      <MatchEntityListItemAvatar match={match} />
      <ListItemText style={{ paddingRight: 0, paddingLeft: 16 }}>
        <MatchScoreName classes={classes}>
          {renderScore(classes, homeScore, awayScore)}
          {renderTeamName(match, 'home_team', homeScore, awayScore, classes)}
        </MatchScoreName>
        <MatchScoreName classes={classes}>
          {renderScore(classes, awayScore, homeScore)}
          {renderTeamName(match, 'away_team', awayScore, homeScore, classes)}
        </MatchScoreName>
      </ListItemText>
    </ListItem>
  );
};

export default withStyles(styles)(MatchEntityListItem);
