import isEqual from 'lodash/isEqual';
import constants from '../../utils/constants';

/**
 *
 * Description.
 * It builds the primary text label for the given entitytype and entity
 * @param {string} entityType.
 * @param {Object} entity.
 * @returns {string} primary text for the suggestion result
 */
export const getSuggestionPrimaryText = (entityType, entity) => {
  const getPlayerName = (player) => {
    let name = '';
    if (player.first_name || player.last_name) {
      name += `${player.first_name || ''} ${player.last_name || ''}`;
    } else if (player.nickname) {
      name += player.nickname;
    }
    return name;
  };

  const getManagerName = (manager) => {
    let name = '';
    if (manager.full_name) {
      if(manager.full_name.length === 2) {
        name += `${manager.full_name[0] || ''} ${manager.full_name[1] || ''}`;
      }
      else if(manager.full_name.length === 1) {
        name += `${manager.full_name[0] || ''}`;
      }
      else {
        name += `${manager.full_name || ''}`;
      }
    } else if (manager.nickname) {
      name += manager.nickname;
    }
    return name;
  };

  switch (entityType) {
    case 'manager':
      return getManagerName(entity);

    case 'player':
      return getPlayerName(entity);

    case 'team':
    case 'tournament':
      return `${entity.name} ${entity.age_group ? `U${entity.age_group}` : ''}`;

    case 'season':
    case 'stadium':
    case 'region':
      return `${entity.name}`;

    default:
      return '';
  }
};

/**
 *
 * Description.
 * It builds the secondary text label used in Suggestion result component,
 * for the given entitytype and entity
 * @param {string} entityType.
 * @param {Object} entity.
 * @returns {string} secondary text for the suggestion result
 */
export const getSuggestionSecondaryText = (entityType, entity) => {
  const getTeamNames = teams => teams.map(team => `${team.name}`).join(', ');
  const getRegionName = () => (entity.region ? entity.region.name : '');

  let text = '';
  switch (entityType) {
    case 'player':
      text = entity.teams.length > 0 ? getTeamNames(entity.teams) : getRegionName();
      text = entity.sex ? (`${text} ${text ? '|' : ''} ${entity.sex}`) : text;
      text = entity.birthdate ? (`${text} ${text ? '|' : ''} ${entity.birthdate}`) : text;
      break;

    case 'tournament':
    case 'team':
      text = getRegionName() || text;
      text = entity.sex ? (`${text} | ${entity.sex}`) : text;
      break;

    case 'season':
      text = entity.tournament ? entity.tournament.name : text;
      text = entity.starts_on ? (`${text} | starts_on: ${entity.starts_on}`) : text;
      text = entity.ends_on ? (`${text} | ends_on: ${entity.ends_on}`) : text;
      break;

    case 'stadium':
      text = getRegionName() || text;
      text = entity.capacity ? (`${text} | ${entity.capacity}`) : text;
      text = entity.coordinates ? (`${text} | ${entity.coordinates}`) : text;
      break;

    case 'manager':
      text = getRegionName() || text;
      text = entity.sex ? (`${text} ${text ? '|' : ''} ${entity.sex}`) : text;
      text = entity.birthdate ? (`${text} ${text ? '|' : ''} ${entity.birthdate}`) : text;
      break;

    case 'region':
      text = entity.country_code ? (`${text} | ${entity.country_code}`) : text;
      break;

    default:
      break;
  }
  return text;
};

/**
 *
 * Description.
 * Builds the thumbnail image uri for the given entitytype and entityid.
 * Used in Suggestions result list item Avatars
 * @param {string} entityType
 * @param {number} entityId
 * @returns {string}
 */
export const getThumbnailImgUri = (entityType, entityId) => {
  const uri = constants.images.image_base_url;
  switch (entityType) {
    case 'team':
      return `${uri}badges/team/thumbnail/${entityId}.png`;

    case 'player':
      return `${uri}photos/player/thumbnail/${entityId}.jpg`;

    case 'region':
    case 'tournament':
      return `${uri}flags/region/thumbnail/${entityId}.png`;

    default:
      return `https://via.placeholder.com/150/?Text=${entityType}`;
  }
};

export const filterSuggestionByName = (filterTerm) => ({ details: entity }) => {
  const fName = entity.first_name ? entity.first_name : '';
  const lName = entity.last_name ? entity.last_name : '';
  const nickName = entity.nickname ? entity.nickname : '';
  const name = entity.name ? entity.name : '';

  const valuingText = `${fName} ${lName} ${nickName} ${name}`;
  return valuingText.toLowerCase().includes(filterTerm.toLowerCase());
};

export /**
 *
 * Description
 * Compares two objects and returns the difference, uses deep object comparison
 * used to show the diff between task and target suggestion
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Array}
 */
const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1)
    .reduce((result, key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2))
    .filter((el) => {
      // Filter attributes which are not present in either task or target entity
      if (!!obj1[el] && !!obj2[el] && !Array.isArray(obj1[el])) {
        return el;
      }
      // For array elements like tournaments,
      // they will be present in the object, but the length could be 0
      if (Array.isArray(obj1[el]) && obj1[el].length > 0 && Array.isArray(obj2[el]) && obj2[el].length > 0) {
        return el;
      }
      return null;
    });

  return diff;
};
