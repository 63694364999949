import React from 'react';
import VisualSetupSection from '../components/VisualSetupSection';
import constants from '../../../utils/constants';

const TournamentVisualSetup = ({ tournament }) => (
  <React.Fragment>
    <VisualSetupSection
      entityType="tournament"
      entityId={tournament.id}
      sectionTitle="Tournament Background Full"
      images={['background_full']}
      defaultExpand
      useCroppie={false}
      uploadSize={{
        width: constants.images.tournament.background_full.uploadWidth,
        height: constants.images.tournament.background_full.uploadHeight,
      }}
    />
  </React.Fragment>
);

export default TournamentVisualSetup;
