import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MatchIcon = props => (
  <SvgIcon {...props}>
    <path d="M12.9834024,19.9401612 C15.1178421,19.6785376 16.9923446,18.5762816 18.2656616,16.9746417 C17.9834736,16.9910534 17.7668491,16.9976961 17.676303,17 C17.5188727,15.0317635 17.0588121,13.7141567 17,13.547288 C18.7961333,12.1423098 19.6727515,11.1658166 19.8216121,11 C19.8635691,11.0225981 19.9051989,11.0458136 19.9464762,11.0695789 C19.7649038,9.50185183 19.1301245,8.07227947 18.1765459,6.91526949 C18.1588614,6.94458575 18.1416663,6.97287955 18.1250648,7 C16.7436632,6.14083714 14.5650703,5.68276872 14.2963381,5.62883821 L14.2935212,5.62157038 C14.095882,5.05612171 13.8743364,4.56895559 13.6697532,4.17450594 C13.1312064,4.06016049 12.5726302,4 12,4 C7.58165,4 4,7.58165 4,12 C4,14.8780721 5.5197293,17.4011241 7.80050713,18.8104753 C8.34252603,18.5670191 9.00141469,18.2464738 9.30901125,18.001568 L9.310086,18 C9.52225257,18.1012887 11.0986703,18.8353669 12.9892765,19.0932824 C12.9941462,19.1779996 13.0137504,19.5479402 12.9834024,19.9401612 Z M21.9679049,12.8051616 C21.9803499,12.8194126 21.9910684,12.8317135 22,12.8419027 L21.963896,12.8537626 C21.5293659,17.9698506 17.2266636,22 12,22 C6.48578889,22 2,17.5142111 2,12 C2,6.48578889 6.48578889,2 12,2 C17.5142111,2 22,6.48578889 22,12 C22,12.2710081 21.9891648,12.5395321 21.9679049,12.8051616 Z M13,12.281346 L9.50829139,15 C9.20067845,14.7978465 6.79364519,13.2495516 6.1392906,12.4841053 L6,12.4763009 L6,12.4745072 C6.59271559,12.1541716 6.77586265,9.30833128 7.42489555,8.23574671 L7.56181039,8.22915397 L7.57307148,8.22675446 C8.2695126,8.08038386 9.17756234,8 10.1306445,8 C10.6700244,8 11.1999131,8.02698864 11.6634113,8.07737852 C11.7683247,8.38451683 12.6657073,11.0185937 13,12.281346 Z" />
  </SvgIcon>
);

export default MatchIcon;
