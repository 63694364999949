import React, { Component } from 'react';

import {
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
} from '@material-ui/core';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

class MatchEventHeader extends Component {
  state = {};

  render() {
    const { match } = this.props;
    return (
      <div>
        <ListItem>
          <ListItemText secondary="Teams" />
        </ListItem>
        <ListItem style={{ minHeight: 64 }}>
          <div style={{ flex: 1, display: 'flex' }}>
            <div
              style={{
                flex: '0 0 50%',
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'flex-end',
                paddingRight: 60,
              }}
            >
              <Avatar>H</Avatar>
              <ListItemText style={{ padding: 0, paddingTop: 8 }} align="right">
                <Typography>{match.home_team.name}</Typography>
              </ListItemText>
            </div>
            <div
              style={{
                flex: '0 0 50%',
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'flex-start',
                paddingLeft: 60,
              }}
            >
              <Avatar>A</Avatar>
              <ListItemText style={{ padding: 0, paddingTop: 8 }} align="left">
                <Typography>{match.away_team.name}</Typography>
              </ListItemText>
            </div>
          </div>
          <ListItemSecondaryAction>
            <IconButton>{this.state.expanded ? <ArrowDropDown /> : <ArrowDropUp />}</IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  }
}

export default MatchEventHeader;
