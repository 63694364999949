import React from 'react';
import { ListSubheader, Divider, withStyles } from '@material-ui/core';
import constants from '../../../utils/constants';
import MatchEntitySearchResults from '../matches/MatchEntitySearchResults';

const styles = () => ({
  'search-result-container': {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
});
const EntitySearchResults = ({
  entities = [],
  EntityListItem,
  onItemClick,
  entityType,
  loading,
  classes,
}) => (
  <div className={classes['search-result-container']}>
    {entities.length === 0 && !loading && <ListSubheader>No results found</ListSubheader>}
    {entityType === constants.entityType.match ? (
      // Matches should render nested
      <MatchEntitySearchResults matches={entities} onItemClick={onItemClick} />
    ) : (
      entities.map(entity => (
        <React.Fragment key={entity.id}>
          <EntityListItem
            // previousEntity={entities[index - 1] || {}}
            entity={entity}
            onClick={() => onItemClick(entity.id || entity, entityType)}
          />
          <Divider />
        </React.Fragment>
      ))
    )}
  </div>
);

export default withStyles(styles)(EntitySearchResults);
