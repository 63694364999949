import React from 'react';
import { connect } from 'react-redux';
import { List, Divider } from '@material-ui/core';
import Backend from '../../../services/backend';
import MatchEntityListItem from './MatchEntityListItem';

const MatchEntitySearchResults = ({ matches = [], pinnedMatches, onItemClick, onPinClick }) => (
  <div>
    <List disablePadding>
      {matches
        .sort((match1, match2) => (match1.kickoff_at > match2.kickoff_at ? 1 : -1))
        .sort(match => (match.status === 'live' ? -1 : 0))
        .map(match => (
          <div key={match.id}>
            <Divider />
            <MatchEntityListItem
              match={match}
              pinned={pinnedMatches[match.id]}
              onItemClick={onItemClick}
              onPinClick={onPinClick}
            />
          </div>
        ))}
    </List>
    <Divider />
  </div>
);

export default connect(
  state => ({ pinnedMatches: state.entities.pinnedMatches }),
  dispatch => ({
    onPinClick: ({ matchId, pinned }) =>
      dispatch({
        type: 'TOGGLE_PIN_MATCH',
        payload: Backend.togglePinMatch({ matchId, pinned }),
      }),
  }),
)(MatchEntitySearchResults);
