import { useState, useEffect } from 'react';
import Backend from '../../../services/backend';

const useTaskEntitiesSearch = (entityType, searchTerm) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const searchEntities = async () => {
      setIsLoading(true);
      try {
        const searchResponse = await Backend.searchEntities(entityType, searchTerm);
        setSearchResults(searchResponse);
        setIsError(false);
      } catch (er) {
        setIsError(true);
        setSearchResults([]);
      } finally {
        setIsLoading(false);
      }
    };
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }
    if (searchTerm.length >= 3) {
      searchEntities();
    }
  }, [searchTerm]);

  return [isLoading, isError, searchResults];
};

export default useTaskEntitiesSearch;
