import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, IconButton } from '@material-ui/core';

class UserMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { onLogout, ...rest } = this.props;
    return (
      <div {...rest}>
        <IconButton onClick={this.handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="UserInfoCard-usermenu"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <MenuItem component={RouterLink} to="/account-settings">
            Settings
          </MenuItem>
          <MenuItem disabled>Help</MenuItem>
          <MenuItem onClick={onLogout}>Log out</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default UserMenu;
