export const getReducedEntityObject = entity =>
  entity && entity.reduce((accum, entry) => {
    if (entry.type.array && entry.type.array.assoc) {
      return {
        ...accum,
        // eslint-disable-next-line no-use-before-define
        [entry.key]: getReducedEntityObjectList(entry.value),
      };
    } else if (entry.type.assoc) {
      return {
        ...accum,
        [entry.key]: getReducedEntityObject(entry.value),
      };
    }
    return {
      ...accum,
      [entry.key]: entry.value,
    };
  }, {});

export const getReducedEntityObjectList = entityList =>
  entityList && entityList.map(entity => getReducedEntityObject(entity));

export const getReducedEntityTypes = entity =>
  entity &&
  entity.reduce((accum, {
    key,
    type,
    nullable,
  }) => ({
    ...accum,
    [key]: {
      type,
      nullable,
    },
  }), {});
