import { connect } from 'react-redux';
import EditEntity from '../components/EntityEditing/EditEntity';

const mapStateToProps = state => ({
  loadingOverwrites: state.playerOverwrites.loading,
  writing: state.playerOverwrites.writing,
  overwrites: state.playerOverwrites.overwrites,
  writeError: state.playerOverwrites.writeError,
  fetchOverwriteError: state.playerOverwrites.error,
  currentEdits: state.edits.currentEdits,
  editsDirty: state.edits.dirty,
});

export default connect(mapStateToProps)(EditEntity);
