import React from 'react';
import { ListItem } from '@material-ui/core';
import ResultListItemText from '../components/ResultListItemText';
import ResultListItemAvatar from '../components/ResultListItemAvatar';

const StadiumEntityListItem = ({ entity, onClick }) => (
  <ListItem button onClick={onClick} style={{ height: 60 }}>
    <ResultListItemAvatar entityId={entity.id} entityType="stadium" imgKey="" />
    <ResultListItemText primary={entity.name} secondary="" />
  </ListItem>
);

export default StadiumEntityListItem;
