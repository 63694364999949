import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import SaveIcon from '@material-ui/icons/Save';
import EntityCard from '../../entities/components/EntityCard';
import EntityCardHeader from '../../entities/components/EntityCardHeader';
import EntityCardContent from '../../entities/components/EntityCardContent';
import EntityCardTab from '../../entities/components/EntityCardTab';
import './AccountSettings.css';
import Backend from '../../../services/backend';

const AccountPage = ({ onLogout }) => {
  const [username, setUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Runs only once when the component is mounted
  useEffect(() => {
    const uname = localStorage.getItem('user') ? localStorage.getItem('user') : '';
    setUsername(uname);
  }, []);

  // Runs when either of Current/New Password value changes
  useEffect(() => {
    if (currentPassword && newPassword && newPassword.length >= 10) {
      setEnableSaveBtn(true);
    } else {
      setEnableSaveBtn(false);
    }
  }, [currentPassword, newPassword]);

  const validatePassword = (password) => {
    // TODO: Add other validations once the api adds them on backend
    let isValid = false;
    if (password && password.length >= 10) {
      isValid = true;
    }
    return isValid;
  };

  const changePassword = async () => {
    try {
      setIsLoading(true);
      const response = await Backend.changePassword(currentPassword, newPassword);
      // TODO: Change this condition once backend is fixed
      if (response || response === '') {
        setIsLoading(false);
        setEnableSaveBtn(false);
        onLogout();
      }
    } catch (error) {
      setIsLoading(false);
      setEnableSaveBtn(false);
      throw error;
    }
  };

  const handleChange = (e) => {
    const value = e.target ? e.target.value : '';
    if (e.target.id === 'currentPassword') {
      setCurrentPassword(value);
    } else {
      setNewPassword(value);
    }
  };

  const inputBlurHandler = () => {
    if (validatePassword(newPassword)) {
      setIsError(false);
      setEnableSaveBtn(true);
    } else {
      setEnableSaveBtn(false);
      setIsError(true);
    }
  };

  const handleSubmit = () => {
    const isPasswordValid = validatePassword(newPassword);
    if (isPasswordValid) {
      changePassword();
    }
    return false;
  };

  return (
    <EntityCard>
      <EntityCardHeader primary={<span>Account Settings</span>} />
      <EntityCardContent tabs={['information']}>
        <EntityCardTab tab="information">
          <div className="account-settings">
            <form noValidate autoComplete="off">
              <TextField id="username" label="Username" value={username} margin="normal" disabled />
              <TextField
                type="password"
                id="currentPassword"
                label="Current Password"
                value={currentPassword}
                onChange={handleChange}
                margin="normal"
              />
              <TextField
                type="password"
                id="newPassword"
                label="New Password"
                value={newPassword}
                onChange={handleChange}
                margin="normal"
                error={isError}
                helperText={
                  isError
                    ? 'Password must be atleast 10 characters long'
                    : 'Min length: 10 charaters'
                }
                onBlur={inputBlurHandler}
                onFocus={() => setIsError(false)}
              />
            </form>
          </div>
          {isLoading && (
            <div className="loader-wrapper">
              <LinearProgress />
            </div>
          )}
          <div className="btnContainer">
            <Fab
              style={{ minWidth: 56, marginRight: 10 }}
              disabled={!enableSaveBtn}
              color="primary"
              aria-label="Save"
              onClick={handleSubmit}
            >
              <SaveIcon />
            </Fab>
          </div>
        </EntityCardTab>
      </EntityCardContent>
    </EntityCard>
  );
};
export default withRouter(AccountPage);
