const initialState = {
  pendingTaskCount: 0,
  unverifiedTaskCount: 0,
  verifiedPendingTaskCount: 0,
  rejectedTaskCount: 0,
  pendingTask: {},
  unverifiedTask: {},
  pendingLoading: false,
  unverifiedLoading: false,
  pendingError: false,
  unverifiedError: false,
};

const tasks = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FETCH_PENDING_TASK_COUNT_FULFILLED':
      return {
        ...state,
        pendingTaskCount: action.payload,
      };
    case 'FETCH_UNVERIFIED_TASK_COUNT_FULFILLED':
      return {
        ...state,
        unverifiedTaskCount: action.payload,
      };
    case 'FETCH_VERIFIED_PENDING_TASK_COUNT_FULFILLED':
      return {
        ...state,
        verifiedPendingTaskCount: action.payload,
      };
    case 'FETCH_REJECTED_TASK_COUNT_FULFILLED':
      return {
        ...state,
        rejectedTaskCount: action.payload,
      };
    case 'FETCH_PENDING_TASK_PENDING':
      return {
        ...state,
        pendingTask: {},
        pendingLoading: true,
        pendingError: false,
      };
    case 'FETCH_UNVERIFIED_TASK_PENDING':
      return {
        ...state,
        unverifiedTask: {},
        unverifiedLoading: true,
        unverifiedError: false,
      };
    case 'FETCH_PENDING_TASK_FULFILLED':
      return {
        ...state,
        pendingTask: action.payload,
        pendingLoading: false,
        pendingError: false,
      };
    case 'FETCH_UNVERIFIED_TASK_FULFILLED':
      return {
        ...state,
        unverifiedTask: action.payload,
        unverifiedLoading: false,
        unverifiedError: false,
      };
    case 'FETCH_PENDING_TASK_REJECTED':
      return {
        ...state,
        pendingTask: {},
        pendingLoading: false,
        pendingError: true,
      };
    case 'FETCH_UNVERIFIED_TASK_REJECTED':
      return {
        ...state,
        unverifiedTask: {},
        unverifiedLoading: false,
        unverifiedError: true,
      };

    default:
      return state;
  }
};

export default tasks;
