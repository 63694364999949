import React, { Component } from 'react';

import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { parseStatusArrayToString } from './editHelper';

class RestoreValuePopover extends Component {
  state = { anchorEl: null };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const { property, value, onRestoreOverwritten } = this.props;
    const { anchorEl } = this.state;
    const open = !!anchorEl;

    const formatValue = (val) => {
      if (Array.isArray(val)) {
        return parseStatusArrayToString(val);
      }
      return String(val);
    };

    return (
      <div>
        <Tooltip title="Overwritten property">
          <IconButton style={{ color: '#ffd200' }} onClick={this.handleOpen}>
            <WarningIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List dense disablePadding>
            <ListItem style={{ backgroundColor: '#006189', color: '#fff' }}>
              <ListItemText disableTypography>
                <Typography color="inherit">RESTORE OVERWRITTEN PROPERTY</Typography>
              </ListItemText>
            </ListItem>
            <ListItem button onClick={onRestoreOverwritten}>
              <ListItemText primary={formatValue(value)} secondary={property} />
            </ListItem>
          </List>
        </Popover>
      </div>
    );
  }
}

export default RestoreValuePopover;
