import constants from './constants';

const getAppDimensions = () => {
  let mode;
  const width = window.innerWidth;
  if (width < 765) {
    mode = constants.mode.mobile;
  } else if (width < 1000) {
    mode = constants.mode.tablet;
  } else {
    mode = constants.mode.laptop;
  }
  return mode;
};

export default getAppDimensions;
