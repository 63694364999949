import { useState } from 'react';

const useGenericStatus = ({ isLoading = false }) => {
  const [loading, setLoading] = useState(isLoading);
  const [error, setError] = useState(null);

  return [loading, error, setLoading, setError];
};

export default useGenericStatus;
