import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProviderPopover from '../../../components/ProviderPopover';
import { fetchEntityProviders } from '../../../redux/actions';

class EntityProviderPopopver extends Component {
  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { entityType, entityId, fetchEntityProviders } = this.props;
    if (entityId) {
      fetchEntityProviders(entityType, entityId);
    }
  }
  render() {
    const { availableProviders, entityType } = this.props;
    return (
      <ProviderPopover
        providers={availableProviders[entityType] && availableProviders[entityType].providers}
        entityType={entityType}
      />
    );
  }
}

export default connect(
  state => ({ availableProviders: state.availableProviders }),
  dispatch => ({
    fetchEntityProviders: (entityType, entityId) =>
      dispatch(fetchEntityProviders(entityType, entityId)),
  }),
)(EntityProviderPopopver);
