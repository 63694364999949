import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = {
  alert: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: '',
    padding: '4px 8px',
    borderRadius: '5px',
  },
};
const LoadingErrorAlert = ({ classes, message }) => <p className={classes.alert}>{message}</p>;

export default withStyles(styles)(LoadingErrorAlert);
