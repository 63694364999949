import React from 'react';
import { ListItem } from '@material-ui/core';
import ResultListItemText from '../components/ResultListItemText';
import ResultListItemAvatar from '../components/ResultListItemAvatar';

const RegionEntityListItem = ({ entity, onClick }) => (
  <ListItem button onClick={onClick} style={{ height: 60 }}>
    <ResultListItemAvatar entityId={entity.id} entityType="region" imgKey="flag_thumbnail" />
    <ResultListItemText primary={entity.name} secondary="" />
  </ListItem>
);

export default RegionEntityListItem;
