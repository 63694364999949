import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import { overwriteAndReloadEntity, fetchEntityAndOverwrites, selectEntity } from '../../../redux/actions';
import { closePrimaryDrawer } from '../../../redux/actions/drawer';
import constants from '../../../utils/constants';
import SeasonCard from './SeasonCard';

const SeasonCardContainer = ({ match, getEntity, ...rest }) => {
  // Match is a router prop, not the football match :P
  const { seasonId } = match.params;
  useEffect(() => {
    getEntity(seasonId, constants.entityType.season);
  }, [seasonId]);

  // Don't show the drawer for season cards
  useEffect(() => {
    closePrimaryDrawer();
  });

  return <SeasonCard {...rest} />;
};

const mapStateToProps = (state) => {
  const isLoadingOrError = state.seasonOverwrites.loading || state.seasonOverwrites.writing;
  const arePropertiesEdited = Object.values(state.edits.currentEdits).filter((value) => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    season: getReducedEntityObject(state.entities.selectedSeason),
    attributeTypes: getReducedEntityTypes(state.entities.selectedSeason),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (seasonId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('season', seasonId, currentEdits)),
  fetchEntityAndOverwrites: (seasonId) => dispatch(fetchEntityAndOverwrites('season', seasonId)),
  closePrimaryDrawer: () => dispatch(closePrimaryDrawer()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SeasonCardContainer)
);
