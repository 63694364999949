export const simpleTypes = ['integer', 'string', 'float', 'utc_datetime', 'date', 'boolean'];
export const isSimpleProperty = ([, propType]) =>
  propType.type.tuple ||
  propType.type.enum ||
  propType.type.type_enum ||
  simpleTypes.indexOf(propType.type.value) !== -1;

export const isColorProperty = ([, propType]) =>
  propType.type && propType.type.value && propType.type.value === 'color';
export const isArrayProperty = ([, propType]) => propType.type && propType.type.array;

export const categoriseProperties = (attributeTypes) => {
  const entries = Object.entries(attributeTypes);
  const simpleProperties = entries.filter(isSimpleProperty);
  const arrayProperties = entries.filter(isArrayProperty);
  const colorProperties = entries.filter(isColorProperty);
  const objectProperties = entries.filter(prop => !isSimpleProperty(prop) && !isArrayProperty(prop) && !isColorProperty(prop));

  return {
    simplePropNames: simpleProperties.map(([prop]) => prop),
    arrayPropNames: arrayProperties.map(([prop]) => prop),
    colorPropNames: colorProperties.map(([prop]) => prop),
    objectPropNames: objectProperties.map(([prop]) => prop),
  };
};

export const parseStringToValue = (type, value) => {
  if (value === null || value === undefined || value === '') {
    return null;
  } else if (type.value) {
    return parseStringToValue(type.value, value);
  } else if (type.tuple) {
    // eslint-disable-next-line no-use-before-define
    return parseTuple(type.tuple, value);
  } else if (type === 'integer') {
    const num = Number.parseInt(value, 10);
    if (!Number.isNaN(num)) {
      return num;
    }
    return 0;
  } else if (type === 'string') {
    return value.trim();
  }
  return value;
};

export const parseTuple = (tupleType, string) => {
  const [a, b] = string.split(',');
  const result = [parseStringToValue(tupleType, a), parseStringToValue(tupleType, b)];
  return result;
};

export const parseStatusArrayToString = (statusValueArray) => {
  const formatVal = statusValueArray.filter(el => el);
  return formatVal.length > 1 ? formatVal.join('-') : formatVal.join();
};
