import Backend from '../../services/backend';

export {
  fetchTaskCount,
  fetchAllTaskCount,
  selectTask,
  fetchPendingTask,
  fetchPendingSuggestions,
  confirmPendingTask,
  confirmPendingTaskAndFetchNext,
  fetchUnverifiedTask,
  confirmUnverifiedTask,
  confirmUnverifiedTaskAndFetchNext,
  fetchUnverifiedSuggestions,
  fetchTasks,
} from './tasks';

export const fetchMatchEvents = matchId => ({
  type: 'FETCH_MATCH_EVENTS',
  payload: Backend.fetchMatchEvents(matchId),
});

export const selectEntity = (entityId, entityType) => (dispatch) => {
  dispatch(fetchEntityAndOverwrites(entityType, entityId));
  if (entityType === 'match') {
    dispatch(fetchMatchEvents(entityId));
  }
};

export const onSavePlayer = playerProperties => (dispatch) => {
  dispatch({
    type: 'OVERWRITE_PLAYER',
    payload: Backend.savePlayer(playerProperties),
  }).then(() => dispatch(selectEntity(playerProperties.id, 'player')));
};

export const fetchEntityOverwrites = (entityType, id) => ({
  type: `FETCH_${String(entityType).toUpperCase()}_OVERWRITES`,
  payload: Backend.fetchEntityOverwrites(entityType, id),
});

export const fetchEntityAndOverwrites = (entityType, id) => dispatch =>
  dispatch({
    type: `SELECT_${String(entityType).toUpperCase()}`,
    payload: Backend.fetchEntity(id, entityType),
  }).then(() => dispatch(fetchEntityOverwrites(entityType, id)));

export const overwriteAndReloadEntity = (entityType, id, properties) => dispatch =>
  dispatch({
    type: `OVERWRITE_${String(entityType).toUpperCase()}`,
    payload: Backend.overwriteEntity(entityType, id, properties),
  }).then(() => dispatch(fetchEntityAndOverwrites(entityType, id)));

export const fetchEntityProviders = (entityType, entityId) => ({
  type: `FETCH_${String(entityType).toUpperCase()}_PROVIDERS`,
  payload: Backend.fetchEntityProviders(entityType, entityId),
});

export const fetchEntityProviderNames = (entityType, entityId) => ({
  type: `FETCH_${String(entityType).toUpperCase()}_PROVIDER_NAMES`,
  payload: Backend.fetchEntityProviderNames(entityType, entityId),
});

export const fetchSelectedEntityProvider = (entityType, entityId) => ({
  type: `FETCH_${String(entityType).toUpperCase()}_PROVIDER`,
  payload: Backend.fetchSelectedEntityProvider(entityType, entityId),
});

export const fetchSelectedProviderAndProviderNames = (entityType, entityId) => dispatch =>
  dispatch(fetchEntityProviderNames(entityType, entityId)).then(() =>
    dispatch(fetchSelectedEntityProvider(entityType, entityId)));

export const selectEntityProvider = (entityType, entityId, provider) => ({
  type: `SELECT_${String(entityType).toUpperCase()}_PROVIDER`,
  payload: Backend.selectEntityProvider(entityType, entityId, provider),
});

export const selectAndFetchEntityProviderNames = (entityType, entityId, provider) => dispatch =>
  dispatch(selectEntityProvider(entityType, entityId, provider)).then(() =>
    dispatch(fetchSelectedProviderAndProviderNames(entityType, entityId)));

export const setEntityEdits = edits => ({
  type: 'SET_ENTITY_EDITS',
  payload: edits,
});

export const setEditsDirty = dirty => ({
  type: 'SET_EDITS_DIRTY',
  payload: dirty,
});

export const resetEntityEdits = () => ({
  type: 'RESET_ENTITY_EDITS',
});

export const setMatchEventDeleted = (eventType, id, deleted) => dispatch =>
  dispatch({
    type: 'SET_MATCH_EVENT_DELETED',
    payload: Backend.setMatchEventDeleted(eventType, id, deleted),
  });

export const closeAlert = dispatch =>
  dispatch({
    type: 'CLOSE_ALERT',
  });
