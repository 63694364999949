import React from 'react';
import { ListItem } from '@material-ui/core';
import ResultListItemText from '../components/ResultListItemText';
import ResultListItemAvatar from '../components/ResultListItemAvatar';
import { getSuggestionPrimaryText, getSuggestionSecondaryText } from '../../tasks/helper';

const PlayerEntityListItem = ({ entity, ...rest }) => (
  <ListItem {...rest} button style={{ height: 60 }}>
    <ResultListItemAvatar entityId={entity.id} entityType="player" imgKey="photo_thumbnail" />
    <ResultListItemText
      primary={getSuggestionPrimaryText('player', entity)}
      secondary={getSuggestionSecondaryText('player', entity)}
    />
  </ListItem>
);

export default PlayerEntityListItem;
