import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DelayedIcon = props => (
  <SvgIcon {...props} viewBox="0,0,38,38">
    <path d="M25.1,26.1L18.3,19.4l0.3,-0.3l-0.3,0l0,-16l1.6,0l0,15.7l6.2,6.3l-1,1Zm-6,10.6c9.7,0,17.6,-7.9,17.6,-17.6C36.7,9.4,28.8,1.5,19.1,1.5C9.4,1.5,1.5,9.4,1.5,19.1c0,9.7,7.9,17.6,17.6,17.6Zm0,1.5C8.6,38.2,0,29.6,0,19.1C0,8.6,8.6,0,19.1,0C29.6,0,38.2,8.6,38.2,19.1c0,10.5,-8.6,19.1,-19.1,19.1Z" />
  </SvgIcon>
);

export default DelayedIcon;
