import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ThumbDownIcon from '@material-ui/icons/ThumbDownAlt';
import Tooltip from '@material-ui/core/Tooltip';
import SkipIcon from '@material-ui/icons/SkipNext';
import Layout from './TaskPageLayout';

const styles = {
  fab: {
    marginLeft: 12,
    background: 'white',
  },
  reject: {
    '&:hover': {
      backgroundColor: '#f44336',
      color: '#fff',
    },
  },
  accept: {
    '&:hover': {
      backgroundColor: '#43a047',
      color: '#fff',
    },
  },
};

const TaskButtons = ({ classes, editsDirty, onTaskAction }) => (
  <Layout.TaskBtnContainer>
    <Tooltip title="Skip">
      <Fab
        aria-label="Skip"
        size="small"
        tabIndex="0"
        className={classes.fab}
        onClick={onTaskAction('skip')}
      >
        <SkipIcon />
      </Fab>
    </Tooltip>
    <Tooltip title="Reject">
      <Fab
        aria-label="Reject"
        size="small"
        tabIndex="0"
        className={classNames(classes.fab, classes.reject)}
        onClick={onTaskAction('reject')}
        disabled={editsDirty}
      >
        <ThumbDownIcon />
      </Fab>
    </Tooltip>
    <Tooltip title="Accept">
      <Fab
        aria-label="Accept"
        size="small"
        tabIndex="0"
        className={classNames(classes.fab, classes.accept)}
        onClick={onTaskAction('accept')}
        disabled={editsDirty}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  </Layout.TaskBtnContainer>
);

TaskButtons.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  editsDirty: PropTypes.bool.isRequired,
  onTaskAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(TaskButtons);
