import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const WhistleIcon = props => (
  <SvgIcon {...props}>
    <path d="M8.5 9a6.5 6.5 0 1 0 6.5 6.5v-1.59L22 12V9H11v2H9V9h-.5zM11 2v5H9V2h2zM6.35 7.28c-.67.16-1.31.4-1.92.72L2.14 4.88 3.76 3.7l2.59 3.58zm11.51-2.4L16.32 7h-2.47l2.39-3.3 1.62 1.18z" />
  </SvgIcon>
);

export default WhistleIcon;
