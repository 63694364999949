// App Primary & Secondary Drawers
export const OPEN_PRIMARY_DRAWER = 'OPEN_PRIMARY_DRAWER';
export const CLOSE_PRIMARY_DRAWER = 'CLOSE_PRIMARY_DRAWER';
export const TOGGLE_PRIMARY_DRAWER = 'TOGGLE_PRIMARY_DRAWER';

export const OPEN_SECONDARY_DRAWER = 'OPEN_SECONDARY_DRAWER';
export const CLOSE_SECONDARY_DRAWER = 'CLOSE_SECONDARY_DRAWER';
export const TOGGLE_SECONDARY_DRAWER = 'TOGGLE_SECONDARY_DRAWER';

// Window Size Actions
export const SET_APP_WINDOW_MODE = 'SET_APP_WINDOW_MODE';
