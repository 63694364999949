import React from 'react';
import { connect } from 'react-redux';
import PlayerIcon from '@material-ui/icons/Person';
import TeamIcon from '@material-ui/icons/Group';
import { getReducedEntityObjectList } from '../../../redux/reducers';
import constants from '../../../utils/constants';
import PlayerEntityListItem from './PlayerEntityListItem';
import EntitySearch from '../components/EntitiySearch';

const PlayerSearch = connect(state => ({
  entities: getReducedEntityObjectList(state.entities.players),
}))(props => (
  <EntitySearch
    {...{
      ...props,
      title: 'Players',
      entityType: constants.entityType.player,
      EntityListItem: PlayerEntityListItem,
      searches: [
        {
          search: 'name',
          leftIcon: <PlayerIcon />,
          hintText: 'Player',
        },
        {
          search: 'team',
          leftIcon: <TeamIcon />,
          hintText: 'Team',
        },
      ],
    }}
  />
));

export default PlayerSearch;
