import React, { Component } from 'react';
import { connect } from 'react-redux';

import { List, CircularProgress, Typography, Divider, Paper } from '@material-ui/core';

import { fetchMatchEvents } from '../../../../redux/actions';
import MatchEventHeader from './MatchEventHeader';
import Period from './Period';

const mapStateToProps = state => ({
  periods: state.matchEvents.periods,
  loading: state.matchEvents.loading,
  error: state.matchEvents.error,
});

class MatchEvents extends Component {
  componentDidMount() {
    const { match, dispatch } = this.props;
    dispatch(fetchMatchEvents(match.id));
  }

  render() {
    const {
      match, periods, loading, error, dispatch,
    } = this.props;
    // console.log(matchEvents);
    return (
      <Paper>
        <List>
          <MatchEventHeader match={match} />
          <Divider />
          {loading && <CircularProgress />}
          {error && <Typography>Could not fetch events.</Typography>}
          {!loading &&
            !error &&
            periods.map(period => (
              <Period
                period={period}
                key={period.type}
                onEventEdited={() => dispatch(fetchMatchEvents(match.id))}
              />
            ))}
        </List>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(MatchEvents);
