import { connect } from 'react-redux';
import EditEntity from '../components/EntityEditing/EditEntity';

const mapStateToProps = state => ({
  loadingOverwrites: state.regionOverwrites.loading,
  writing: state.regionOverwrites.writing,
  overwrites: state.regionOverwrites.overwrites,
  writeError: state.regionOverwrites.writeError,
  fetchOverwriteError: state.regionOverwrites.error,
  currentEdits: state.edits.currentEdits,
  editsDirty: state.edits.dirty,
});

export default connect(mapStateToProps)(EditEntity);
