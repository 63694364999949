// TODO: This works now, but really needs a rewrite
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Transform from './Transform';
import StyleRelated from './styleStuff';
import { deepExtend, cssExtend, TransformOrigin } from './helpers';
import './croppie.css';

class Croppie extends React.Component {
  constructor(props) {
    super(props);

    this.isDragging = false;
    this.originalX = null;
    this.originalY = null;
    this.originalDistance = null;
    this.vpRect = null;
    this.transform = null;
    this._currentZoom = 1;
    this.data = {};
    this.state = {};


    this._bind = this._bind.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.keyMove = this.keyMove.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this._updateOverlay = this._updateOverlay.bind(this);
    this._setZoomerVal = this._setZoomerVal.bind(this);
    this._onZoom = this._onZoom.bind(this);
    this._getVirtualBoundaries = this._getVirtualBoundaries.bind(this);
    this._updatePropertiesFromImage = this._updatePropertiesFromImage.bind(this);

    this.preview = null;
    this.viewport = null;
    this.zoomer = null;
    this.boundary = null;
  }

  componentDidMount() {
    this._bind(this.props.url);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this._bind(nextProps.url);
    }
  }


  onWheel(ev) {
    const self = this;
    let delta;

    if (ev.wheelDelta) {
      delta = ev.wheelDelta / 1200; // wheelDelta min: -120 max: 120 // max x 10 x 2
    } else if (ev.deltaY) {
      delta = ev.deltaY / 1060; // deltaY min: -53 max: 53 // max x 10 x 2
    } else if (ev.detail) {
      delta = ev.detail / -60; // delta min: -3 max: 3 // max x 10 x 2
    } else {
      delta = 0;
    }
    const targetZoom = self._currentZoom + delta;

    ev.preventDefault();
    this._setZoomerVal(targetZoom);
    this.changeZoom();
  }
  mouseDown(ev) {
    ev.preventDefault();
    if (this.isDragging) return;

    this.isDragging = true;
    this.originalX = ev.pageX;
    this.originalY = ev.pageY;
    if (ev.touches) {
      const touches = ev.touches[0];
      this.originalX = touches.pageX;
      this.originalY = touches.pageY;
    }

    this.transform = Transform.parse(this.preview);
    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('touchmove', this.mouseMove);
    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('touchend', this.mouseUp);

    document.body.style[StyleRelated.CSS_USERSELECT] = 'none';
    this.vpRect = this.viewport.getBoundingClientRect();
  }
  keyDown(ev) {
    const self = this;
    const LEFT_ARROW = 37;
    const UP_ARROW = 38;
    const RIGHT_ARROW = 39;
    const DOWN_ARROW = 40;

    function parseKeyDown(key) {
      switch (key) {
        case LEFT_ARROW:
          return [1, 0];
        case UP_ARROW:
          return [0, 1];
        case RIGHT_ARROW:
          return [-1, 0];
        case DOWN_ARROW:
          return [0, -1];
        default:
          return [0, 0];
      }
    }

    if (ev.shiftKey && (ev.keyCode === UP_ARROW || ev.keyCode === DOWN_ARROW)) {
      let zoom = 0.0;
      if (ev.keyCode === UP_ARROW) {
        zoom = parseFloat(this.zoomer.value, 10) + parseFloat(this.zoomer.step, 10);
      } else {
        zoom = parseFloat(this.zoomer.value, 10) - parseFloat(this.zoomer.step, 10);
      }
      // self.setZoom(zoom);
      self._setZoomerVal(zoom);
    } else if (ev.keyCode >= 37 && ev.keyCode <= 40) {
      ev.preventDefault();
      const movement = parseKeyDown(ev.keyCode);

      self.transform = Transform.parse(this.preview);
      document.body.style[StyleRelated.CSS_USERSELECT] = 'none';
      self.vpRect = this.viewport.getBoundingClientRect();
      self.keyMove(movement);
    }
  }

  keyMove(movement) {
    const deltaX = movement[0];
    const deltaY = movement[1];
    const newCss = {};

    this.assignTransformCoordinates(deltaX, deltaY);

    newCss[StyleRelated.CSS_TRANSFORM] = this.transform.toString();
    // css(self.elements.preview, newCss);
    this.setState({
      previewStyle: newCss,
    });
    this._updateOverlay();
    document.body.style[StyleRelated.CSS_USERSELECT] = '';
    this._updateCenterPoint();
    // _triggerUpdate.call(self);TODO
    this.originalDistance = 0;
  }

  mouseMove(ev) {
    const self = this;
    ev.preventDefault();
    let pageX = ev.pageX;
    let pageY = ev.pageY;

    if (ev.touches) {
      const touches = ev.touches[0];
      pageX = touches.pageX;
      pageY = touches.pageY;
    }

    const deltaX = pageX - this.originalX;
    const deltaY = pageY - this.originalY;
    const newCss = {};

    if (ev.type === 'touchmove') {
      if (ev.touches.length > 1) {
        const touch1 = ev.touches[0];
        const touch2 = ev.touches[1];
        // eslint-disable-next-line no-mixed-operators
        const dist = Math.sqrt(((touch1.pageX - touch2.pageX) * (touch1.pageX - touch2.pageX))
          + ((touch1.pageY - touch2.pageY) * (touch1.pageY - touch2.pageY)));

        if (!this.originalDistance) {
          this.originalDistance = dist / self._currentZoom;
        }

        const scale = dist / this.originalDistance;

        this._setZoomerVal.call(self, scale);
        // dispatchChange(self.elements.zoomer);TODO
        return;
      }
    }

    this.assignTransformCoordinates(deltaX, deltaY);
    newCss[StyleRelated.CSS_TRANSFORM] = this.transform.toString();
    // css(self.elements.preview, newCss);

    this.setState({
      previewStyle: cssExtend(newCss, this.state.previewStyle),
    });


    setTimeout(this._updateOverlay, 0);
    this.originalY = pageY;
    this.originalX = pageX;
  }

  changeZoom() {
    this._onZoom({
      value: parseFloat(this.zoomer.value),
      origin: new TransformOrigin(this.preview),
      viewportRect: this.viewport.getBoundingClientRect(),
      transform: Transform.parse(this.preview),
    });
  }

  mouseUp() {
    this.isDragging = false;
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('touchmove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('touchend', this.mouseUp);
    document.body.style[StyleRelated.CSS_USERSELECT] = '';
    this._updateCenterPoint();
    // this._triggerUpdate.call(self);TODO
    this.originalDistance = 0;
  }

  _updateOverlay() {
    const boundRect = this.boundary.getBoundingClientRect();
    const imgData = this.preview.getBoundingClientRect();

    this.setState({
      overlayStyle: {
        width: `${imgData.width}px`,
        height: `${imgData.height}px`,
        top: `${imgData.top - boundRect.top}px`,
        left: `${imgData.left - boundRect.left}px`,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  fix(v, decimalPoints) {
    return parseFloat(v).toFixed(decimalPoints || 0);
  }

  _setZoomerVal(v) {
    // TODO:
    if (this.props.enableZoom) {
      const z = ReactDOM.findDOMNode(this.zoomer);
      const val = this.fix(v, 4);
      z.value = Math.max(z.min, Math.min(z.max, val));
    }
  }
  _onZoom(ui) {
    const self = this;
    const transform = ui ? ui.transform : Transform.parse(ReactDOM.findDOMNode(this.preview));

    // TODO does this need this.vpRect
    const vpRect = ui ? ui.viewportRect : this.elements.viewport.getBoundingClientRect();

    const origin = ui ? ui.origin : new TransformOrigin(ReactDOM.findDOMNode(this.preview));

    function applyCss() {
      const transCss = {};
      transCss[StyleRelated.CSS_TRANSFORM] = transform.toString();
      transCss[StyleRelated.CSS_TRANS_ORG] = origin.toString();
      self.setState({
        previewStyle: transCss,
      });
    }

    self._currentZoom = ui ? ui.value : self._currentZoom;
    transform.scale = self._currentZoom;
    applyCss();


    if (this.props.enforceBoundary) {
      const boundaries = this._getVirtualBoundaries(vpRect);
      const transBoundaries = boundaries.translate;
      const oBoundaries = boundaries.origin;

      if (transform.x >= transBoundaries.maxX) {
        origin.x = oBoundaries.minX;
        transform.x = transBoundaries.maxX;
      }

      if (transform.x <= transBoundaries.minX) {
        origin.x = oBoundaries.maxX;
        transform.x = transBoundaries.minX;
      }

      if (transform.y >= transBoundaries.maxY) {
        origin.y = oBoundaries.minY;
        transform.y = transBoundaries.maxY;
      }

      if (transform.y <= transBoundaries.minY) {
        origin.y = oBoundaries.maxY;
        transform.y = transBoundaries.minY;
      }
    }
    applyCss();
    setTimeout(this._updateOverlay, 0);

    // _triggerUpdate.call(self); TODO
  }

  _getVirtualBoundaries(viewport) {
    // TODO:
    const self = this;

    const scale = self._currentZoom;
    const vpWidth = viewport.width;
    const vpHeight = viewport.height;
    const centerFromBoundaryX = self.props.boundary.width / 2;
    const centerFromBoundaryY = self.props.boundary.height / 2;
    const imgRect = this.preview.getBoundingClientRect();
    const curImgWidth = imgRect.width;
    const curImgHeight = imgRect.height;
    const halfWidth = vpWidth / 2;
    const halfHeight = vpHeight / 2;

    const maxX = ((halfWidth / scale) - centerFromBoundaryX) * -1;
    const minX = maxX - ((curImgWidth * (1 / scale)) - (vpWidth * (1 / scale)));

    const maxY = ((halfHeight / scale) - centerFromBoundaryY) * -1;
    const minY = maxY - ((curImgHeight * (1 / scale)) - (vpHeight * (1 / scale)));

    const originMinX = (1 / scale) * halfWidth;
    const originMaxX = (curImgWidth * (1 / scale)) - originMinX;

    const originMinY = (1 / scale) * halfHeight;
    const originMaxY = (curImgHeight * (1 / scale)) - originMinY;

    return {
      translate: {
        maxX,
        minX,
        maxY,
        minY,
      },
      origin: {
        maxX: originMaxX,
        minX: originMinX,
        maxY: originMaxY,
        minY: originMinY,
      },
    };
  }

  _bind(options) {
    let url;
    let points = [];
    let zoom = null;

    if (typeof (options) === 'string') {
      url = options;
    //   options = {};
    } else if (Array.isArray(options)) {
      points = options.slice();
    } else if (typeof (options) === 'undefined' && this.data.url) {
      // refreshing TODO
      this._updatePropertiesFromImage.call(this);
      // _triggerUpdate.call(self);TODO
      return null;
    } else {
      url = options.url;
      points = options.points || [];
      zoom = typeof (options.zoom) === 'undefined' ? null : options.zoom;
    }

    this.data.bound = false;
    this.data.url = url || this.data.url;
    this.data.points = (points || this.data.points).map(p => parseFloat(p));
    this.data.boundZoom = zoom;
    const prom = this.loadImage(url, this.preview);
    prom.then(() => {
      this._updatePropertiesFromImage.call(this);
      // _triggerUpdate.call(self);TODO
    });
    return prom;
  }

  loadImage(src, imageEl) {
    const self = this;
    const img = imageEl || new Image();
    let prom;

    if (img.src === src) {
      // If image source hasn't changed, return a promise that resolves immediately
      prom = new Promise((resolve) => {
        resolve(img);
      });
    } else {
      prom = new Promise((resolve) => {
        if (self.props.enableOrientation && src.substring(0, 4).toLowerCase() === 'http') {
          img.setAttribute('crossOrigin', 'anonymous');
        }
        img.onload = () => {
          setTimeout(() => {
            resolve(img);
          }, 1);
        };
      });

      img.src = src;
    }

    return prom;
  }

  _updatePropertiesFromImage() {
    const self = this;
    let minZoom = 0;
    let maxZoom = 1.5;
    const initialZoom = 1;
    const cssReset = {};
    const img = this.preview;
    const zoomer = this.zoomer;
    const transformReset = new Transform(0, 0, initialZoom);
    const originReset = new TransformOrigin();
    const isVisible = this._isVisible(self);
    let minW;
    let minH;

    if (!isVisible || self.data.bound) {
      // if the croppie isn't visible or it doesn't need binding
      return;
    }

    self.data.bound = true;
    cssReset[StyleRelated.CSS_TRANS_ORG] = originReset.toString();
    cssReset.opacity = 1;

    const imgData = img.getBoundingClientRect();
    const vpData = this.viewport.getBoundingClientRect();
    // const boundaryData = this.boundary.getBoundingClientRect();
    self._originalImageWidth = imgData.width;
    self._originalImageHeight = imgData.height;

    if (self.props.enableZoom) {
      if (self.props.enforceBoundary) {
        minW = vpData.width / imgData.width;
        minH = vpData.height / imgData.height;
        minZoom = Math.max(minW, minH);
      }

      if (minZoom >= maxZoom) {
        maxZoom = minZoom + 1;
      }

      zoomer.min = this.fix(minZoom, 4);
      zoomer.max = this.fix(maxZoom, 4);
      // Default zoom is set to 1
      // const defaultInitialZoom = Math.max(
      //   (boundaryData.width / imgData.width),
      //   (boundaryData.height / imgData.height),
      // );
      // initialZoom = self.data.boundZoom !== null ? self.data.boundZoom : defaultInitialZoom;
      this._setZoomerVal(initialZoom);
      this._currentZoom = initialZoom;
      // TODO:dispatchChange(zoomer);
    } else {
      self._currentZoom = initialZoom;
    }

    transformReset.scale = self._currentZoom;
    cssReset[StyleRelated.CSS_TRANSFORM] = transformReset.toString();
    this.setState({
      previewStyle: cssReset,
    });

    if (self.data.points.length) {
      // _bindPoints.call(self, self.data.points);
    } else {
      this._centerImage.call(self);
    }

    this._updateCenterPoint.call(self);
    this._updateOverlay.call(self);
  }
  _isVisible() {
    // TODO:
    return this.preview.offsetHeight > 0 && this.preview.offsetWidth > 0;
  }

  _centerImage() {
    const self = this;
    const imgDim = this.preview.getBoundingClientRect();
    const vpDim = this.viewport.getBoundingClientRect();
    const boundDim = this.boundary.getBoundingClientRect();
    const vpLeft = vpDim.left - boundDim.left;
    const vpTop = vpDim.top - boundDim.top;
    const w = vpLeft - ((imgDim.width - vpDim.width) / 2);
    const h = vpTop - ((imgDim.height - vpDim.height) / 2);
    const transform = new Transform(w, h, self._currentZoom);

    // css(self.elements.preview, CSS_TRANSFORM, transform.toString());
    const previewStyle = {};
    previewStyle[StyleRelated.CSS_TRANSFORM] = transform.toString();
    this.setState({
      previewStyle,
    });
  }

  _updateCenterPoint() {
    const self = this;
    const scale = self._currentZoom;
    const data = this.preview.getBoundingClientRect();
    const vpData = this.viewport.getBoundingClientRect();
    const transform = Transform.parse(this.preview.style[StyleRelated.CSS_TRANSFORM]);
    const pc = new TransformOrigin(this.preview);
    const top = (vpData.top - data.top) + (vpData.height / 2);
    const left = (vpData.left - data.left) + (vpData.width / 2);
    const center = {};
    const adj = {};

    center.y = top / scale;
    center.x = left / scale;
    adj.y = (center.y - pc.y) * (1 - scale);
    adj.x = (center.x - pc.x) * (1 - scale);

    transform.x -= adj.x;
    transform.y -= adj.y;

    const newCss = {};
    newCss[StyleRelated.CSS_TRANS_ORG] = `${center.x}px${' '}${center.y}px`;
    newCss[StyleRelated.CSS_TRANSFORM] = transform.toString();
    // css(self.elements.preview, newCss);
    this.setState({
      previewStyle: newCss,
    });
  }

  assignTransformCoordinates(deltaX, deltaY) {
    const self = this;
    const imgRect = this.preview.getBoundingClientRect();
    const top = this.transform.y + deltaY;
    const left = this.transform.x + deltaX;

    if (self.props.enforceBoundary) {
      if (this.vpRect.top > imgRect.top + deltaY && this.vpRect.bottom < imgRect.bottom + deltaY) {
        this.transform.y = top;
      }

      if (this.vpRect.left > imgRect.left + deltaX && this.vpRect.right < imgRect.right + deltaX) {
        this.transform.x = left;
      }
    } else {
      this.transform.y = top;
      this.transform.x = left;
    }
  }

  resultImage() {
    const data = this._get();
    return data;
  }

  result(options) {
    const self = this;
    const data = this._get();
    const opts = deepExtend(deepExtend({}, this.RESULT_DEFAULTS), deepExtend({}, options));
    const type = (typeof (options) === 'string' ? options : (opts.type || 'viewport'));
    const size = opts.size;
    const format = opts.format;
    const quality = opts.quality;
    const backgroundColor = opts.backgroundColor;
    const circle = typeof opts.circle === 'boolean' ? opts.circle : (self.props.viewport.type === 'circle');
    const vpRect = this.viewport.getBoundingClientRect();
    const ratio = vpRect.width / vpRect.height;

    if (size === 'viewport') {
      data.outputWidth = vpRect.width;
      data.outputHeight = vpRect.height;
    } else if (typeof size === 'object') {
      if (size.width && size.height) {
        data.outputWidth = size.width;
        data.outputHeight = size.height;
      } else if (size.width) {
        data.outputWidth = size.width;
        data.outputHeight = size.width / ratio;
      } else if (size.height) {
        data.outputWidth = size.height * ratio;
        data.outputHeight = size.height;
      }
    }

    if (this.RESULT_FORMATS.indexOf(format) > -1) {
      data.format = `image/${format}`;
      data.quality = quality;
    }

    data.circle = circle;
    data.url = self.data.url;
    data.backgroundColor = backgroundColor;

    const prom = new Promise((resolve, reject) => {
      if (data) {
        if (type === 'rawCanvas') {
          resolve(self._getCanvasResult(this.preview, data));
        }
        if (type === 'canvas' || type === 'base64') {
          resolve(self._getBase64Result(data));
        } else if (type === 'blob') {
          resolve(self._getBlobResult(data));
        } else resolve(self._getHtmlResult(data));
      }
      reject(new Error('No data found'));
    });
    return prom;
  }
  // eslint-disable-next-line class-methods-use-this
  _getHtmlResult(data) {
    const points = data.points;
    return (
      <div className="croppie-result" style={{ width: points[2] - points[0], height: points[3] - points[1] }}>
        <img src={data.url} alt="" style={{ left: `${-1 * points[0]}px`, top: `${-1 * points[1]}px` }} />
      </div>
    );
  }

  _getBase64Result(data) {
    const self = this;
    return self._getCanvasResult(this.preview, data).toDataURL(data.format, data.quality);
  }

  _getBlobResult(data) {
    const self = this;
    return new Promise((resolve, reject) => {
      if (data) {
        const canvasRes = self._getCanvasResult(this.preview, data);
        canvasRes.toBlob((blob) => {
          resolve(blob);
        }, data.format, data.quality);
      }
      reject(new Error('No data found!'));
    });
  }

  _get() {
    // TODO:
    const self = this;

    const imgData = this.preview.getBoundingClientRect();
    const vpData = this.viewport.getBoundingClientRect();
    let x1 = vpData.left - imgData.left;
    let y1 = vpData.top - imgData.top;
    const widthDiff = (vpData.width - this.viewport.offsetWidth) / 2;
    const heightDiff = (vpData.height - this.viewport.offsetHeight) / 2;
    let x2 = x1 + this.viewport.offsetWidth + widthDiff;
    let y2 = y1 + this.viewport.offsetHeight + heightDiff;
    let scale = self._currentZoom;

    // eslint-disable-next-line no-restricted-globals
    if (scale === Infinity || isNaN(scale)) {
      scale = 1;
    }

    const max = self.props.enforceBoundary ? 0 : Number.NEGATIVE_INFINITY;
    x1 = Math.max(max, x1 / scale);
    y1 = Math.max(max, y1 / scale);
    x2 = Math.max(max, x2 / scale);
    y2 = Math.max(max, y2 / scale);

    return {
      points: [this.fix(x1), this.fix(y1), this.fix(x2), this.fix(y2)],
      zoom: scale,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  _getCanvasResult(img, data) {
    const points = data.points;
    const left = points[0];
    const top = points[1];
    const width = (points[2] - points[0]);
    const height = (points[3] - points[1]);
    const circle = data.circle;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let outWidth = width;
    let outHeight = height;

    if (data.outputWidth && data.outputHeight) {
      outWidth = data.outputWidth;
      outHeight = data.outputHeight;
    }

    canvas.width = outWidth;
    canvas.height = outHeight;

    if (data.backgroundColor) {
      ctx.fillStyle = data.backgroundColor;
      ctx.fillRect(0, 0, outWidth, outHeight);
    }
    ctx.drawImage(img, left, top, width, height, 0, 0, outWidth, outHeight);
    if (circle) {
      ctx.fillStyle = '#fff';
      ctx.globalCompositeOperation = 'destination-in';
      ctx.beginPath();
      ctx.arc(outWidth / 2, outHeight / 2, outWidth / 2, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
    }
    return canvas;
  }

  render() {
    const contClass = 'croppie-container';
    const customViewportClass = this.props.viewport.type ? `cr-vp-${this.props.viewport.type}` : ' ';
    let preview;

    if (this.props.enableOrientation) {
      preview = <canvas className="cr-image" ref={(previewEl) => { this.preview = previewEl; }} style={this.state.previewStyle || {}} />;
    } else {
      preview = <img src="" alt="" className="cr-image" ref={(previewEl) => { this.preview = previewEl; }} style={this.state.previewStyle || {}} />;
    }

    const onWheelFunc = this.props.enableZoom ? this.onWheel : () => {};
    return (
      <div className={contClass}>
        <div
          className="cr-boundary"
          ref={(boundary) => { this.boundary = boundary; }}
          style={{ width: this.props.boundary.width, height: this.props.boundary.height }}
          onWheel={onWheelFunc}
        >
          {preview}

          <div
            tabIndex="0"
            onKeyDown={this.keyDown.bind(this)}
            ref={(viewport) => { this.viewport = viewport; }}
            className={`cr-viewport ${customViewportClass}`}
            style={{ width: this.props.viewport.width, height: this.props.viewport.height }}
          />

          <div
            className="cr-overlay"
            onTouchStart={this.mouseDown.bind(this)}
            onMouseDown={this.mouseDown.bind(this)}
            style={this.state.overlayStyle}
          />

        </div>
        {this.props.enableZoom &&
        <div className="cr-slider-wrap">
          <input
            type="range"
            className="cr-slider"
            step="0.0001"
            style={{ display: this.props.showZoomer ? '' : 'none' }}
            onChange={this.changeZoom.bind(this)}
            ref={(zoomer) => { this.zoomer = zoomer; }}
          />
        </div>
        }
      </div>
    );
  }
}

Croppie.RESULT_DEFAULTS = {
  type: 'canvas',
  format: 'png',
  quality: 1,
};

Croppie.RESULT_FORMATS = [
  'jpeg',
  'webp',
  'png',
];

Croppie.defaultProps = {
  viewport: {
    width: 150,
    height: 150,
    type: 'circle',
  },
  boundary: {
    width: 150,
    height: 150,
  },
  orientationControls: {
    enabled: true,
    leftClass: '',
    rightClass: '',
  },
  customClass: '',
  showZoomer: true,
  enableZoom: true,
  mouseWheelZoom: true,
  enableExif: false,
  enforceBoundary: true,
  enableOrientation: false,
  update: () => { },
};

Croppie.propTypes = {
  viewport: PropTypes.any,
  boundary: PropTypes.object,
  orientationControls: PropTypes.object,
  customClass: PropTypes.string,
  showZoomer: PropTypes.bool,
  enableZoom: PropTypes.bool,
  mouseWheelZoom: PropTypes.bool,
  enableExif: PropTypes.bool,
  enforceBoundary: PropTypes.bool,
  enableOrientation: PropTypes.bool,
  update: PropTypes.func,
  url: PropTypes.string,
};

export default Croppie;
