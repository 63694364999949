import React from 'react';
import { connect } from 'react-redux';
import TournamentIcon from '../../../assets/icons/TournamentIcon';
import { getReducedEntityObjectList } from '../../../redux/reducers';
import constants from '../../../utils/constants';
import TournamentEntityListItem from './TournamentEntityListItem';
import EntitySearch from '../components/EntitiySearch';

const TournamentSearch = connect(state => ({
  entities: getReducedEntityObjectList(state.entities.tournaments),
}))(props => (
  <EntitySearch
    {...{
      ...props,
      title: 'Tournaments',
      entityType: constants.entityType.tournament,
      EntityListItem: TournamentEntityListItem,
      searches: [
        {
          search: 'name',
          leftIcon: <TournamentIcon />,
          hintText: 'Tournament',
        },
      ],
    }}
  />
));

export default TournamentSearch;
