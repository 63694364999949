import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StadiumIcon = props => (
  <SvgIcon {...props} viewBox="1 17.8 98.1 79.2">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M89.2,26.9v11.8 c5.6,1.8,8.8,4.1,8.8,6.5 c0,3.4-6.3,6.4-16.3,8.5c0.2-0.1,0.3-0.2,0.5-0.4c4-1.3,6.3-2.9,6.3-4.5c0-4.6-17.5-8.3-39-8.3c-21.5,0-39,3.7-39,8.3c0,2,3.3,3.9,8.9,5.3C8.2,52,1,48.8,1,45.2c0-2.5,3.5-4.8,9.4-6.7V26.9v-6.2H12h9.9v6.2H12V38c0.4-0.1,0.8-0.2,1.2-0.3c4.9-1.3,11.2-2.3,18.2-3c0.7-0.1,1.3-0.1,2-0.2c4.9-0.4,10.2-0.6,15.7-0.6c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0v-9.9v-6.2h1.6h9.9v6.2h-9.9v9.9c14.6,0.1,27.5,1.8,36,4.3V26.9v-6.2h1.6h9.9v6.2H89.2z M1.6,54l4,20.5l1.6,14.6c2,1.4,4.8,2.6,8.3,3.7V80.2c0-3.8,3.1-6.9,6.9-6.9h0c3.8,0,6.9,3.1,6.9,6.9v15.5c4.1,0.6,8.6,1,13.3,1.2V80.2c0-3.8,3.1-6.9,6.9-6.9s6.9,3.1,6.9,6.9V97c4.7-0.2,9.2-0.5,13.3-1.1V80.2c0-3.8,3.1-6.9,6.9-6.9s6.9,3.1,6.9,6.9v13c3.2-0.9,6-2,8.1-3.1l2-16.7L97.4,54c-6,3.9-25.2,8.3-47.9,8.3C26.8,62.3,7.6,57.9,1.6,54z" />
  </SvgIcon>
);

export default StadiumIcon;
