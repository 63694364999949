/* eslint-disable camelcase */
// API calls (always returns promises)

import Base, { API } from './base';
import search from './searchUtil';

export { axiosInstance } from './base';

export default class Backend {
  static async searchWithCancel(endpoint) {
    try {
      return await search(endpoint);
    } catch (error) {
      return [];
    }
  }

  static fetchTasks(taskState, page) {
    return Base.get(API.admin, `tasks?${taskState ? `task_state=${taskState}&` : ''}page=${page}`);
  }

  static fetchTask(taskId) {
    return Base.get(API.admin, `task/${taskId}`);
  }

  static fetchNextTask(taskType, entityType = '', taskState = '', provider = '', filter = '') {
    return Base.get(API.admin, `task/current/${taskType}?entity_type=${entityType}&task_state=${taskState}&provider=${provider}&search=${filter}`);
  }

  static fetchTaskCount(taskType) {
    return Base.get(API.admin, `tasks/${taskType}/count`);
  }

  static grabTask(id) {
    return Base.put(`task/${id}/grab`);
  }

  static pinMatch(id) {
    return Base.put(`match/${id}/pin`).then(response => ({ response, id, pinned: true }));
  }

  static unpinMatch(id) {
    return Base.put(`match/${id}/unpin`).then(response => ({ response, id, pinned: false }));
  }

  static togglePinMatch({ matchId, pinned }) {
    if (pinned) {
      return this.unpinMatch(matchId);
    }
    return this.pinMatch(matchId);
  }

  static confirmTaskAction(id, action, message, targetId, overwrites) {
    let payload;
    if (targetId) {
      payload = { target_id: targetId };
    }

    if (action === 'accept') {
      payload = { overwrites: overwrites || {} };
    }

    switch (action) {
      case 'accept':
      case 'reject':
      case 'map':
      case 'skip':
      case 'verify':
      case 'grab':
        return Base.put(
          `task/${id}/${action}${message ? `?comment=${message}` : ''}`,
          payload && payload,
        );
      default:
        return new Promise((resolve, reject) => {
          reject(Error(`Confirm: Invalid action selected: ${action}`));
        });
    }
  }

  static validateUserLogin() {
    return Base.get(API.admin, 'user/username');
  }

  static fetchTeams() {
    return Base.get(API.admin, 'teams');
  }

  static fetchMatch(id) {
    return Base.get(API.admin, `match/${id}`);
  }

  static fetchTournament(id) {
    return Base.get(API.admin, `tournament/${id}`);
  }

  static fetchManager(id) {
    return Base.get(API.admin, `manager/${id}`);
  }

  static fetchPlayer(id) {
    return Base.get(API.admin, `player/${id}`);
  }

  static fetchTeam(id) {
    return Base.get(API.admin, `team/${id}`);
  }

  static fetchRegion(id) {
    return Base.get(API.admin, `region/${id}`);
  }

  static fetchSeason(id) {
    return Base.get(API.admin, `season/${id}`);
  }

  static fetchStadium(id) {
    return Base.get(API.admin, `stadium/${id}`);
  }

  static fetchTargetSuggestions(taskId, threshold) {
    const thresholdParam = threshold ? `?threshold=${threshold}` : '';
    return Base.get(API.admin, `task/${taskId}/suggestions${thresholdParam}`);
  }

  static fetchEntity(entityId, entityType) {
    switch (entityType) {
      case 'match':
        return this.fetchMatch(entityId);
      case 'tournament':
        return this.fetchTournament(entityId);
      case 'manager':
        return this.fetchManager(entityId);
      case 'player':
        return this.fetchPlayer(entityId);
      case 'team':
        return this.fetchTeam(entityId);
      case 'region':
        return this.fetchRegion(entityId);
      case 'season':
        return this.fetchSeason(entityId);
      case 'stadium':
        return this.fetchStadium(entityId);
      default:
        return new Promise((resolve, reject) => {
          reject(Error('Fetch entity: Invalid entity type'));
        });
    }
  }

  static searchTournaments(name = '') {
    return this.searchWithCancel(`${API.admin.url}tournaments/search?name=${name}`);
  }

  static searchRegions(name = '') {
    return this.searchWithCancel(`${API.admin.url}regions/search?name=${name}`);
  }

  static searchSeasons(name = '') {
    return this.searchWithCancel(`${API.admin.url}seasons/search?name=${name}`);
  }

  static searchStadiums(name = '', region = '') {
    return this.searchWithCancel(`${API.admin.url}stadiums/search?name=${name}&region=${region}`);
  }

  static searchTeams(name = '') {
    return this.searchWithCancel(`${API.admin.url}teams/search?name=${name}`);
  }

  static searchManagers(name = '', region = '') {
    return this.searchWithCancel(`${API.admin.url}managers/search?full_name=${name}&region=${region}`);
  }

  static searchPlayers(name = '', team = '') {
    return this.searchWithCancel(`${API.admin.url}players/search?name=${name}&team=${team}`);
  }

  static searchMatches(searches) {
    const offset = new Date().getTimezoneOffset() * -1;
    return Base.get(API.private_striker, `calendar/${searches.date}/matches?utc_offset=${offset}`)
      .then(({ matches }) => {
        const shouldFilterTournament = typeof searches.tournament === 'number';
        const shouldFilterTeam = typeof searches.team === 'number';

        const filteredMatches = [];
        const teams = {};
        const tournaments = {};

        matches.forEach((match) => {
          const acceptTournament =
                !shouldFilterTournament || searches.tournament === match.tournament.id;

          const acceptTeam =
                !shouldFilterTeam || (
                  searches.team === match.home_team.id ||
                  searches.team === match.away_team.id
                );

          if (acceptTeam && acceptTournament) {
            filteredMatches.push(match);
          }

          if (acceptTeam) {
            tournaments[match.tournament.id] = match.tournament;
          }

          if (acceptTournament) {
            teams[match.home_team.id] = match.home_team;
            teams[match.away_team.id] = match.away_team;
          }
        });

        const entriesToSelections = entries =>
          Object.entries(entries)
            .sort(([, value1], [, value2]) => value1.name.localeCompare(value2.name))
            .map(([id, value]) => ({ id: parseInt(id, 10), value }));

        return {
          matches: filteredMatches,
          filterSelections: {
            teams: entriesToSelections(teams),
            tournaments: entriesToSelections(tournaments),
          },
        };
      });
  }

  static searchEntities(entityType, query, query2, query3) {
    switch (entityType) {
      case 'tournament':
        return this.searchTournaments(query);
      case 'team':
        return this.searchTeams(query);
      case 'season':
        return this.searchSeasons(query);
      case 'manager':
        return this.searchManagers(query, query2);
      case 'player':
        return this.searchPlayers(query, query2);
      case 'match':
        return this.searchMatches(query, query2, query3);
      case 'stadium':
        return this.searchStadiums(query, query2);
      default:
        return new Promise((resolve, reject) => {
          reject(Error('Search: no valid entity type selected.'));
        });
    }
  }

  static searchEntitiesNew(entityType, searches = {}) {
    switch (entityType) {
      case 'tournament':
        return this.searchTournaments(searches.name);
      case 'region':
        return this.searchRegions(searches.name);
      case 'season':
        return this.searchSeasons(searches.name);
      case 'team':
        return this.searchTeams(searches.name);
      case 'manager':
        return this.searchManagers(searches.name, searches.region);
      case 'player':
        return this.searchPlayers(searches.name, searches.team);
      case 'match':
        return this.searchMatches(searches);
      case 'stadium':
        return this.searchStadiums(searches.name, searches.region);
      default:
        return new Promise((resolve, reject) => {
          reject(Error('Search: no valid entity type selected.'));
        });
    }
  }

  static searchTasks({
    entityType = '', taskState = '', provider = '', filter = '',
  }) {
    return Base.get(API.admin, `tasks?entity_type=${entityType}&task_state=${taskState}&provider=${provider}&search=${filter}`);
  }

  static logout() {
    return Base.post('logout');
  }

  static login(username, password) {
    return Base.post('login', { username, password });
  }

  static fetchMatchEvents(matchId) {
    return Base.get(API.private_striker, `matches/${matchId}/periods`, true);
  }

  static savePlayer({ id, ...rest }) {
    return Promise.all(Object.entries(rest)
      .filter(([, value]) => !!value)
      .map(([key, value]) => Base.put(`overwrites/player/${id}/attributes/${key}`, { value })));
  }

  static overwriteEntity(entityType, id, properties) {
    return Object.entries(properties)
      .filter(([, value]) => !!value)
      .reduce(
        (promiseChain, [key, value]) =>
          promiseChain.finally(() =>
            (value.restore
              ? Base.delete(`overwrites/${entityType}/${id}/attributes/${key}`)
              : Base.put(`overwrites/${entityType}/${id}/attributes/${key}`, { value: value.value }))),
        Promise.resolve(),
      );
  }

  static setMatchEventDeleted(type, id, deleted) {
    if (deleted) {
      return Base.put(`overwrites/${type}/${id}/attributes/deleted`, { value: deleted });
    }

    return Base.delete(`overwrites/${type}/${id}/attributes/deleted`);
  }

  static fetchEntityOverwrites(entityType, id) {
    return Base.get(API.admin, `overwrites/${entityType}/${id}/attributes`);
  }

  static fetchEntityProviders(entityType, entityId) {
    return Base.get(API.admin, `${entityType}/${entityId}/provider_ids`);
  }

  static fetchEntityProviderNames(entityType, entityId) {
    return Base.get(API.admin, `${entityType}/${entityId}/provider_names`);
  }

  static fetchSelectedEntityProvider(entityType, entityId) {
    return Base.get(API.admin, `${entityType}/${entityId}/provider`);
  }

  static selectEntityProvider(entityType, entityId, provider_name) {
    return Base.put(`${entityType}/${entityId}/provider`, { provider_name });
  }

  static uploadImage(entityType, entityId, imgKey, payload) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return Base.put(`images/${entityType}/${entityId}/${imgKey}`, payload, config);
  }

  static changePassword(current_password, new_password) {
    return Base.put('user/password', { current_password, new_password });
  }

  static deleteMapping(entity_type, forza_id, provider_name, provider_entity_id) {
    return Base.delete(`mapping/${entity_type}/${forza_id}/${provider_name}/${provider_entity_id}`);
  }

  static fetchRegionTournaments(regionId) {
    return Base.get(API.admin, `region/${regionId}/tournaments`);
  }

  static fetchSeasons(tournamentId) {
    return Base.get(API.admin, `tournament/${tournamentId}/seasons`);
  }

  static fetchStadiums(regionId) {
    return Base.get(API.admin, `region/${regionId}/stadiums`);
  }

  static fetchManagers(regionId) {
    return Base.get(API.admin, `region/${regionId}/managers`);
  }
}
