import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import LinkIcon from '@material-ui/icons/Link';

const styles = {
  mapIconWrapper: {
    alignSelf: 'center',
    margin: '0 10px 0 -14px',
  },
  map: {
    background: '#2196f3',
    color: 'white',
    '&:hover': {
      background: '#fff',
      color: '#2196f3',
    },
  },
};

const TaskMapAction = ({ classes, onTaskAction, isEditable }) => (
  <div className={classes.mapIconWrapper}>
    <Tooltip title="Map">
      {/* used this redundant div as material ui throws a warning:( */}
      <div>
        <Fab
          className={classes.map}
          aria-label="Map"
          onClick={onTaskAction('map')}
          disabled={!isEditable}
          size="small"
          tabIndex="0"
        >
          <LinkIcon />
        </Fab>
      </div>
    </Tooltip>
  </div>
);

TaskMapAction.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isEditable: PropTypes.bool.isRequired,
  onTaskAction: PropTypes.func.isRequired,
};
export default withStyles(styles)(TaskMapAction);
