// Replace <PROVIDER_ID> with provider ID
// OPTA
// Region:
// http://api.performfeeds.com/soccerdata/tournamentcalendar/83lutzy5i67h1iu73o45az6z2?_fmt=json&_rt=b
// (there's no endpoint for a region, so we need to list all tournaments and then search for "country" ID manually)
// Tournament:
// http://api.performfeeds.com/soccerdata/tournamentcalendar/83lutzy5i67h1iu73o45az6z2/authorized?comp=<PROVIDER_ID>&_fmt=json&_rt=b
// Team:
// http://api.performfeeds.com/soccerdata/team/83lutzy5i67h1iu73o45az6z2?ctst=<PROVIDER_ID>&_fmt=json&_rt=b&detailed=yes
// Player:
// http://api.performfeeds.com/soccerdata/playercareer/83lutzy5i67h1iu73o45az6z2?prsn=<PROVIDER_ID>&_fmt=json&_rt=b
// Match:
// http://api.performfeeds.com/soccerdata/match/83lutzy5i67h1iu73o45az6z2/<PROVIDER_ID>?live=yes&lineups=yes&_fmt=json&_rt=b

// SPORTRADAR
// Region:
// https://api.sportradar.us/soccer-xt3/global/en/tournaments.json?api_key=ethgrk8u9cpbudcze738a7rr
// (there's no endpoint for a region, so we need to list all tournaments and then search for "category" ID manually)
// Tournament:
// https://api.sportradar.us/soccer-xt3/global/en/tournaments/<PROVIDER_ID>/info.json?api_key=ethgrk8u9cpbudcze738a7rr
// Team:
// https://api.sportradar.us/soccer-xt3/global/en/teams/<PROVIDER_ID>/profile.json?api_key=ethgrk8u9cpbudcze738a7rr
// Player:
// https://api.sportradar.us/soccer-xt3/global/en/players/<PROVIDER_ID>/profile.json?api_key=ethgrk8u9cpbudcze738a7rr
// Match
// https://api.sportradar.us/soccer-xt3/global/en/matches/<PROVIDER_ID>/timeline.json?api_key=ethgrk8u9cpbudcze738a7rr

const providers = {
  opta: 'opta',
  sportradar: 'sportradar',
};

const baseUrls = {
  [providers.opta]: 'http://api.performfeeds.com/soccerdata/',
  [providers.sportradar]: 'https://api.sportradar.us/soccer/trial/v4/en/',
};

const entityPaths = {
  [providers.opta]: {
    region: () => 'tournamentcalendar/83lutzy5i67h1iu73o45az6z2?_fmt=json&_rt=b',
    season: () => 'tournamentcalendar/83lutzy5i67h1iu73o45az6z2?_fmt=json&_rt=b',
    tournament: id =>
      `tournamentcalendar/83lutzy5i67h1iu73o45az6z2/authorized?comp=${id}&_fmt=json&_rt=b`,
    team: id => `team/83lutzy5i67h1iu73o45az6z2?ctst=${id}&_fmt=json&_rt=b&detailed=yes`,
    player: id => `playercareer/83lutzy5i67h1iu73o45az6z2?prsn=${id}&_fmt=json&_rt=b`,
    match: id => `match/83lutzy5i67h1iu73o45az6z2/${id}?live=yes&lineups=yes&_fmt=json&_rt=b`,
  },
  [providers.sportradar]: {
    region: () => 'competitions.json?api_key=ethgrk8u9cpbudcze738a7rr',
    season: () => 'competitions.json?api_key=ethgrk8u9cpbudcze738a7rr',
    tournament: id => `competitions/${id}/info.json?api_key=ethgrk8u9cpbudcze738a7rr`,
    team: id => `competitors/${id}/profile.json?api_key=ethgrk8u9cpbudcze738a7rr`,
    player: id => `players/${id}/profile.json?api_key=ethgrk8u9cpbudcze738a7rr`,
    match: id => `sport_events/${id}/timeline.json?api_key=ethgrk8u9cpbudcze738a7rr`,
  },
};

export default class providerLinks {
  static getLink(provider, entityType, id) {
    const pathGetters = entityPaths[String(provider).toLowerCase()];
    const getPath = pathGetters && pathGetters[entityType];
    if (getPath) {
      const baseUrl = baseUrls[String(provider).toLowerCase()];
      return `${baseUrl}${getPath(id)}`;
    }

    return null;
  }
}
