import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Backend from '../../../services/backend';
import EntitySearchFields from './EntitySearchFields';
import EntitySearchResults from './EntitySearchResults';
import { selectEntity } from '../../../redux/actions';
import constants from '../../../utils/constants';
import EntitySearchSpinner from '../../../components/EntitySearchSpinner';

const getEntityRoute = (entityId, entityType) => {
  if (entityType === constants.entityType.match) {
    return `/${entityType}es/${entityId}`;
  }
  return `/${entityType}s/${entityId}`;
};

const EntitySearch = connect(
  state => ({ loading: state.entities.loading }),
  dispatch => ({
    handleSearch: (entityType, searches) =>
      dispatch({
        type: `SEARCH_${String(entityType).toUpperCase()}`,
        payload: Backend.searchEntitiesNew(entityType, searches),
      }),
    onSelectEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  }),
)(class EntitySearch extends Component {
    state = {
      searches: {
        ...this.props.searches
          .map(search => ({ [search.search]: search.value }))
          .reduce((a, b) => ({ ...a, ...b }), {}),
      },
    };

    componentDidMount() {
      setTimeout(() => this.handleSearch(), 225);
    }

    onItemSelect = (entityId, entityType) => {
      const { history } = this.props;
      const entityRoute = getEntityRoute(entityId, entityType);
      history.push(entityRoute);
    };

    onSearch = key => (value) => {
      this.setState(
        {
          searches: {
            ...this.state.searches,
            [key]: value,
          },
        },
        this.handleSearch,
      );
    };

    handleSearch = () => {
      this.props.handleSearch(this.props.entityType, this.state.searches);
    };

    render() {
      return (
        <div>
          <div
            style={{
              minHeight: 96,
              padding: '33px 15px 0',
              backgroundColor: '#38D430',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography color="secondary" variant="h6">
              {this.props.title}
            </Typography>

            <IconButton color="secondary" onClick={this.handleSearch}>
              <RefreshIcon />
            </IconButton>
          </div>
          <EntitySearchFields
            searches={this.props.searches}
            onSearch={this.onSearch}
            loading={this.props.loading}
          />
          {this.props.loading && <EntitySearchSpinner />}

          <EntitySearchResults
            loading={this.props.loading}
            entities={this.props.entities}
            onItemClick={this.onItemSelect}
            EntityListItem={this.props.EntityListItem}
            entityType={this.props.entityType}
          />
        </div>
      );
    }
});

export default withRouter(EntitySearch);
