import React from 'react';
import { withTheme, Typography } from '@material-ui/core';

const EntityCardHeader = ({
  theme, primary, imgUrl, children, entityType, ...rest
}) => (
  <div
    style={{
      color: 'white',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-end',
      width: '100%',
      height: 72 * 3,
      backgroundSize: entityType && entityType === 'player' ? 'contain' : 'auto, cover',
      backgroundRepeat: 'no-repeat',
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
      backgroundColor: entityType && entityType === 'player' ? 'none' : theme.palette.primary.main,
      backgroundImage: `linear-gradient(to bottom, rgba(0, 97, 137, 0.71), #2196f3), ${
        imgUrl ? `url(${imgUrl})` : ''
      } `,
      backgroundPosition: entityType && entityType === 'player' ? 'center' : 'initial',
    }}
    {...rest}
  >
    <Typography
      variant="h5"
      color="inherit"
      style={{
        paddingLeft: 16,
        marginBottom: 16,
      }}
    >
      {primary}
    </Typography>
    <div style={{ position: 'absolute', right: 12, bottom: -12 }}>{children}</div>
  </div>
);

export default withTheme(EntityCardHeader);
