import React from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditManager from './EditManager';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardTab from '../components/EntityCardTab';
import EntityCardFooter from '../components/EntityCardFooter';
import ProviderPopover from '../../../components/ProviderPopover';
import Mapping from '../components/Mapping';
import { getSuggestionPrimaryText } from '../../tasks/helper';

const ManagerCard = ({
  manager,
  attributeTypes,
  currentEdits,
  buttonEnable,
  providers,
  readOnly,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const overwriteEntity = () => {
    overwriteAndReloadEntity(manager.id, currentEdits);
  };

  const reloadContent = () => {
    fetchEntityAndOverwrites(manager.id);
  };

  return (
    manager && (
      <EntityCard>
        <EntityCardHeader
          primary={getSuggestionPrimaryText('manager', manager)}
          imgUrl=""
        >
          {readOnly && <ProviderPopover entityType="manager" providers={providers} />}
        </EntityCardHeader>
        <EntityCardContent
          tabs={
            readOnly ? ['information'] : ['information', 'mappings']
          }
        >
          <EntityCardTab tab="information">
            <EditManager
              entity={manager}
              entityType="manager"
              key={manager.id}
              editableProperties={readOnly ? [] : Object.keys(manager).filter(key => key !== 'id')}
              attributeTypes={attributeTypes}
              reloadContent={reloadContent}
            />

            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          <EntityCardTab tab="mappings">
            <Mapping entityType="manager" entityId={manager.id} />
          </EntityCardTab>
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default ManagerCard;
