import React from 'react';
import { Fab } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditTournament from './EditTournament';
import EntityCard from '../components/EntityCard';
import EntityCardHeader from '../components/EntityCardHeader';
import EntityCardFooter from '../components/EntityCardFooter';
import EntityCardContent from '../components/EntityCardContent';
import EntityCardTab from '../components/EntityCardTab';
import ProviderPopover from '../../../components/ProviderPopover';
import TournamentVisualSetup from './TournamentVisualSetup';
import Mapping from '../components/Mapping';
import Seasons from './Seasons';
import Backend from '../../../services/backend';
import useGenericStatus from '../../../dataHooks/useGenericStatus';
import { getImageUrl } from '../../../utils/getImageUrl';
import { getReducedEntityObjectList } from '../../../redux/reducers/helper';

const TournamentCard = ({
  tournament,
  attributeTypes,
  currentEdits,
  buttonEnable,
  providers,
  readOnly = false,
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
}) => {
  const [seasons, setSeasons] = React.useState([]);
  const [loading, error, setLoading, setError] = useGenericStatus(false);

  React.useEffect(() => {
    const fetchSeasons = async () => {
      try {
        setLoading(true);

        const seasonsResponse = await Backend.fetchSeasons(tournament.id);
        setSeasons(getReducedEntityObjectList(seasonsResponse));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (tournament && tournament.id) {
      fetchSeasons();
    }
  }, [tournament && tournament.id]);

  const overwriteEntity = () => {
    overwriteAndReloadEntity(tournament.id, currentEdits);
  };

  const reloadContent = () => {
    fetchEntityAndOverwrites(tournament.id);
  };

  return (
    tournament && (
      <EntityCard>
        <EntityCardHeader
          primary={tournament.name}
          imgUrl={getImageUrl('region', tournament.region.id, 'background_full')}
        >
          {readOnly && <ProviderPopover entityType="tournament" providers={providers} />}
        </EntityCardHeader>
        <EntityCardContent tabs={readOnly ? ['information'] : ['information', 'seasons', 'visual setup', 'mappings']}>
          <EntityCardTab tab="information">
            <EditTournament
              entity={tournament}
              entityType="tournament"
              key={tournament.id}
              editableProperties={
                readOnly ? [] : Object.keys(tournament).filter(key => key !== 'id')
              }
              attributeTypes={attributeTypes}
              reloadContent={reloadContent}
            />
            {!readOnly && (
              <EntityCardFooter>
                <Fab
                  style={{ minWidth: 56 }}
                  disabled={!buttonEnable}
                  color="primary"
                  aria-label="Save"
                  onClick={overwriteEntity}
                >
                  <SaveIcon />
                </Fab>
              </EntityCardFooter>
            )}
          </EntityCardTab>
          <EntityCardTab tab="seasons">
            {!loading && !error && <Seasons seasons={seasons} />}
          </EntityCardTab>
          {!readOnly && (
            <EntityCardTab tab="mappings">
              <Mapping entityType="tournament" entityId={tournament.id} />
            </EntityCardTab>
          )}
          {!readOnly && (
            <EntityCardTab tab="visual setup">
              <TournamentVisualSetup tournament={tournament} />
            </EntityCardTab>
          )}
        </EntityCardContent>
      </EntityCard>
    )
  );
};

export default TournamentCard;
