import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import UserMenu from './UserMenu';

const styles = {
  cardHeader: {
    minWidth: 128,
  },
  userAvatar: {
    width: 64,
    height: 64,
    marginTop: -32,
    marginLeft: 16,
  },
  userHeader: {
    minHeight: 96,
  },
  userArea: {
    padding: '12px 24px 12px 4px',
    display: 'flex',
    alignItems: 'center',
  },
};

const formatUserName = name => `${name.charAt(0).toUpperCase()}${name.slice(1)}`;

const UserInfoCard = (props) => {
  const {
    user, classes, onLogout,
  } = props;

  return (
    <div>
      <div className={classes.userHeader} style={{ backgroundColor: '#2196f3' }} />
      {/* TODO: Enable Avatar once the we have the user images */}
      {/* <Avatar src={user.avatar} className={classes.userAvatar} /> */}
      <div className={classes.userArea}>
        <UserMenu onLogout={onLogout} style={{ marginRight: 20 }} />
        <Typography variant="subtitle1">{user ? formatUserName(user) : ''}</Typography>
      </div>
      <Divider />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(UserInfoCard);
