import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
});

const EntitySearchSpinner = ({ classes }) => (
  <div className={classes.spinnerWrapper}>
    <CircularProgress disableShrink />
  </div>
);

export default withStyles(styles)(EntitySearchSpinner);
