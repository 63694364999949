import React from 'react';

const EntityCardFooter = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 24px 16px',
    }}
  >
    {children}
  </div>
);

export default EntityCardFooter;
