import React from 'react';
import { Divider, Paper } from '@material-ui/core';
import SearchField from '../../../components/SearchField';

const EntitySearchFields = ({ searches = [], onSearch, loading }) => (
  <Paper square>
    {searches.map(search => (
      <div key={search.search}>
        <SearchField {...search} handleSearch={onSearch(search.search)} loading={loading} />
        <Divider />
      </div>
    ))}
  </Paper>
);

export default EntitySearchFields;
