import React from 'react';

const RecursiveObjectRenderer = ({
  object, RenderItem, prefix, ...rest
}) => {
  if (!!object && typeof object === 'object') {
    return (
      <div {...rest}>
        {Object.keys(object).map((key) => {
          const keyPrefix = (prefix ? `${String(prefix)} ` : '') + key;
          if (!!object[key] && typeof object[key] === 'object') {
            return (
              <RecursiveObjectRenderer
                {...rest}
                key={keyPrefix}
                object={object[key]}
                RenderItem={RenderItem}
                prefix={keyPrefix}
              />
            );
          }
          return <RenderItem key={keyPrefix} value={object[key]} keyvalue={keyPrefix} />;
        })}
      </div>
    );
  }
  return null;
};

export default RecursiveObjectRenderer;
