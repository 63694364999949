import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, TextField, withStyles } from '@material-ui/core';

const styles = {
  card: { marginBottom: 24, marginRight: 24, padding: 24 },
  reviewInput: {
    minHeight: 64,
    padding: '0 12px',
    borderRadius: 3,
    boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.19)',
    backgroundColor: '#ffffff',
    border: 'solid 1px #eae7e7',
  },
};

const TaskReviewComments = ({
  classes,
  auditorName,
  autoMapped,
  review,
  showReviewError,
  updateReviewNote,
  isReviewEditable,
}) => {
  const reviewHeader = isReviewEditable
    ? 'Review Notes'
    : `Auditor: ${auditorName || ''} ${autoMapped ? 'Automapped' : ''}`;

  return (
    <Paper className={classes.card}>
      <Typography style={{ textTransform: 'capitalize' }}>{reviewHeader}</Typography>
      <TextField
        disabled={!isReviewEditable}
        fullWidth
        multiline
        InputProps={{ disableUnderline: true }}
        className={classes.reviewInput}
        style={{ border: showReviewError ? '2px solid rgb(206, 26, 26)' : 'inherit' }}
        value={review || ''}
        onChange={e => updateReviewNote(e.target.value)}
      />
    </Paper>
  );
};

TaskReviewComments.defaultProps = {
  review: null || '',
};

TaskReviewComments.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  review: PropTypes.string,
  auditorName: PropTypes.string.isRequired,
  autoMapped: PropTypes.bool.isRequired,
  showReviewError: PropTypes.bool.isRequired,
  updateReviewNote: PropTypes.func.isRequired,
};
export default withStyles(styles)(TaskReviewComments);
