import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Snackbar, SnackbarContent, IconButton, withStyles } from '@material-ui/core';
import { CheckCircle, Error as ErrorIcon, Info, Warning, Close } from '@material-ui/icons';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { closeAlert } from '../redux/actions/index';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: ErrorIcon,
  info: Info,
};

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const AppAlert = (props) => {
  const {
    classes, className, variant, open, message, onClose, ...other
  } = props;

  const Icon = variant ? variantIcon[variant] : variantIcon.info;

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={4000}
        onClose={onClose}
      >
        <SnackbarContent
          className={classNames(classes[variant], className)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={onClose}
            >
              <Close className={classes.icon} />
            </IconButton>,
          ]}
          {...other}
        />
      </Snackbar>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  open: state.alert.open,
  variant: state.alert.variant,
  message: state.alert.message,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeAlert),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AppAlert));
