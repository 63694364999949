import React from 'react';
import { Avatar } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import dateFormat from 'dateformat';
import PostponedIcon from '../../../assets/icons/matchlist/PostponedIcon';
import CancelledIcon from '../../../assets/icons/matchlist/CancelledIcon';
import DelayedIcon from '../../../assets/icons/matchlist/DelayedIcon';
import constants from '../../../utils/constants';

const matchStatusColors = {
  live: constants.ffColors.neonGreen,
  before: constants.ffColors.lightOrange,
  after: constants.ffColors.dalablue,
};

const getMatchBackColor = (match, theme) =>
  (matchStatusColors[match.status] ? matchStatusColors[match.status] : theme.palette.primary.main);

const hasCurrentMatchTime = match => match.match_time && match.match_time.current;

const formatKickoff = kickoffSeconds =>
  dateFormat(new Date(kickoffSeconds * 1000), 'HH:MM') || 'hh:mm';

const getLiveInfoText = (match) => {
  const { status, status_detail } = match;
  if (status === 'live' && status_detail && status_detail === 'halftime_pause') {
    return 'HT';
  }
  return hasCurrentMatchTime(match) ? `${match.match_time.current}'` : 'live';
};

const MatchEntityListItemAvatar = ({
  match, style, theme, ...rest
}) => (
  <Avatar
    {...rest}
    style={{
      ...style,
      fontSize: 12,
      backgroundColor: getMatchBackColor(match, theme),
    }}
  >
    {
      {
        live: getLiveInfoText(match),
        before:
          {
            postponed: <PostponedIcon />,
            cancelled: <CancelledIcon />,
            suspended: <PostponedIcon />,
            delayed: <DelayedIcon />,
            abandoned: <CancelledIcon />,
          }[match.status_detail] || formatKickoff(match.kickoff_at),
        after:
          {
            extra_time: 'AET',
            penalties: 'AP',
          }[match.status_detail] || 'FT',
      }[match.status]
    }
  </Avatar>
);

export default withTheme(MatchEntityListItemAvatar);
