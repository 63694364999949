import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import {
  overwriteAndReloadEntity,
  fetchEntityAndOverwrites,
  selectEntity,
} from '../../../redux/actions';
import constants from '../../../utils/constants';
import TournamentCard from './TournamentCard';

const TournamentCardContainer = ({ match, getEntity, ...rest }) => {
  // Match is a router prop, not the football match :P
  const { tournamentId } = match.params;
  useEffect(() => {
    getEntity(tournamentId, constants.entityType.tournament);
  }, [tournamentId]);

  return <TournamentCard {...rest} />;
};

const mapStateToProps = (state) => {
  const isLoadingOrError = state.tournamentOverwrites.loading || state.tournamentOverwrites.writing;
  const arePropertiesEdited =
    Object.values(state.edits.currentEdits).filter(value => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    tournament: getReducedEntityObject(state.entities.selectedTournament),
    attributeTypes: getReducedEntityTypes(state.entities.selectedTournament),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = dispatch => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (tournamentId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('tournament', tournamentId, currentEdits)),
  fetchEntityAndOverwrites: tournamentId =>
    dispatch(fetchEntityAndOverwrites('tournament', tournamentId)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentCardContainer));
