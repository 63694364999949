import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getReducedEntityObject, getReducedEntityTypes } from '../../../redux/reducers';
import { overwriteAndReloadEntity, fetchEntityAndOverwrites, selectEntity } from '../../../redux/actions';
import { closePrimaryDrawer } from '../../../redux/actions/drawer';
import constants from '../../../utils/constants';
import StadiumCard from './StadiumCard';

const StadiumCardContainer = ({ match, getEntity, stadium, ...rest }) => {
  const { stadiumId } = match.params;
  useEffect(() => {
    getEntity(stadiumId, constants.entityType.stadium);
  }, [stadiumId]);

  // Don't show the drawer for stadium cards
  useEffect(() => {
    closePrimaryDrawer();
  });

  if (stadium) {
    return <StadiumCard stadium={stadium} {...rest} />;
  }
  return null;
};

const mapStateToProps = (state) => {
  const isLoadingOrError = state.stadiumOverwrites.loading || state.stadiumOverwrites.writing;
  const arePropertiesEdited = Object.values(state.edits.currentEdits).filter((value) => value).length > 0;
  const editsDirty = state.edits.dirty;

  return {
    stadium: getReducedEntityObject(state.entities.selectedStadium),
    attributeTypes: getReducedEntityTypes(state.entities.selectedStadium),
    currentEdits: state.edits.currentEdits,
    editsDirty,
    buttonEnable: !isLoadingOrError && arePropertiesEdited && !editsDirty,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEntity: (entityId, entityType) => dispatch(selectEntity(entityId, entityType)),
  overwriteAndReloadEntity: (stadiumId, currentEdits) =>
    dispatch(overwriteAndReloadEntity('stadium', stadiumId, currentEdits)),
  fetchEntityAndOverwrites: (stadiumId) => dispatch(fetchEntityAndOverwrites('stadium', stadiumId)),
  closePrimaryDrawer: () => dispatch(closePrimaryDrawer()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StadiumCardContainer)
);
