import React from 'react';
import VisualSetupSection from '../components/VisualSetupSection';
import constants from '../../../utils/constants';

const RegionVisualSetup = ({ region }) => (
  <React.Fragment>
    <VisualSetupSection
      entityType="region"
      entityId={region.id}
      sectionTitle="Region Flg Thumbnail"
      images={['flag_thumbnail']}
      defaultExpand
      useCroppie
      uploadSize={{
        width: constants.images.region.flag_thumbnail.uploadWidth,
        height: constants.images.region.flag_thumbnail.uploadHeight,
      }}
    />
    <VisualSetupSection
      entityType="region"
      entityId={region.id}
      sectionTitle="Region Background Full"
      images={['background_full']}
      defaultExpand={false}
      useCroppie={false}
      uploadSize={{
        width: constants.images.region.background_full.uploadWidth,
        height: constants.images.region.background_full.uploadHeight,
      }}
    />
  </React.Fragment>
);

export default RegionVisualSetup;
